import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from "vue-axios";
import axios from "axios";
import './assets/css/style.css';
import './assets/js/script.js';
import GSignInButton from 'vue-google-signin-button'
import VueSession from 'vue-session';
import * as VueGoogleMaps from "vue2-google-maps" // Import package
import x5GMaps from 'x5-gmaps'
import wb from "./registerServiceWorker";
import firebase from 'firebase/app';
import 'firebase/auth';

// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//     config: { id: "UA-204659317-2" }
// });

var options = {
    persist: true
}
Vue.use(VueSession, options);
Vue.use(GSignInButton)
Vue.prototype.$workbox = wb;

// vue - meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

var VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);

//Single otp-input
import SingleInput from "vue-single-input";
Vue.use(SingleInput);


// vue input phone number 
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'



Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDGuN1gO8Qy67dToGzAfT7jnSZF6ZQI29I",
        libraries: "places"
    }
});


Vue.use(VueAxios, axios);

axios.defaults.baseURL = 'https://happybox.com'

// global variable dataLayer
// Vue.prototype.dataLayer = [];

Vue.config.productionTip = false

new Vue({ router, render: h => h(App), }).$mount('#app')
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
//const firebaseConfig = {
//    apiKey: "AIzaSyDGuN1gO8Qy67dToGzAfT7jnSZF6ZQI29I",
//    authDomain: "happybox-318909.firebaseapp.com",
//    projectId: "happybox-318909",
//    storageBucket: "happybox-318909.appspot.com",
//    messagingSenderId: "994655294176",
//    appId: "1:994655294176:web:08e3090a683b37a63d3685",
//    measurementId: "G-9QK5YYD4GS"
//};

const firebaseConfig = {
  apiKey: "AIzaSyDUzr8pv2AgwkoAzD06bVdpvth6kl8F9bU",
  authDomain: "happybox-webapp.firebaseapp.com",
  projectId: "happybox-webapp",
  storageBucket: "happybox-webapp.appspot.com",
  messagingSenderId: "359600052331",
  appId: "1:359600052331:web:806c55bedd7948e0c5792e",
  measurementId: "G-V2W5V74QPK"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);