<template>
    <!-- 4 up to main banner -->
    <div class="category-component-main-div">
      <div class="category-component-sub-div">
        <div v-for="topMenus in topMenu" :key="topMenus.id" class="category-component-main-div-zero-div" style="border: 2px solid transparent">
          <router-link :to="`/category/${topMenus.slug}`" class="category-component-main-div-first-inner" onclick="window.location.reload();">
            <img :alt="topMenus.menu_name" :src="'/uploads/category/'+ topMenus.cat_image" onclick="window.location.reload();"/>
            <div class="category-component-main-div-second-inner">{{topMenus.menu_name}}</div>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'Category',
    data() {
    return {
      topMenu:''
    };
  },
  mounted() {
    axios
      .post("/webapi/webshop/get_menus", {
        Identifier: "top-menu",
      })
      .then(response => {
        this.topMenu = response.data.AllMenuLevels;
      })
      
  },
}
</script>

<style scoped>
.category-component-main-div-second-inner{
    text-transform: capitalize;
    top: 55px;
}
</style>