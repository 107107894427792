<template>
    <div class="container main-box-sec order-success">
        <div class="row row-main-div">
            <div class="row_main_div_first_col_md_class">
                <div id="maindivshopproduct_7" class="">
                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div class="sucess-img" style="">
                                <div style="font-size: 19px;color: #2F1317;font-family: 'Arial';text-align: center;padding-top: 2em;display: inline;flex-direction: row;justify-content: left;font-weight: bold !important;padding-bottom: 0em;padding-left: 3em;padding-right: 3em;color: #664242;">
                                    <div class="order-date-time">
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="sucess-order-summary">
                                <div class="error-details" v-html="notFound.content">
                                    {{notFound.content}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name:'NotFound',
    metaInfo(){
        return {
            titleTemplate:this.metaTitle+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.notFound.meta_description },
                { name: 'keywords', content: this.notFound.meta_keywords }
            ]
        }
    },
    data(){
        return{
            notFound:'',
            metaTitle:'',
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.getNotFound()
    },
    methods:{
        getNotFound(){
            axios.get("/webapi/webshop/get_cms_page/404-not-found")
            .then(response => {
                this.notFound = response.data.cms_page_detail;
                if(this.notFound.meta_title){
                    this.metaTitle = this.notFound.meta_title;
                    
                }else{
                    this.metaTitle = this.notFound.title;
                    
                }
                // console.log("Not Found :", this.notFound);
            })           
        }
    },
}
</script>

<style>

</style>