<template>
    <footer class="footer-top">
       <div class="footer" style="padding-bottom: 0em;">
        <div>
            <div class="brand"><img alt="HB" class="logo-main" src="/assets/img/HP_BW_Logo.png"></div>
            <p class="subtext">{{footerdata.site_from_day}} - {{footerdata.site_to_day}} | {{footerdata.site_start_time}} - {{footerdata.site_close_time}}</p>
        </div>
        <hr class="separator" />
        <div class="contacts">
            <div class="contact-left">
                  <p>
                    <img alt='Instagram' class="footer_contact_icon" src="/assets/img/fa_icons/fa-instagram.png">
                </p>
                <p>
                    <img alt='WhatsApp' class="footer_contact_icon" src="/assets/img/fa_icons/fa-whatsapp.png">
                </p>
                <p>
                    <img alt='Phone' class="footer_contact_icon" src="/assets/img/fa_icons/fa-phone-square.png">
                </p>
                <p>
                    <img alt='E-mail' class="footer_contact_icon" src="/assets/img/fa_icons/fa-envelope.png">
                </p>
            </div>
            <div class="contact-right">
                <p>
                    <a class="detail" target="_blank" rel="noreferrer" :href="sociallink.link">happyboxuae</a>
                </p>
                <p>
                    <a class="detail" target="_blank" rel="noreferrer" :href="'https://wa.me/'+whatsappno">{{footerdata.site_whatsapp_no}}</a>
                </p>
                <p>
                    <a class="detail" target="_blank" rel="noreferrer" :href="'tel:'+footerdata.site_contact_no">{{footerdata.site_contact_no}}</a>

                </p>
                <p>
                    <a class="detail" target="_blank" rel="noreferrer" :href="'mailto:'+footerdata.site_contact_email">{{footerdata.site_contact_email}}</a>
                </p>
            </div>
        </div>
      <hr class="separator" />
        <div class="footer-bottom-sec">
            <div class="footer-copyright">
                Copyright @ <span id="year"></span> HappyBox
            </div>
            <div class="footer-links">
                    <ul>
                        <li><router-link to="/contact">Contact</router-link></li>
                        <li><router-link to="/terms">Terms & Conditions</router-link></li>
                        <li><router-link to="/policy">Privacy Policy</router-link></li>
                    </ul>
            </div>
        </div>
         <div style="padding: 20px 0 10px !important;float: right;font-size: 13px;/ padding-top: 20px; /color: #6c6c6c;"> Powered by&nbsp;  <a target="_blank" href="https://admiral.digital/"> admiral.digital</a></div>
    </div>
</footer>
</template>

<script>
import axios from 'axios';
export default {
    name: 'AppFooter',
    data(){
        return{
            footerdata:'',
            sociallink:'',
            whatsappno:'',
        }
    },
    mounted(){
       document.getElementById("year").innerHTML = new Date().getFullYear();
        this.getHeaderinfo();
        this.getSocialLink();
    },
    methods:{
        getHeaderinfo(){
            axios.get('/webapi/webshop/get_webshop_details/')
            .then(response => {
            this.footerdata = response.data.FbcWebShopDetails;
            this.whatsappno = response.data.FbcWebShopDetails.site_whatsapp_no.replace(/\+/g, '').replace(' ','');
            })
        },
        getSocialLink(){
            axios.get('/webapi/webshop/get_webshop_social/')
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200')
                {
                    this.sociallink = response.data.FbcWebShopSocial;
                }
            })
        }
    }
}
</script>

<style>
.detail{
    padding-top: 0px !important;
}
</style>