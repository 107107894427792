<template>
<div class="main-box-sec">
<div>
    <div class="container delivery_location_extended_customer_information_form" style="margin-top:0;">
        <div class="row row-main-div">
            <div id="delivery_location_extended_customer_information_form" style="margin: 0.5em 2em 0em 2em;">
                <div id="maindivshopproduct_7" class="">
                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div style="">
                                <div class="" style="display: flex;flex-direction: row;justify-content: space-evenly;">
                                    <div class="main_section_title" id="delivery_location_extended_checkout_div">Set New Password</div>
                                </div>

                                <!-- Success Messages start -->
                                <div id="success-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                                            {{message}}
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                                </div>
                                <!-- success Messages End -->

                                <!-- Error Messages start -->
                                <div id="error-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            {{message}}
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                                </div>
                                <!-- Error Messages End -->

                                <!--Not match Error Messages start -->
                                <div id="mismatch-error-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            Password and confirm password does <b>Not Match</b>. 
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('mismatch-error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                                </div>
                                <!--Not match  Error Messages End -->

                                <form id="delivery_location_extended_form" enctype="multipart/form-data" method="post" name="fileinfo">
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder is-upgraded" style="width: 100%;text-align: center;padding: 0px 0px 60px !important;" data-upgraded=",MaterialTextfield">
                                        <div>Enter the new Password.</div>
                                    </div>
                                    <div class="mb-6 text-center">
                                        <div id="password" class="flex justify-center">
                                            <div id="new_password_error" class="happybox-error-class">
                                                <div class="center_vertically_and_horizontally wrapped_text">
                                                </div>
                                            </div>
                                            <div class="flex-container" style="padding: 10px 0px;">
                                            <input type="password" v-model="password" placeholder="Enter Password" id="new_password" required>
                                            </div>

                                            <div id="Confirm_password_error" class="happybox-error-class">
                                                <div class="center_vertically_and_horizontally wrapped_text">
                                                </div>
                                            </div>
                                             <div class="flex-container" style="padding: 10px 0px;">
                                            <input type="password" v-model="confirmpassword" placeholder="Confirm Password" id="confirm_new_password" required>
                                            </div>
                                        </div>  
                                    </div>
                                </form>
                            </div>
                        </div>
                    <div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

    
 <div class="container sms_div_container">
        <div id="sms_div" style="flex-direction: column;justify-content: top;margin: 8vw;margin-top: 0em;display: flex; height: 58vh;opacity: 1;">
            <div style="width: 100%;margin-bottom: 0-;margin-top: 1em;">
				<button class="mdl-button green-btn" v-on:click="setNewPassword">Submit</button>
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'SetNewPassword',
    data(){
        return{
            password:'',
            confirmpassword:'',
            message:'',
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Set New Password | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Set New Password' },
                { name: 'keywords', content: 'Set New Password' }
            ]
        }
    },
    methods:{
        setNewPassword(){
            var new_password = document.getElementById('new_password');
            var confirm_new_password = document.getElementById('confirm_new_password');
            if(new_password.value.length == 0 || new_password.value == null){
                this.enableOrDisableErrorMessage('new_password_error', 'Please Enter Your New Password', true);
                return;
            }
            if(confirm_new_password.value.length == 0 || confirm_new_password.value == null){
                this.enableOrDisableErrorMessage('Confirm_password_error', 'Please Confirm Your New Password', true);
                return;
            }
            if(new_password.value !== confirm_new_password.value){
                document.getElementById('mismatch-error-dialog').style.display = 'block';
                return;
            }

            var token_data = JSON.parse(atob(this.$route.params.token));
            var customerEmail = token_data.token;
            axios.post('/webapi/webshop/reset_new_password',{
                email: customerEmail,
                password:confirm_new_password.value,
            })
            .then(response => {
                if (response.data.statusCode === "200" || response.data.statusCode === 200) {
                    this.message = response.data.message
                    document.getElementById('success-dialog').style.display="block";
                    this.$router.push({ path: '/login' });
                } else if (response.data.statusCode === 500 || response.data.statusCode === "500") {
                    this.message = response.data.message
                    document.getElementById('error-dialog').style.display="block";
                    
                }
            })
        },
        enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
            var mainElement = document.getElementById(idOfTheMainElement);
            if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
                var mainElement_first_children = mainElement.firstElementChild;
                if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
                    mainElement_first_children.innerHTML = messageText;
                    if (!disableFlag) {
                        mainElement.style.display = "none";
                    } else {
                        mainElement.style.display = "block";
                    }
                }
            }
        },
        checkIfObjectIsNotUndefiendOrNull(obj) {
            if (obj === undefined || obj === null) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>

<style>

</style>