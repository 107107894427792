<template>
    <div class="main-box-sec otp-sec">
<div>
    <div class="container delivery_location_extended_customer_information_form" style="margin-top:0;">
        <div class="row row-main-div">
            <div id="delivery_location_extended_customer_information_form" style="margin: 0.5em 2em 0em 2em;">
                <div id="maindivshopproduct_7" class="">
                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div style="">
                                <div class="" style="display: flex;flex-direction: row;justify-content: space-evenly;">
                                    <div class="main_section_title" id="delivery_location_extended_checkout_div">Verify OTP</div>
                                </div>
                                <form id="verify_contact_form" enctype="multipart/form-data" method="post" name="fileinfo">
                                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder is-upgraded" style="width: 100%;text-align: center;padding: 0px 0px 60px !important;" data-upgraded=",MaterialTextfield">
                                        <div>OTP has been sent to your Mobile.</div>
                                    </div>
                                    <div class="mdl-textfield mdl-js-textfield">
                                        <div class="otp-text">Number {{phoneNumber}} or <router-link to="/login/step2">Change mobile number</router-link></div>
                                    </div>
                                    <div class="mb-6 text-center">
                                        <div id="otp" class="flex justify-center">
                                            <div id="recaptcha-container"></div>
                                            <!-- <input type="number" v-model="otp" placeholder="OTP" required minlength="4" maxlength="6"> -->

                                            <SingleInput  v-model="otp" formId="verify_contact_form" :boxCount="6"  textColor="green" borderColor="red" textSize="2em" :withBorder="false" borderRadius="0%" backgroundColor="none" type="text"/>

                                        </div>  
                                    </div>
                                </form>
                            </div>
                        </div>
                    <div>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>

    <!-- Success Messages start -->
    <div id="success-dialog" style="display: none;" class="dialog-main">
        <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
            <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                <div style="margin:0 auto;">
                    <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                        <div class="">
                            <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                {{message}}
                            </ul>
                        </div>
                    <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                </div>
            </div>
        </div>
        <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
    </div>
    <!-- success Messages End -->

    <!-- dynamic box message-->
	<div id="error-dialog" style="display: none;" class="dialog-main">
		<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
			<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
				<div style="margin:0 auto;">
					<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
								{{message}}
							</ul>
						</div>
					<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
				</div>
			</div>
		</div>
		<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
	</div>
    <!--dynamic box message  -->

    <!-- dynamic box message-->
	<div id="otp-error-dialog" style="display: none;" class="dialog-main">
		<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
			<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
				<div style="margin:0 auto;">
					<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
								SMS sent Please check.
							</ul>
						</div>
					<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('otp-error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
				</div>
			</div>
		</div>
		<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
	</div>
    <!--dynamic box message  -->

    <!-- dynamic box message-->
	<div id="otp-errormessage-dialog" style="display: none;" class="dialog-main">
		<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
			<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
				<div style="margin:0 auto;">
					<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
								Error ! {{errormessage}}.
							</ul>
						</div>
					<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('otp-errormessage-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
				</div>
			</div>
		</div>
		<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
	</div>
    <!--dynamic box message  -->
    
 <div class="container sms_div_container">
        <div id="sms_div" style="flex-direction: column;justify-content: top;margin: 8vw;margin-top: 0em;display: flex; height: 58vh;opacity: 1;">
            <div style="width: 100%;margin-bottom: 0-;margin-top: 1em;">
                <button id="generate_otp" name = "generate_otp" @click="resend_otp" class="mdl-button green-btn">Resend OTP</button>
                <button @click="verifyOtp" id="submit-verify" class="mdl-button green-btn">Submit</button>
				
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/auth";
export default {
    name:'VerifyNumber',
    data(){
        return{
            appVerifier : '',
			otp : '',
            message:'',
            errormessage:'',
            phoneNumber:'',
            input_otp:'',
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Verify Contact | %s',
            htmlAttrs: {
                lang: 'en-US'                  
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Verify Contact'},
                { name: 'keywords', content: 'Verify Contact'}
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
        
        let countryCode = sessionStorage.getItem("countryCode");
        let phnumber = sessionStorage.getItem("phnumber");

        var phstring = phnumber.toString();
        var trailingCharsIntactCount = 4;

        phstring = new Array(phstring.length - trailingCharsIntactCount + 1).join('X') + phstring.slice( -trailingCharsIntactCount);
        
        this.phoneNumber = '+'+countryCode+' '+phstring;
        this.contactDetails = '+'+countryCode + phnumber;
    },
    methods:{
        verifyOtp(){
            let vm = this
            // let code = this.otp
            var self = this;
            // console.log("OTP Module Working: ",self.otp);
            document.getElementById("submit-verify").disabled = true;
            document.getElementById("submit-verify").innerText = "Please Wait...";

            window.confirmationResult.confirm(self.otp).then(function (result) {
            // User signed in successfully.
            var user = result.user;
            // ...
            //route to set password !
            //vm.$router.push({path:'/setPassword'})
            
            var countryCode  = sessionStorage.getItem("countryCode");
            var phnumber = sessionStorage.getItem("phnumber");
			axios.post('/webapi/webshop/customer_get_personal_info_by_phone_no',{
                phone_no: phnumber,
                ext_no: countryCode,
            }).then(response => {
				if (response.data.statusCode === 200 || response.data.statusCode === '200') {
                    self.custLogin();
				}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    self.registerUser();
				}
			})

            
          }).catch(function (error) {
            // User couldn't sign in (bad verification code?)
			self.errormessage = error
            // console.log("verifyOtp: ",self.errormessage);
            document.getElementById('otp-errormessage-dialog').style.display ='block';
            document.getElementById("submit-verify").disabled = false;
            document.getElementById("submit-verify").innerText = "Submit";
            self.otp = "";
            // console.log("OTP Module Not Working: ",self.otp);
            // ...
          });
        
        },
        custLogin(){
			var phnumber = sessionStorage.getItem("phnumber");
            var countryCode = sessionStorage.getItem("countryCode");
            var receive_nw = sessionStorage.getItem("receive_nw");
			axios.post('/webapi/webshop/login',{
                phone_no: phnumber,
                ext_no: countryCode,
                receive_nw: receive_nw,
            }).then(response => {
				if (response.data.statusCode === 200 || response.data.statusCode === '200') {
                    this.$session.start();
                    this.$session.set("EmailID", response.data.userdetails.EmailID);
                    this.$session.set("LoginID", response.data.userdetails.LoginID);
                    this.$session.set("FirstName", response.data.userdetails.FirstName);
                    this.$session.set("LastName", response.data.userdetails.LastName);
                    this.$session.set("LoginToken", response.data.userdetails.LoginToken);
                    this.$session.set("customer_type_id", response.data.userdetails.customer_type_id);
                    this.$session.set("ext_no",response.data.userdetails.ExtNo);
                    this.$session.set("PhoneNumber",response.data.userdetails.PhoneNumber);
                    localStorage.UEmailID = true;

                    // redirecting start
                    var customer_id = this.$session.get("LoginID");
                    var session_id = this.$cookie.get('device');
                    var quote_id = '';
                    if(this.$session.has("QuoteId") === false)
                    {
                        quote_id = '';
                    }
                    else
                    {
                        quote_id = this.$session.get("QuoteId");
                    }
                    axios.post('/webapi/webshop/cart_listing',{
                        quote_id: quote_id,
                        customer_id: customer_id,
                        session_id: session_id,
                    })
                    .then(response => {
                        if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                            this.$router.push({ path: '/checkout/step1' });
                        }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                            this.$router.push({ path: '/basket/' });
                        }
                    })
                    // redirecting end
                    
				}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    self.message = response.data.message;
                    document.getElementById('error-message-dialog').style.display='block';
				}
			})
		},
        registerUser(){
            var self = this;
            var payload = new FormData();
            payload.set('phone_no', sessionStorage.getItem("phnumber"));
            payload.set('ext_no', sessionStorage.getItem("countryCode"));
            payload.set('receive_nw', sessionStorage.getItem("receive_nw"));
            return axios({
                method: 'post',
                url: '/webapi/webshop/register',
                data: payload,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            }).then(response => { 
                if (response.data.statusCode === 200 || response.data.statusCode === "200") {
                    // console.log("Success Message: ",response.data.message);
                    self.$session.set("LoginID", response.data.userdetails.id);
                    self.$session.set("PhoneNumber", response.data.userdetails.mobile_no);
                    self.$session.set('ext_no', response.data.userdetails.ext_no);

                    localStorage.UEmailID = true;

                   self.Register_User(self.$session.get('EmailID'),self.$session.get('LoginID'),sessionStorage.getItem("countryCode"),sessionStorage.getItem("phnumber"));


                    // redirecting start
                    var customer_id = self.$session.get("LoginID");
                    var session_id = self.$cookie.get('device');
                    var quote_id = '';
                    if(self.$session.has("QuoteId") === false)
                    {
                        quote_id = '';
                    }
                    else
                    {
                        quote_id = self.$session.get("QuoteId");
                    }
                    axios.post('/webapi/webshop/cart_listing',{
                        quote_id: quote_id,
                        customer_id: customer_id,
                        session_id: session_id,
                    })
                    .then(response => {
                        if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                            self.$router.push({ path: '/checkout/step1' });
                        }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                            self.$router.push({ path: '/basket/' });
                        }
                    })
                    // redirecting end
                    
                    sessionStorage.removeItem("countryCode");
                    sessionStorage.removeItem("phnumber");
                    sessionStorage.removeItem("receive_nw");

                }else if(response.data.statusCode === 500 || response.data.statusCode === "500"){
                    self.message = response.data.message;
                    // console.log("Failed Message: ",response.data.message);
                }
            })
        },

        resend_otp()
        {
            var self = this;
			let countryCode = sessionStorage.getItem("countryCode");
			let phnumber = sessionStorage.getItem("phnumber");
			
			let phoneNumber = "+"+countryCode + phnumber
			//
			let appVerifier = self.appVerifier
			
			firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				window.confirmationResult = confirmationResult;
				//
				document.getElementById('otp-error-dialog').style.display ='block';
                // alert('SMS sent.');
            }).catch(function (error) {
				// Error; SMS not sent
				// ...
                self.errormessage = error
                document.getElementById('otp-errormessage-dialog').style.display ='block';
				// console.log('Error ! SMS not sent',error);
				// alert('ERROR:',error);
				// alert('Error ! SMS not sent')
			});
        
        },
        generate_otp()
        {
            var self = this;
			let countryCode = sessionStorage.getItem("countryCode");
			let phnumber = sessionStorage.getItem("phnumber");
			
			
			let phoneNumber = "+"+countryCode + phnumber
			//
			let appVerifier = self.appVerifier
			//window.location.href = '/login/verify-contact';
			//
			firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				window.confirmationResult = confirmationResult;
				//
				document.getElementById('otp-error-dialog').style.display ='block';
                // alert('SMS sent.');
            }).catch(function (error) {
				// Error; SMS not sent
				// ...
                self.errormessage = error
                document.getElementById('otp-errormessage-dialog').style.display ='block';
				// console.log('Error ! SMS not sent',error);
				// alert('ERROR:',error);
				// alert('Error ! SMS not sent')
			});
        
        },
        
        // saveContact(){
        //     var Country_ext_no = this.$cookie.get('countryCode');
        //     var phnumber = this.$cookie.get('phnumber').replace(" ","");
        //     var receive_nw = sessionStorage.getItem("receive_nw");
        //     this.$session.start();
        //     var user_email = this.$session.get('EmailID');

        //     axios.post('/webapi/webshop/register_phone',{
        //         phone_no: phnumber,
        //         email: user_email,
        //         receive_nw: receive_nw,
        //         ext_no: Country_ext_no,
        //     }).then(response => {
        //         if (response.data.statusCode === 200 || response.data.statusCode === '200') {
        //             this.message = response.data.message
        //             sessionStorage.removeItem("receive_nw");
		// 			document.getElementById('success-dialog').style.display ='block';
        //             var next_param_url = '';

        //                 if (next_param_url != undefined) {
        //                     // if (next_param_url != '/checkout/step1' && next_param_url != '/basket/' && next_param_url != '/checkout/map') {
        //                     if (next_param_url != '/checkout/step1' && next_param_url != '/basket/') {
        //                         next_param_url = undefined;
        //                     }
        //                 }
                        
        //                 this.Register_User(this.$session.get('EmailID'),this.$session.get('LoginID'),Country_ext_no,phnumber);

        //                 // redirecting start
        //                 var customer_id = this.$session.get("LoginID");
        //                 var session_id = this.$cookie.get('device');
        //                 var quote_id = '';
        //                 if(this.$session.has("QuoteId") === false)
        //                 {
        //                     quote_id = '';
        //                 }
        //                 else
        //                 {
        //                     quote_id = this.$session.get("QuoteId");
        //                 }
        //                 axios.post('/webapi/webshop/cart_listing',{
        //                     quote_id: quote_id,
        //                     customer_id: customer_id,
        //                     session_id: session_id,
        //                 })
        //                 .then(response => {
        //                     if(response.data.statusCode === 200 || response.data.statusCode === '200'){
        //                         this.$router.push({ path: '/checkout/step1' });
        //                     }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
        //                         this.$router.push({ path: '/basket/' });
        //                     }
        //                 })
        //                 // redirecting end

                        
        //             }else if(response.data.statusCode === "500" || response.data.statusCode === 500){
        //                 this.message = response.data.message
        //                 document.getElementById('error-dialog').style.display ='block';
        //             } else {
        //                 JSON.stringify(response);
        //             }
                
        //     })
        // },
        Register_User(Email_ID,Login_ID,Country_code,contact_no)
        {
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'register',
                'userEmailAddress' : Email_ID,
                'userPhoneNumber' : '+'+Country_code+''+contact_no,
                'userId' : Login_ID // registerID
            });
        },
		initReCaptcha(){
        setTimeout(()=>{
          let vm = this
          window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': function(response) {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            'expired-callback': function() {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            }
          });
          //
          this.appVerifier =  window.recaptchaVerifier;
           this.generate_otp();
        },1000)
      }
    },
    created(){
      this.initReCaptcha();
     
    }
}
</script>

<style>

</style>