<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->
    <!-- main content -->
    <div class="container main_class_misc content-sec-info">
        <div class="row" style="margin: auto;">
            <h3 style="text-align: center;border-bottom: 2px solid #4d4d4d;width: 7em;margin: auto;margin-bottom: 1em;margin-top: 1em;" class="content-title">{{ termsConditions.title }}</h3>
        </div>
        <div class="form_div_box" style="margin: 2em;text-align: left;">
            <p v-html="termsConditions.content">{{ termsConditions.content }}</p>
        </div>
    </div>
</div>
    
</template>

<script>
import axios from 'axios';
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'TermsConditionsPage',
    components: { 
        SpinnerData
    },
    metaInfo(){
        return {
            titleTemplate:this.metaTitle+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.termsConditions.meta_description },
                { name: 'keywords', content: this.termsConditions.meta_keywords }
            ]
        }
    },
    data(){
        return{
            termsConditions:'',
            metaTitle:'',
            loading: false,
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.getTermsConditions()
    },
    methods:{
        getTermsConditions(){
            this.loading = true;
            axios
            .get("/webapi/webshop/get_cms_page/terms")
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.termsConditions = response.data.cms_page_detail;
                    if(this.termsConditions.meta_title){
                    this.metaTitle = this.termsConditions.meta_title;
                        
                    }else{
                        this.metaTitle = this.termsConditions.title;
                        
                    }
                    // console.log("Terms Conditions", this.termsConditions);
                    this.loading = false;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.loading = false;
                }
                
            })           
        }
    },
}

</script>

<style>

</style>