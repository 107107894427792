<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->
    <div class="container main-box-sec order-success">
        <div class="row row-main-div">
            <div class="row_main_div_first_col_md_class">
                <div id="maindivshopproduct_7" class="">

                    <!-- Error Messages start -->
                    <div id="error-dialog" style="display: none;">
                        <div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                            <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                <div style="margin:0 auto;">
                                    <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                        <div class="">
                                            <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                {{message}}
                                            </ul>
                                        </div>
                                    <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" onclick="var obj = document.getElementById('error-dialog'); obj.remove();" class="mdl-button confirm">Ok</button></div>
                                </div>
                            </div>
                        </div>
                        <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                    </div>
                    <!-- Error Messages End -->

                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div class="sucess-img" style="">
                                <!-- <img style="" src="/assets/img/order_complete_icon.png"> -->
                                <div style="font-size: 19px;color: #2F1317;font-family: 'Arial';text-align: center;padding-top: 2em;display: inline;flex-direction: row;justify-content: left;font-weight: bold !important;padding-bottom: 0em;padding-left: 3em;padding-right: 3em;color: #664242;">
                                    <!-- <div class="main_section_title">Thank you for you order, <br>you will receive your delivery at</div> -->
                                    <div class="order-date-time" id="order_date_time">
                                   
                                    </div>
                                </div>
                            </div>
                        <div class="sucess-order-summary">
                            <div class="success-title">
                                Order Summary ( ID: {{orderdata.increment_id}} )
                            </div>
                            <div class="order-product-detail-main">
                                <div v-for="orderitems in orderdata.order_items" :key="orderitems.item_id">
                                    <div class="order-product-detail">
                                        {{orderitems.qty_ordered}} x {{orderitems.product_name}} -
                                    </div>
                                </div>
                                <div class="order-total-div">
                                    <div>
                                        Total:
                                    </div>
                                    <div class="order-final-total">
                                        AED {{orderdata.grand_total}}
                                    </div>
                                </div> 
                            </div>   
                        </div>
                    <div class="order-delivery-address-main">
                        <div class="order-delivery-address">
                            <div class="success-title">
                                Delivery Address
                            </div>
                           <div class="order-content"><b>City:</b> {{addressdetails.city}}</div>
                           <div class="order-content"><b>Area:</b> {{addressdetails.region}}</div>
                            <div class="order-content"><b>Building:</b> {{addressdetails.building}}</div>
                            <div class="order-content"><b>Building Floor:</b> {{addressdetails.building_floor}}</div>
                            <div class="order-content"><b>Apartment Number:</b> {{addressdetails.posession_number}}</div>
                            <div class="order-content"><b>Recipient phone number:</b> (+{{addressdetails.phone_prefix}}) {{addressdetails.mobile_no}}</div>    
                        </div>
                        <div class="order-delivery-address">
                            <div class="success-title">
                                Order By
                            </div>
                           <div class="order-content"><b>Email:</b>  {{orderdata.customer_email}}</div>
                           <div class="order-content"><b>Phone:</b> (+{{orderdata.customer_phone_prefix}}) {{orderdata.customer_phone}}</div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'OrderDetails',
    components: { 
       SpinnerData, 
    },
    data(){
        return{
            order_id:'',
            deliverydetails:'',
            addressdetails:'',
            orderdata:'',
            message:'',
            loading: false,
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Order Details | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Order Details' },
                { name: 'keywords', content: 'Order Details' }
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.showOrderDetails();
    },
    methods:{
        showOrderDetails(){
            this.loading = true;
            var orderid = this.$route.params.order_id;
            // console.log(orderid);
            axios.get('/webapi/webshop/my_order_detail' + '/' + orderid)
            .then(response => {
                console.log('OrderData: ',response.data.OrderData);
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.deliverydetails = response.data.OrderData.delivery_details;
                    this.addressdetails = response.data.OrderData.shipping_address;
                    this.orderdata = response.data.OrderData;

                    axios.get('/webapi/webshop/get_delivery_offer/')
                    .then(response => {
                        this.deliveryoffer = response.data.Fbcgetdelivery_offer;
                        // var d = new Date(this.deliverydetails.order_date);
                        // var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        // var monthName = months[d.getMonth()];
                        var delivery_date = this.deliverydetails.order_date;
                        if(this.deliverydetails.time_slot === this.deliveryoffer.name){
                            document.getElementById('order_date_time').innerText = delivery_date +', '+ document.getElementById('order_date_time').innerText.replace('between','')+''+this.deliverydetails.time_slot;
                        }else{
                            document.getElementById('order_date_time').innerText = delivery_date +', between '+ this.deliverydetails.time_slot;
                        }
                    })
                    this.loading = false;
                }else{
                    this.message = response.data.message;
                    document.getElementById('error-dialog').style.display="block";
                    this.loading = false;
                }
            }) 
        }
    }
}
</script>

<style>

</style>