<template>
    <div class="container my-account">
        <div class="row row-main-div">
            <div class="col-md-6" style="margin: 0em;">
                <div id="maindivshopproduct_7" style="min-height: 22em;">
                    <div style="padding-bottom: 0;">
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div style="margin-left: 0em;margin-right: 0em;">

                                <!-- Error Messages start -->
                                <div id="error-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            {{message}}
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                                </div>
                                <!-- Error Messages End -->

                                <!-- Success Messages start -->
                                <div id="success-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                                            {{message}}
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                                </div>
                                <!-- success Messages End -->
                               
                                <div v-on:click="toggle_visibilit_of_orders" style="width: 100%;margin: auto;padding-top: 0em; border-bottom: 1px solid #00000033;" class="toggle-title-main">
                                    <div class="" style="font-size: 12px;color: #dfbe58;font-family: 'proxima-nova', sans-serif !important;text-align: center;margin: auto;display: flex;flex-direction: row;justify-content: space-around;padding-top: 1em;padding-left: 6px;padding-right: 6px;">
                                        <div class="" style="font-size: 9px;color: rgba(0,0,0,.54);font-family: 'proxima-nova', sans-serif !important;text-align: center;margin: auto;display: flex;flex-direction: row;justify-content: space-between;padding-top: 0em;padding-bottom: 1em;width: 100%;">
                                            <div style="font-size: 16px;">My Orders</div>
                                        </div>
                                        <div style="">
                                            <img src="assets/img/fa_icons/fa-arrow-right.svg" style="width: 16px;">
                                        </div>
                                    </div>
                                </div>
                                
                                <div>
                                    
                                    <div style="display: none;" id="order_history_div">
                                        <table style="margin: auto; margin-bottom: 0em;width: 100%;text-align: left;margin-top: 2em;margin-bottom: 4em;border-collapse: collapse;">
                                            <thead style="border-bottom: 1px solid #79797933;">
                                                <tr style="border-bottom: 3px solid #79797933;border-top: 3px solid #79797933;text-align: left;">
                                                    <th style="text-align: center;">
                                                        Order 
                                                    </th>
                                                    <th style="text-align: center;">
                                                        Date
                                                    </th>
                                                    <th style="text-align: center;">
                                                        Status
                                                    </th>
                                                    <th style="text-align: center;">
                                                        Total 
                                                    </th>
                                                    <th style="text-align: center;">
                                                        View Details
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="no_data" style="display: none;">
                                                <tr>
                                                    <td colspan="4" style="text-align: center;">No Order found</td>
                                                </tr>
                                            </tbody>
                                            <tbody class="data_found" style="color: #946b6b;font-size: 13px;display: contents;">
                                                <tr v-for="orderlist in orderlists" :key="orderlist.order_id" style="border-bottom: 3px solid #79797933;padding: 20px;height: 6em;">
                                                    <td style="padding-right: 2em;">
                                                        {{orderlist.increment_id}}
                                                    </td>
                                                    <td style="width: 41px;padding-right: 1em;">
                                                        <input type="hidden" :value="orderlist.created_at" :id="'created_at_'+`${orderlist.order_id}`">
                                                        {{setcreatedDate(orderlist.created_at)}}
                                                    </td>
                                                    <td style="padding-right: 1em;">
                                                        <p :id="'status_'+`${orderlist.order_id}`"></p>
                                                        {{statusname(orderlist.status)}}
                                                    </td>
                                                    <td style="font-weight: 700;color: black;">
                                                        AED {{orderlist.grand_total}}
                                                    </td>
                                                    <td style="font-weight: 700;color: black;text-align: center !important;padding-right: 1em;">
                                                        <router-link :to="`/user/order-details/${orderlist.order_id}`">
                                                            <button style="background: orange;border: 1px solid orchid;color: white;font-size: 13px;padding: 7px;width: 65px;" class="order-view">
                                                                View
                                                            </button>
                                                        </router-link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!--<div v-on:click="toggle_visibilit_of_change_password" style="width: 100%;margin: auto;padding-top: 0em;" class="toggle-title-main">
                                    <div class="" style="font-size: 12px;color: #dfbe58;font-family: 'proxima-nova', sans-serif !important;text-align: center;margin: auto;display: flex;flex-direction: row;justify-content: space-around;padding-top: 1em;padding-left: 6px;padding-right: 6px;">
                                        <div class="" style="font-size: 9px;color: rgba(0,0,0,.54);font-family: 'proxima-nova', sans-serif !important;text-align: center;margin: auto;display: flex;flex-direction: row;justify-content: space-between;padding-top: 0em;padding-bottom: 1em;width: 100%;">
                                            <div style="font-size: 16px;">Change Password</div>
                                        </div>
                                        <div style="">
                                            <img src="assets/img/fa_icons/fa-arrow-right.svg" style="width: 16px;">
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        
                        <!--<form id="password_reset_form" class="" method="post" enctype="multipart/form-data">
                            <input type="hidden" name="csrfmiddlewaretoken" value="ftgUqHn19ig4gcS3RTysRuvCwVhmfQx1Ujn4OiAkMhGblqD3EUy4TpEWsSmW6gCO">
                            <div id="order_password_div" style="width: 80%;margin: auto; display: none;">

                                <div id="old_password_error" class="happybox-error-class">
                                    <div class="center_vertically_and_horizontally wrapped_text">
                                    </div>
                                </div>
                                <div style="margin-bottom: 1em;margin-top: 1em;" id="old_password_div" class="password-field">
                                    <label>Old Password</label><input type="password" id="old_password" placeholder="" required="true" name="old_password">
                                </div>

                                <div id="new_password1_error" class="happybox-error-class">
                                    <div class="center_vertically_and_horizontally wrapped_text">
                                    </div>
                                </div>
                                <div style="margin-bottom: 1em;margin-top: 1em;" class="password-field">
                                    <label>New Password</label><input  type="password" name="new_password1" placeholder="" required="true" id="new_password1">
                                </div>

                                <div id="new_password2_error" class="happybox-error-class">
                                    <div class="center_vertically_and_horizontally wrapped_text">
                                    </div>
                                </div>
                                <div style="margin-bottom: 1em;margin-top: 1em;" class="password-field">
                                    <label>Confirm Password</label> <input  type="password" placeholder="" required="true" name="new_password2" id="new_password2">
                                </div>
                                 <div id="both_new_password_matching_error" class="happybox-error-class">
                                    <div class="center_vertically_and_horizontally wrapped_text">
                                    </div>
                                </div>
                                <div style="width: 100%;text-align: left;" class="myaccount_update_password">
                                    <button v-on:click="changePassword" style="width: 100%;height: 4em;background: rgb(238,153,47);background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;">Update Password</button>
                                </div>
                            </div>
                        </form>
                        <div style="display: flex;justify-content: center;">
                            <router-link to="/user/password/reset" style="color: black;" class="reset-pswd">Reset password</router-link>
                        </div>-->
                        <div style="margin-top: 50px;display: flex;justify-content: center;">
                            <a v-on:click="logout" class="reset-pswd" style="color: black;font-size:16px;font-weight:bold;">Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
export default {
    name: 'MyAccount',
    data(){
        return{
            changepasswords:'',
            orderlists:'',
            createdDate:'',
            message:'',
        }
    },
    metaInfo(){
        return {
            titleTemplate:'My Account | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'My Account'  },
                { name: 'keywords', content: 'My Account' }
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
        // this.$session.start();
        // console.log(this.$session.getAll());
    },
    methods: {
        logout() {
            this.$session.destroy();
            localStorage.removeItem("UEmailID");
            this.$router.push({ path: '/' });
        },
        toggle_visibilit_of_orders() {
            var order_history_div = document.getElementById('order_history_div');
            if (order_history_div.style.display == 'none') {
                order_history_div.style.display = 'block';
                this.getOrderList();
            } else {
                order_history_div.style.display = 'none';
            }
        },
        toggle_visibilit_of_change_password() {
            var order_history_div = document.getElementById('order_password_div');
            if (order_history_div.style.display == 'none') {
                order_history_div.style.display = 'block';
            } else {
                order_history_div.style.display = 'none';
            }
        },
        changePassword(event){
            if (event !== undefined) {
                event.preventDefault();
            }
            var old_password = document.getElementById("old_password");
            var new_password1 = document.getElementById("new_password1");
            var new_password2 = document.getElementById("new_password2");
            var EmailID = this.$session.get("EmailID");

            // console.log(old_password , new_password1 , new_password2 , EmailID)

            if (old_password.value.length == 0 || old_password.value == null) {
                this.enableOrDisableErrorMessage('old_password_error', 'Please Enter Your Old Password', true);
                return;
            }

            if (new_password1.value.length == 0 || new_password1.value == null) {
                this.enableOrDisableErrorMessage('new_password1_error', 'Please Enter Your New Password', true);
                return;
            }

            if (new_password2.value.length == 0 || new_password2.value == null) {
                this.enableOrDisableErrorMessage('new_password2_error', 'Please Re-Enter Your New Password', true);
                return;
            }
            if(new_password1.value !== new_password2.value){
                this.enableOrDisableErrorMessage('both_new_password_matching_error','Your Password Not Matching with New Password', true);
                return;
            }
            
            axios.post('/webapi/webshop/reset_password',{
                email: EmailID,
                password: new_password2.value,
                old_password: old_password.value,

            })
            .then(response => {
                if (response.data.statusCode === "200" || response.data.statusCode === 200) {
                    this.message = response.data.message
                    document.getElementById('success-dialog').style.display="block";
                    location.reload();
                } else if (response.data.statusCode === 500 || response.data.statusCode === "500") {
                        this.message = response.data.message
                        document.getElementById('error-dialog').style.display="block";
                    
                }
            })            
        },
        getOrderList(){
            var customer_id = this.$session.get('LoginID');
            var limit = 10;
            var offset = 0;
            axios.get('/webapi/webshop/my_orders_listing' + '/' + customer_id + '/' + limit + '/' + offset)
            .then(response => {
                if(response.data.statusCode === "200" || response.data.statusCode === 200){
                    this.orderlists = response.data.OrderData;
                    
                }else if(response.data.statusCode === "500" || response.data.statusCode === 500){
                    this.message = response.data.message
                   // document.getElementById('order_history_div').style.display = 'none';
                    document.getElementById('no_data').style.display = 'block';

                    // document.getElementById('error-dialog').style.display="block";
                }
                // console.log(response.data);
            })
        },
        statusname(status){
            // var cStatus = ["To Be Processed", "Processing", "Complete", "Cancelled", "Tracking Missing", "Tracking Incomplete", "Tracking Complete", "Pending"];
            var cStatus = ["To Be Processed", "Processing", "Complete", "Cancelled", "Tracking Missing", "Tracking Incomplete", "Tracking Complete", "Pending", "Payment Failure", "Shipping", "Shipped", "Accepted", "Delivered"];
            var nStatus = cStatus[status];
            return nStatus;
        },
        setcreatedDate(timestampdate){
            // var date = new Date(timestampdate * 1000);
            var date = new Date(timestampdate);
            var created_at = date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear();
            // console.log("date",date,"created",created_at,"timestampdate",timestampdate);
            return created_at;
        },

        enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
            var mainElement = document.getElementById(idOfTheMainElement);
            if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
                var mainElement_first_children = mainElement.firstElementChild;
                if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
                    mainElement_first_children.innerHTML = messageText;
                    if (!disableFlag) {
                        mainElement.style.display = "none";
                    } else {
                        mainElement.style.display = "block";
                    }
                }
            }
        },
        checkIfObjectIsNotUndefiendOrNull(obj) {
            if (obj === undefined || obj === null) {
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>

<style>

</style>