<template>
    <div>
        <div class="main-box-sec reset-password">
            
            <!-- Error Messages start -->
            <div id="error-dialog" style="display: none;">
                <div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                    <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                        <div style="margin:0 auto;">
                            <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                <div class="">
                                    <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                        {{message}}
                                    </ul>
                                </div>
                            <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                        </div>
                    </div>
                </div>
                <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
            </div>
            <!-- Error Messages End -->

            <!-- success Messages start -->
            <div id="success-message-dialog" style="display: none;">
                <div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                    <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                        <div style="margin:0 auto;">
                            <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Successfully</div>
                                <div class="">
                                    <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                        Please Check Your Email Inbox.
                                    </ul>
                                </div>
                            <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-message-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                        </div>
                    </div>
                </div>
                <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
            </div>
            <!-- success Messages End -->

            <div style="">
                <div style="">
                        <div class="main_section_title">Forgotten your password?</div>
                    <div class="mdl-textfield mdl-js-textfield">
                        Enter the email address linked to your HappyBox account. If you’re in our system, we’ll send you a reset link.
                    </div>
                </div>
                <form style="display: flex;justify-content: center;flex-direction: column;margin: auto;margin-top: 1em;" method="post">
                    <div class="" style="display: flex;justify-content: center;flex-direction: column;">
                        <input type="email" name="email" v-model="email" placeholder="Email Address" maxlength="254" style="" required="" id="id_email">
                    </div>

                    <div class="" style="display: flex;justify-content: center;flex-direction: column;">
                        <button class="mdl-button green-btn" v-on:click="getresetlink">
                            Send
                        </button>
                    </div>

                    <div class="" style="display: flex;justify-content: center;flex-direction: column;">
                        <router-link to="/login">
                            <button style="color: black;background: transparent;font-size: 16px;height: 2.59em;width: 16em;margin: auto;margin-top: 20px;border: transparent;text-decoration: underline;font-weight: 500;">Back to Login</button>
                        </router-link>
                    </div>  
                </form>
            </div>
            <div>
                <div class="mdl-textfield mdl-js-textfield">
                    NEW CUSTOMER?
                </div>
                <div style="width: 100%;text-align: center;">
                    <router-link to="/login">
                        <button class="mdl-button green-btn">Create Account</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ResetPassword',
    data(){
        return{
            email:'',
            message:'',
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Forgot Password | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Forgotten your password' },
                { name: 'keywords', content: 'Forgotten your password' }
            ]
        }
    },
    methods:{
        getresetlink(event){
            // Changing title of the proceed button
			if(event !== undefined)
            event.preventDefault();

            axios.post('/webapi/webshop/forgot_password',{
                email:this.email,
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    document.getElementById('success-message-dialog').style.display = 'block';
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.message = response.data.message;
                    document.getElementById('error-dialog').style.display = 'block';
                }
            })
        }
    }
}
</script>

<style>

</style>