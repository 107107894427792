import { Workbox } from "workbox-window";

let wb;

if ("serviceWorker" in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
	navigator.serviceWorker.register('/service-worker.js').
		then(function (registration) {
			// Registration was successful``
			console.log('ServiceWorker registration successful with scope: ', 
	registration.scope);
		}).catch(function (err) {
			// registration failed :(
			console.log('ServiceWorker registration failed: ', err);
		});
  wb.addEventListener("controlling", () => {
    window.location.reload();
  });

  wb.register();
} else {
  wb = null;
}

export default wb;
