<template>
  <div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->

    <Category />
    <VueSlickCarousel v-if="banner.length > 0" :arrows="true"  v-bind="settings">
      <div v-for="banners in banner" :key="banners.id" class="intro_banners-main">
        <section class='intro_banners' v-bind:style="{ 'background-image': 'url(/uploads/banner/1920x1080/' + banners.banner_image + ')' }">
            <div class="see_all_categories_div_category_name" v-html="banners.description">
                {{banners.description}}
            </div>
            <div v-if="banners.button_text" class="button_link_div">
              <a :href="banners.link_button_to" target="_blank"><button class="button_link">{{banners.button_text}}</button></a>
            </div>
        </section>
      </div>
    </VueSlickCarousel>
    <Homebestsellers />

    <div style="position: fixed; display: none; bottom: 0px; z-index: 1; width: 100%; opacity: 1; background: rgb(112, 206, 52); color: rgb(112, 206, 52);" id="BASKET_FOOTER" class="main_footer_class" onclick="SPINNER.actions.showSpinner();BASKET_FOOTER.actions.startCheckout('BASKET')">
      <div class="basket_component_class_footer">
        <div class="basket_component_class_footer_first_inner">
          <div style="word-break: break-all;word-spacing: unset;font-size: 17px;letter-spacing: 2px;margin: 0 0 0px;color: #F0F0F0;text-align: center;background: transparent;width: min-content;height: min-content;width: 2em;height: 2em;position: relative;border-radius: 7px;margin-left: 1em;">        
            <div class="basket_footer_products_quantity">
              <div style="position: relative;">
                <img alt="Basket" class="basket_footer_image" src="/assets/img/fa_icons/fa-shopping-basket.svg">
                <div id="BASKET_FOOTERproducts_quantity" class="basket_FOOTER_products_quantity">{{basketcartItems}}</div>
              </div>
            </div>
          </div>
          <div id="add_to_the_basket_div" class="add_to_the_basket_div">
            <router-link to="/basket/">
                <div style="cursor: pointer;color: white;" class="" >
                  VIEW BASKET
                </div>
            </router-link>
          </div>
          <div class="BASKET_FOOTERgrand_total">
            <div id="BASKET_FOOTERgrand_total">
              <div class="original_price ">AED {{basketcartDetails.grand_total}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Category from './Category'
import Homebestsellers from './HomeBestsellers'
import SpinnerData from './SpinnerData.vue';
import axios from "axios";
export default {
  name: "HomePage",
  components:{
    Category,
    Homebestsellers,
    VueSlickCarousel,
    SpinnerData,
  },
  data(){
    return{
      banner:'',
      basketcartDetails:'',
      basketcartItems:'',
      cartlength:'',
      metaTitle:'',
      homepage:'',
      loading: false,
      settings:{
        "dots": true,
        "dotsClass": "slick-dots custom-dot-class",
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "autoplay":true,
      }
      
    }
  },
  metaInfo(){
    return {
        titleTemplate:this.metaTitle+' | %s',
        htmlAttrs: {
            lang: 'en-US'
        },
        meta: [
            { charset: 'utf-8' },
            { name: 'description', content: this.homepage.meta_description },
            { name: 'keywords', content: this.homepage.meta_keywords }
        ]
    }
  },
  mounted(){
    window.scrollTo(0,0);
    this.getbanner()
    this.getBasketDetails()
    this.getWebshopinfo()
  },
  methods: {
    getbanner(){
      this.loading = true; 
      axios
        .post("/webapi/webshop/get_banners",{
          banner_type: "homebanner",
        }).then(response => { 
          if(response.data.statusCode === 200 || response.data.statusCode === '200'){
            this.banner = response.data.ShopBannerDetails;
            // console.log("best banner:",this.banner);
            this.loading = false;
          }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
            this.loading = false;
          }
          
        })
    },
    getBasketDetails(){
			var customer_id = this.$session.get("LoginID");
			var session_id = this.$cookie.get('device');
			var quote_id = '';
      if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
      {
          quote_id = '';
      }
      else
      {
          quote_id = this.$cookie.get('QuoteId');
      }

			// if(this.$session.has("QuoteId") === false)
      // {
      //     quote_id = '';
      // }
      // else
      // {
      //     quote_id = this.$session.get("QuoteId");
      // }
			axios.post('/webapi/webshop/cart_listing',{
				quote_id: quote_id,
				customer_id: customer_id,
				session_id: session_id,
			})
			.then(response => {
				if(response.data.statusCode === 200 || response.data.statusCode === '200'){
					this.basketcartDetails = response.data.cartData.cartDetails;
					this.basketcartItems= response.data.cartData.cartItems.length;
          document.getElementById('BASKET_FOOTER').style.display = 'block';
					console.log("Item Details: ",this.basketcartItems,"Basket Details:",this.basketcartDetails);
				}else{
					this.message = response.data.message;
          document.getElementById('BASKET_FOOTER').style.display = 'none';
				}
			})
		},
    getWebshopinfo(){
      axios.get('/webapi/webshop/get_webshop_details/')
      .then(response => {
      if(response.data.statusCode === 200 || response.data.statusCode === '200'){
          this.homepage = response.data.FbcWebShopDetails;
          if(this.homepage.meta_title){
            this.metaTitle = this.homepage.meta_title;
          }else{
            this.metaTitle = 'Homepage';
          }
          this.loading = false;
      }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
          this.loading = false;
      }
      
      })
    },
  }
};
</script>

<style>
#category{
    display: flex!important;
}
</style>