<template>
    <div class="signup_main_class main-box-sec">
        <div style="font-size: 21px !important;">
            <div class="main_section_title">
                To continue, please login
            </div>
        </div>

        <!-- Success Messages start -->
        <div id="success-dialog" style="display: none;" class="dialog-main">
            <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                    <div style="margin:0 auto;">
                        <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                            <div class="">
                                <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                    {{message}}
                                </ul>
                            </div>
                        <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                    </div>
                </div>
            </div>
            <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
        </div>
        <!-- success Messages End -->
        <!-- dynamic box message-->
        <div id="error-message-dialog" style="display: none;" class="dialog-main">
            <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                    <div style="margin:0 auto;">
                        <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                            <div class="">
                                <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                    {{message}}
                                </ul>
                            </div>
                        <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-message-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                    </div>
                </div>
            </div>
            <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
        </div>
        <!--dynamic box message  -->

        <div style="display: flex;flex-direction: column;justify-content: space-evenly;" class="google-btn">
            <button style="margin: auto;background: white;border: 1px solid #e0e0e0;box-shadow: none;color: #8f8f8f;font-size: 16px;margin-bottom: 1em;width: 100%;height: 51px;" class="mdl-button mdl-button--raised mdl-button--colored">
                <div style="display: flex;flex-direction: row;justify-content: left;">
                    <img class="google_icon" src="assets/img/google-icon.png">
                    <div style="">
                    <template><g-signin-button :params="googleSignInParams" @success="onSignInSuccess" @error="onSignInError">Sign in with Google</g-signin-button></template>
                    </div>
                   <span>Continue with</span><img class="google_text" src="assets/img/transparent-background-google-logo.png">
                 </div>
            </button>   
        </div>
        <div class="row">
            <div class="col-md-8">
                <div id="sign-up-csrf-token"> 
                    <input type="hidden" name="csrfmiddlewaretoken" value="2uc2esVSTjIRHWeYvrRm00JcUXesLYTj2XJUA8ctWRi5xFfmWMDHcOFx80dsk1lb">
                </div>

                <div style="font-size: 21px !important;margin-top: 70px;">
                    <div class="main_section_title">
                        Create Account
                    </div>        
                </div>        
        
                <div>
                    <form style="margin: auto;margin-top: 0px;" class="form-signin" id="form-signin">
                        <input type="hidden" name="csrfmiddlewaretoken" value="2uc2esVSTjIRHWeYvrRm00JcUXesLYTj2XJUA8ctWRi5xFfmWMDHcOFx80dsk1lb">
                        
                        <div id="inputEmail_information" class="happybox-information-class">
                            <div class="center_vertically_and_horizontally wrapped_text happybox-information-class_first_inner">">
                            </div>
                        </div>

                        <div id="inputEmail_error" style="min-height: 2.75em;" class="happybox-error-class">
                            <div class="center_vertically_and_horizontally wrapped_text">
                            </div>
                        </div>

                        <div class="flex-container" style="padding: 10px 0;">
                            <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required autofocus>
                        </div>


                        <div id="inputEmail_error" class="form-control-error form-control [# class #]" style="display: none;">
                        </div>

                        <div id="inputPassword_information" class="happybox-information-class" style="opacity: 0.3;height: 51px;">
                            <div class="center_vertically_and_horizontally wrapped_text happybox-information-class_first_inner">
                            </div>
                        </div>

                        <div id="inputPassword_error" class="happybox-error-class">
                            <div class="center_vertically_and_horizontally wrapped_text">
                            </div>
                        </div>

                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" id="password-div" style="opacity: 1;width: 100%">
                            <input class="mdl-textfield__input" style="" type="password" id="inputPassword" name="password" placeholder="Password" required="true">
                        </div>
                    </form>    
                </div>
    
                <div id="terms_and_conditions_error" class="happybox-error-class">
                    <div class="center_vertically_and_horizontally wrapped_text">
                    </div>
                </div><span id="success-register" style="display:none;">Registration Successful...</span><span id="loading_screen" style="display:none;">Sign In....Please wait</span>
        
                <button id="register_btn" style="" class="mdl-button mdl-button--raised mdl-button--colored btn-lg sign_up_form_btn green-btn" v-on:click="registration">
                    Sign Up
                </button>

                <div id="newsletter_input" class="newsletter_input text-sec" style=";">
                    <div style="/* width: 303px;*/font-size: 12px; text-align: center;">
                            By continuing you agree to our 
                        <router-link style="color: black;"  to="/terms">Terms &amp; Conditions</router-link>
                            and 
                        <router-link style="color: black;" to="/policy">Privacy Policy</router-link>
                    </div>
                </div>
                <div id="inputPassword_error" class="form-control-error form-control [# class #]" style="display: none;"></div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8"> 

                <form id="login_form" method="post" style="display: flex;flex-direction: column;justify-content: center;margin: 0em;margin-top: 70px;">
        
                    <div style="font-size: 21px !important;margin-top: 0;">
                        <div class="main_section_title">
                            Login
                        </div>
                    </div>
                    <input type="hidden" name="csrfmiddlewaretoken" value="2uc2esVSTjIRHWeYvrRm00JcUXesLYTj2XJUA8ctWRi5xFfmWMDHcOFx80dsk1lb">
            
                    <div id="login_error" class="happybox-error-class">
                        <div class="center_vertically_and_horizontally wrapped_text">
                        </div>
                    </div>

                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" id="login-email-div" style="width: 100%;padding: 15px 0px 16px 0;">
                        <input class="mdl-textfield__input" style="" type="email" id="login-email" name="email" placeholder="E-mail" required>
                    </div>

                    <div id="password_error" class="happybox-error-class">
                        <div class="center_vertically_and_horizontally wrapped_text">
                        </div>
                    </div>

                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" id="login-password-div" style="width: 100%;padding-bottom: 10px;">
                        <input class="mdl-textfield__input" style="" type="password" id="login-password" name="password" placeholder="Password">
                    </div>

                    <button id="login_btn" v-on:click="login_function" class="mdl-button mdl-button--raised mdl-button--colored btn-lg login_form_btn green-btn">
                        Login
                    </button>
                    
                    <div style="display: flex;justify-content: center;" class="forgot-text">
                        <router-link to="/user/password/reset/" style="color: black;">I forgot my password</router-link>
                    </div>

                     <div id="newsletter_input" style="display: block;flex-direction: row;margin-left: 0;justify-content: space-between;width: 100%;" class="text-sec">
                        <div style="font-size: 12px;text-align: center;">
                            By continuing you agree to our 
                            <router-link style="color: black;" to="/terms">Terms &amp; Conditions</router-link> 
                            and 
                            <router-link style="color: black;" to="/policy">Privacy Policy</router-link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>    
</template>
<script>
import axios from 'axios';
export default {
    name:'RegistrationLogin',
    data(){
        return{
        message:'',
        googleSignInParams: {client_id: '359600052331-e7534o4qgtbv08ccpertl7mtlhmvfcef.apps.googleusercontent.com'},
        ERROR_MODAL :{
                data: {
                    ID: "error-dialog",
                    firstPart: '<dialog class="mdl-dialog" style="width: 27em; z-index: 100149;">' +
                        '<div class="mdl-card__supporting-text">',
                    lastPart: '</div>' +
                        '<div class="mdl-dialog__actions">' +
                        '<button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 0.7em;font-family: \'proxima-nova\', sans-serif !important;border: none;" onclick="var obj = document.getElementById(\'error-dialog\'); obj.remove()" class="mdl-button confirm">Ok</button>' +
                        '</div>' +
                        '</dialog>',
                    title: '',
                    errors: ''
                },
                getters: {
                    title: function(modal_obj) {
                        return modal_obj.data.title;
                    },
                    errors: function(modal_obj, data) {
                        return modal_obj.data.errors;
                    }
                },
                setters: {
                    title: function(modal_obj, data) {
                        modal_obj.data.title = data;
                    },
                    errors: function(modal_obj, data) {
                        modal_obj.data.errors = data;
                    }
                },
                actions: {
                    
                    showMultipleChoiceWindow: function(title, errors, callback_reject, callback_accept) {
                        // Building up the entire modal
                        var new_div = document.createElement('div')
                        new_div.setAttribute('id', 'error-dialog')
                        var baseDiv = `
                    <div class="" style="width: 80vw;z-index: 100157;right: 0;padding: 1em;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;"
                        open="">
                        <div style="width: 100%;z-index: 100157;right: 0;background: #fff;color: #000;display: block;margin: 0;height: 100%;display: flex;flex-direction: column;justify-content: space-between;">
                            <div class="" style="font-size: 2.5vh;text-align: center;padding-bottom: 1em;padding-top: 1em;color: grey;">{0}</div>
                            <div class="">
                                <ul style="list-style-type: none;text-align: center;padding: 0;">
                                    {1}
                                </ul>
                            </div>
                            <div style="display: flex;flex-direction: row;justify-content: space-evenly;">
                                <button style="width: 100%;height: 5.2em;background: #ff5d5da6;color: white;font-size: 1.5vh;font-family: 'proxima-nova', sans-serif !important;border: none;" onclick="var obj = document.getElementById('error-dialog'); obj.remove(); {2};" class="mdl-button confirm">REJECT</button>
                                <button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 1.5vh;font-family: 'proxima-nova', sans-serif !important;border: none;" onclick="var obj = document.getElementById('error-dialog'); obj.remove(); {3};" class="mdl-button confirm">CONTINUE</button>
                            </div>
                        </div>
                    </div>
                    <div class="" style="width: 100vw;z-index: 20;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                `;
                        new_div.innerHTML = baseDiv.format(
                            title,
                            errors,
                            callback_reject,
                            callback_accept
                        );
                        // Appending new div to the document
                        var body_dom = document.querySelector('body');
                        body_dom.prepend(new_div);
                    },
                    showErrorModal: function(modal_obj, title = "Something wrong happened!", errors, callback = '') {
                        // Building up the entire modal
                        var errordialog = document.getElementById('error-dialog');
                        if (errordialog !== undefined) {
                            var new_div = document.createElement('div')
                            new_div.setAttribute('id', 'error-dialog')
                            var baseDiv = `
                        <div class="" style="width: 80vw;z-index: 100149;right: 0;padding: 1em;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;"
                            open="">
                            <div style="
                        width: 100%;
                        z-index: 100149;
                        right: 0;
                        background: #fff;
                        color: #000;
                        display: block;
                        margin: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    ">
                                <div class="" style="
                        font-size: 2.5vh;
                        text-align: center;
                        padding-bottom: 1em;
                        padding-top: 1em;
                        color: grey;
                    ">{1}</div>
                                <div class="">
                                    <ul style="list-style-type: none;text-align: center;padding: 0;">
                                        {0}
                                    </ul>
                                </div>
                                <div class=""><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 1.5vh;font-family: 'proxima-nova', sans-serif !important;border: none;" onclick="var obj = document.getElementById('error-dialog'); obj.remove();{2}" class="mdl-button confirm">Ok</button></div>
                            </div>
                        </div>
                        <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                    `;
                            new_div.innerHTML = baseDiv.format(
                                errors,
                                title,
                                callback
                            );
                            // Appending new div to the document
                            var body_dom = document.querySelector('body');
                            body_dom.prepend(new_div);
                        }


                    }
                }
            }
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Login & Sign up Page  | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Login & Signup ' },
                { name: 'keywords', content: 'Login & Signup' }
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
    },
    methods:{ 
    onSignInSuccess (googleUser) {
        document.getElementById('loading_screen').style = "display:block";
        const profile = googleUser.getBasicProfile();
        var self = this;
        var user_email =profile.getEmail();
        axios.post('/webapi/webshop/customer_get_personal_info_by_email',{
            user_email: user_email,
        }).then(response => {
            if (response.data.statusCode === "200" || response.data.statusCode === 200) {
             document.getElementById('success-register').style = "display:block";
                    self.$session.start();
                    self.$session.set("EmailID", response.data.customerData.email_id);
                    self.$session.set("LoginID", response.data.customerData.id);

                    self.LoginUser(self.$session.get('EmailID'),self.$session.get('LoginID'));
                    
                    // redirecting start
                    var customer_id = self.$session.get("LoginID");
                    var session_id = self.$cookie.get('device');
                    var quote_id = '';
                    if(self.$session.has("QuoteId") === false)
                    {
                        quote_id = '';
                    }
                    else
                    {
                        quote_id = self.$session.get("QuoteId");
                    }
                    axios.post('/webapi/webshop/cart_listing',{
                        quote_id: quote_id,
                        customer_id: customer_id,
                        session_id: session_id,
                    })
                    .then(response => {
                        if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                            self.$router.push({ path: '/checkout/step1' });
                        }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                            self.$router.push({ path: '/my-account' });
                        }
                    })
                }
                else if(response.data.message == 'No data found'){
                    axios.post('/webapi/webshop/register',{
                        email: user_email,
                        loginwith : 'gmail',

                    }).then(response => {
                        if (response.data.statusCode === "200" || response.data.statusCode === 200) {
                            self.$session.start();
                            self.$session.set("EmailID", response.data.userdetails.email_id);
                            self.$session.set("LoginID", response.data.userdetails.id);

                            self.LoginUser(self.$session.get('EmailID'),self.$session.get('LoginID'));

                            // redirecting start
                            var customer_id = self.$session.get("LoginID");
                            var session_id = self.$cookie.get('device');
                            var quote_id = '';
                            if(self.$session.has("QuoteId") === false)
                            {
                                quote_id = '';
                            }
                            else
                            {
                                quote_id = self.$session.get("QuoteId");
                            }
                            axios.post('/webapi/webshop/cart_listing',{
                                quote_id: quote_id,
                                customer_id: customer_id,
                                session_id: session_id,
                            })
                            .then(response => {
                                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                                    self.$router.push({ path: '/checkout/step1' });
                                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                                    self.$router.push({ path: '/my-account' });
                                }
                            })
                        }
                    })
                }
                else{
                    console.log(response.data.message);
                }
        })
        // console.log(profile, this.$session.get("EmailID"), this.$session.get("LoginID"), this.$session.get("Full Name"));
    },
    onSignInError (error) {
    alert('Input credentials are wrong!');
        console.log('OH NOES', error)
    },
    login_function(event){
            event.preventDefault();
            var form_input = document.getElementById('login_form');
            var payload = new FormData(form_input);
            this.loginFunc(undefined, payload);
            
        },
        loginFunc(event = undefined, payload = undefined) {
           
            if (event != undefined) {
                event.preventDefault();
            }
            var self = this;
            // Making sure that inputs are not empty
            var login_email = document.getElementById('login-email');
            var login_password = document.getElementById('login-password');

            if (login_email.value.length == 0) {
                this.enableOrDisableErrorMessage('login_error', 'E-mail address cannot be empty', true);
                return;
            }
            if (login_password.value.length == 0) {
                this.enableOrDisableErrorMessage('password_error', 'Password must contain at least 5 characters', true);
                return;
            }

            var user_email = document.getElementById('login-email').value;
            var user_password = document.getElementById('login-password').value;
            axios.post('/webapi/webshop/login', {
                        email: user_email,
                        password : user_password
                    })
                    .then(function(response) {
                        //console.log("Login Details :",response.data.userdetails);
                        if (response.data.statusCode === "200" || response.data.statusCode === 200)
                        {
                            self.message = response.data.message;
                            document.getElementById('success-dialog').style.display = 'block';
                            self.$session.start();
                            self.$session.set("EmailID", response.data.userdetails.EmailID);
                            self.$session.set("FirstName", response.data.userdetails.FirstName);
                            self.$session.set("LastName", response.data.userdetails.LastName);
                            self.$session.set("LoginID", response.data.userdetails.LoginID);
                            self.$session.set("LoginToken", response.data.userdetails.LoginToken);
                            self.$session.set("customer_type_id", response.data.userdetails.customer_type_id);

                            self.LoginUser(self.$session.get('EmailID'),self.$session.get('LoginID'));

                            // redirecting start
                            var customer_id = self.$session.get("LoginID");
                            var session_id = self.$cookie.get('device');
                            var quote_id = '';
                            if(self.$session.has("QuoteId") === false)
                            {
                                quote_id = '';
                            }
                            else
                            {
                                quote_id = self.$session.get("QuoteId");
                            }
                            axios.post('/webapi/webshop/cart_listing',{
                                quote_id: quote_id,
                                customer_id: customer_id,
                                session_id: session_id,
                            })
                            .then(response => {
                                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                                    self.$router.push({ path: '/checkout/step1' });
                                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                                    self.$router.push({ path: '/basket/' });
                                }
                            })
                            // redirecting end

                            // window.location.href = '/basket/';
                        }else if(response.data.statusCode === "500" || response.data.statusCode === 500){
                            self.message = response.data.message;
                            //console.log(self.message);
                            document.getElementById('error-message-dialog').style.display='block';
                        }
                    }
                )
                
                
        },
        registration(evt){
            evt.preventDefault();
            var inputPassword = document.getElementById("inputPassword");
            if (inputPassword.value.length == 0) {
                this.enableOrDisableErrorMessage('inputPassword_error', 'Your password must contain at least 5 characters', true);
                return;
            } else {
                this.enableOrDisableErrorMessage('inputPassword_error', '', false);
            }
            this.registerThePassword();
        },

        registerThePassword(pincode) {
            var self = this;
            self.$session.start();
            var registrationButton = document.getElementById('register_btn');
            //if(validateTextWithRegex(document.getElementById('inputPassword').value), NumberRegeX) {
            if(registrationButton){
                // registrationButton.style='background-image: linear-gradient(#d2d2d2,#d2d2d2) !important;background-color: grey !important;box-shadow: none !important;border: grey !important;background-color:rgba(0,0,0,0.12) !important;';
                this.enableOrDisableErrorMessage('inputPassword_error', '', false);

                // Password validated
                var form_input = document.getElementById('form-signin');
                var payload = new FormData(form_input);
                payload.set('email', document.getElementById('inputEmail').value);
                payload.set('signup', true);
                // payload.set('pincode', validatePinCode());
                payload.set('password', document.getElementById('inputPassword').value);
				document.getElementById('loading_screen').style = "display:block";
                return axios({
                        method: 'post',
                        url: '/webapi/webshop/register',
                        data: payload,
                        config: {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    }).then(response => { 
                        if (response.data.statusCode === 200 || response.data.statusCode === "200") {
                        document.getElementById('loading_screen').style = "display:none";
                         document.getElementById('success-register').style = "display:block";
                            var local_register_btn = document.getElementById('register_btn');

                            self.$session.set("LoginID", response.data.userdetails.id);
                            self.$session.set("EmailID", response.data.userdetails.EmailID);

                            
                            // local_register_btn.setAttribute('disabled', 'disabled');

                            this.loginFuncRegister();
                            // changeDisabledState(['inputEmail', 'inputPassword'], true);
                        } else if (response.data.statusCode === 500) {
                            self.message = response.data.message;
                            document.getElementById('error-message-dialog').style.display = 'block';
                        } else {
                            if (response.data.errors !== null && response.data.errors !== undefined) {
                                // Building the error form
                                this.ERROR_MODAL.actions.parseErrorList(JSON.parse(response.data.errors));
                            }
                            document.getElementById('loading_screen').style = "display:none";
                            
                            self.message = response.data.message;
                            document.getElementById('error-message-dialog').style.display = 'block';
                        }
                    })
                    .catch(e => {
                        self.message = e;
                        console.log('User already registered with this email address'+self.message);
                        // document.getElementById('error-message-dialog').style.display = 'block';
                    })
            }
        },

        loginFuncRegister(event = undefined, payload = undefined) {
            var self = this;
            self.$session.start();
            if (event != undefined) {
                event.preventDefault();
            }

            if (payload == undefined) {
                var form_input = document.getElementById('form-signin');
                 var payload_mail = new FormData(form_input);
                payload_mail.set('email', document.getElementById('inputEmail').value);
            }
                var user_email =    document.getElementById('inputEmail').value                  
               
            axios.post('/webapi/webshop/verify_phone', {
                       
                        email: user_email
                    })
                .then(function(response) {
                    console.log("registration Phone test:",response);
                    if (response.data.statusCode === "200" || response.data.statusCode === 200) {
                        var next_param_url = '';

                        if (next_param_url != undefined) {
                            // if (next_param_url != '/checkout/step1' && next_param_url != '/basket/' && next_param_url != '/checkout/map') {
                            if (next_param_url != '/checkout/step1' && next_param_url != '/basket/') {
                                next_param_url = undefined;
                            }
                        }


                        var phone_number_verified = response.phone_number_verified;
                        if (phone_number_verified == false || phone_number_verified == null ) {
                            var redirect_url = '';
                            self.$session.set('EmailID',document.getElementById('inputEmail').value);
                             if (next_param_url != undefined) {
                                self.$router.push({ path: '/login/step2' });
                            } else {
                                self.$router.push({ path: '/login/step2' });
                            }
                        } else {
                            if (
                                next_param_url != undefined
                            ) {
                                window.location.href = next_param_url;
                            } else {
                                self.$router.push({ path: '/' });
                            }
                        }
                    } else {
                        alert(JSON.stringify(response));
                    }
                    //return response.json();
                })
                .then(function(response) {
                    console.log(response);
                    
                });
        },

        LoginUser(Email_ID,Login_ID)
        {
            var dataLayer = window.dataLayer || [];
            dataLayer.push({
                'event': 'login',
                'userEmailAddress' : Email_ID,
                'userId' : Login_ID // loginID
            });
        },

        


        // getUrlParam(parameter = '', defaultvalue = '') {
        //     // alert('In Urlparameter');
        //     var urlparameter = defaultvalue;
        //     if (window.location.href.indexOf(parameter) > -1) {
        //         urlparameter = this.getUrlVars()[parameter];
        //     }
        //     return urlparameter;
        // },

        // // URL FUNCTIONS
        // getUrlVars() {
        //     var vars = {};
        //     var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
        //         vars[key] = value;
        //     });
        //     return vars;
        // },

        enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
            var mainElement = document.getElementById(idOfTheMainElement);
            if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
                var mainElement_first_children = mainElement.firstElementChild;
                if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
                    mainElement_first_children.innerHTML = messageText;
                    if (!disableFlag) {
                        mainElement.style.display = "none";
                    } else {
                        mainElement.style.display = "block";
                    }
                }
            }
        },

        checkIfObjectIsNotUndefiendOrNull(obj) {
            if (obj === undefined || obj === null) {
                return true;
            } else {
                return false;
            }
        },


    }
       
}
   
   
</script>
<style scoped>
#success-register{color:green !important;height:20px;padding:10px;border:1px solid green !important;}
#loading_screen{color:white !important;background:orange !important;height:20px;padding:10px;}
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
.form-control-error{
    background:red
}
.btn-social-google-oauth{
    max-width:7em
    }
.google_text{
    width:86px;
    height:65px;
    position:absolute;
    margin:0;
    position:absolute;
    top:50%;
    right:0;
    -ms-transform:translate(-50%,-50%);
    transform:translate(0,-42%)}.google_icon{width:1.75em;padding-right:10px;
    height:1.75em;
    margin:0;
    position:absolute;
    top:50%;
    left:9vw;
    -ms-transform:translate(-50%,-50%);
    transform:translate(-50%,-50%)
    }
.sign_up_form_btn{
    width:82vw !important;
    padding:0 !important;
    background-image:linear-gradient(#67ae55,#578843) !important;
    background-color:#69a74e !important;
    box-shadow:#baff9b 0 0 6px inset !important;
    border:1px solid currentcolor;
    border-top-left-radius:5px !important;
    border-top-right-radius:5px !important;
    border-bottom-right-radius:5px !important;
    border-bottom-left-radius:5px !important;
    color:white !important;
    cursor:pointer !important;
    letter-spacing:1px !important;
    position:relative !important;
    text-shadow:rgba(0,0,0,0.498039) 0 1px 2px !important;
    font-weight:bold !important;
    margin:0 auto auto !important;
    display:block !important;
    text-align:center !important;
    font-size:1.4em !important;
    height:2.59em !important;
    background-position:initial initial !important;
    background-repeat:initial initial !important;
    max-width:16em !important
    }
.form-control-error{
    background:red
}
.invalid-feedback{
    display:block;
    margin-top:.25rem;
    font-size:80%;
    color:#dc3545
}
.flex-container{
    display:flex;
    justify-content:center;
    flex:row
}
.form-pin-code{
    text-align:center !important;
    min-height:49.5px
}
.form-pin-code ::-webkit-input-placeholder{
    color:pink
}
.form-pin-code ::-moz-placeholder{
    color:pink
}
.form-pin-code :-ms-input-placeholder{
    color:pink
}
.form-pin-code :-moz-placeholder{
    color:pink
}
.divider-input-code{
    font-size:9vh;
    text-align:center;
    margin-left:.1em;
    margin-right:.1em;
    margin-bottom:auto;
    margin-top:0
}
.newsletter_input{
    flex-direction:row;
    justify-content:space-between
}
.login_form_btn{
    width:82vw !important;
    padding:0 !important;
    background-image:linear-gradient(#67ae55,#578843) !important;
    background-color:#69a74e !important;
    box-shadow:#baff9b 0 0 6px inset !important;
    border:1px solid currentcolor !important;
    border-top-left-radius:5px !important;
    border-top-right-radius:5px !important;
    border-bottom-right-radius:5px !important;
    border-bottom-left-radius:5px !important;
    color:white !important;
    cursor:pointer !important;
    letter-spacing:1px !important;
    position:relative !important;
    text-shadow:rgba(0,0,0,0.498039) 0 1px 2px !important;
    font-weight:bold !important;
    margin:0 auto auto !important;
    display:block !important;
    text-align:center !important;
    font-size:1.4em !important;
    height:2.59em !important;
    background-position:initial initial !important;
    background-repeat:initial initial !important;
    max-width:16em !important
}
@media(max-width:599px){
    .row-main-div{
        margin-left:0;
        margin-right:0
    }
}
@media(min-width:600px){
    .row-main-div{
        margin-left:24vh;
        margin-right:24vh
    }
}
@media(min-width:900px){
    .row-main-div{
        margin-left:24vh;
        margin-right:24vh
    }
}
@media(min-width:1200px){
    .row-main-div{
        margin-left:24vh;
        margin-right:24vh
    }
}
@media(min-width:1800px){
    .row-main-div{
        margin-left:24vh;
        margin-right:24vh
    }
}
.signup_main_class{
    height:auto;
    margin-top:4vw
}
@media(max-width:599px){
    .signup_main_class{
        margin-left:7vw;
        margin-right:7vw
    }
}
@media(min-width:600px){
    .signup_main_class{
        height:auto;
        max-width:600px;
        margin:auto;
        margin-top:auto;
        margin-top:8vw
    }
}
@media(min-width:900px){
    .signup_main_class{
        height:auto;
        max-width:600px;
        margin:auto;
        margin-top:auto;
        margin-top:8vw
    }
}
@media(min-width:1200px){
    .signup_main_class{
        height:auto;
        max-width:600px;
        margin:auto;
        margin-top:auto;
        margin-top:8vw
    }
}
@media(min-width:1800px){
    .signup_main_class{
        height:auto;
        max-width:600px;
        margin:auto;
        margin-top:auto;
        margin-top:8vw
    }
}
@media(min-width:600px){
    .newsletter_input{
        margin-left:3em;
        margin-right:3em
    }
}
@media(min-width:900px){
    .newsletter_input{
        margin-left:3em;
        margin-right:3em
    }
}
@media(min-width:1200px){
    .newsletter_input{
        margin-left:3em;
        margin-right:3em
    }
}
@media(min-width:1800px){
    .newsletter_input{
        margin-left:3em;
        margin-right:3em
    }
}
</style>