<template>
    <div class="container main-box-sec order-success">
        <div class="row row-main-div">
            <div class="row_main_div_first_col_md_class">
                <div id="maindivshopproduct_7" class="">
                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div class="sucess-img" style="">
                                <img style="" src="/assets/img/order_complete_icon.png">
                                <div style="font-size: 19px;color: #2F1317;font-family: 'Arial';text-align: center;padding-top: 2em;display: inline;flex-direction: row;justify-content: left;font-weight: bold !important;padding-bottom: 0em;padding-left: 3em;padding-right: 3em;color: #664242;">
                                    <div class="main_section_title">Sorry,<br>Your transaction has failed. Please go back and try again.</div>
                                    
                                </div>
                            </div>
                      </div>
						<div  style="width: 100%;margin-bottom: 0em;margin-top: 1em;">
							<router-link to="/checkout/step2"><button style="width: 100%;height: 4em;background: rgb(238,153,47);background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;" class="green-btn">Go Back</button></router-link>
                        </div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'OrderFailure',
    data(){
        return{
            Failure:'',
        }
    },
	metaInfo(){
        return {
            titleTemplate:'Payment Failure | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Payment Failure' },
                { name: 'keywords', content: 'Payment Failure' }
            ]
        }
    },
    mounted(){
        this.$session.start();
        this.getFailureresponse();
    },
    methods:{
        getFailureresponse(){
            var payload = new FormData();
            payload.set('orderid', this.$session.get("orderid"));
            payload.set('paymentId', this.$route.query.paymentId);
            return axios({
                method: 'post',
                url: 'admin/PaymentController/StoreApiResponse',
                data: payload,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.Failure = response.data;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.Failure = response.data;
                    
                }
            })
        }
    }
    
}
</script>

<style scoped>
@media(max-width:599px) {
	.row_main_div_first_col_md_class {
		margin: 1em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:600px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:900px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:1200px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:1800px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}
</style>