<template>
	<div>
		<!-- spinner -->
		<div v-if="loading">
			<SpinnerData />
		</div>
		<!-- spinner end -->
		<div>
			<form>

				<!-- myfatoorah iframe start -->
				<div id="myFatoorah-payment" style="display: none;">
					<div class="online-payment-card-outer" open="open"
						style="width: 100%; z-index: 100149; right: 0px; padding: 40px 20px; background: rgb(255, 255, 255); color: rgb(0, 0, 0); display: block; margin: 0px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); max-width: 600px; height: 40vh; filter: drop-shadow(rgba(0, 0, 0, 0.16) 0px 3px 3.5px);">
						<div
							style="width: 100%; z-index: 100149; right: 0px; background: rgb(255, 255, 255); color: rgb(0, 0, 0); display: flex; margin: 0px; height: 100%; align-items: center;">
							<div style="margin: 0px auto;">
								<div class="dialog-title"
									style="font-size: 32px; line-height: 40px; color: rgb(0, 0, 0); font-weight: bold; text-align: center;">
									Online Payment</div>
								<div data-v-49cbee9c="">
									<iframe width="300px" height="300px" id="destination"
										src="https://happybox.com/data.html"></iframe>
								</div>
							</div>
						</div>
					</div>
					<div open="open"
						style="z-index: 100148; position: fixed; left: 0px; right: 0px; margin: auto; padding: 1em; background: grey; opacity: 0.5; display: block; top: 0px; height: -webkit-fill-available;">
					</div>
				</div>
				<!-- myfatoorah iframe end -->

				<div class="container main_container main-box-sec">
					<div class="row row-main-div">
						<div class="col-md-6" style="margin: 0em;">
							<div id="maindivshopproduct_7" class="">

								<div>
									<div style="display: flex;flex-direction: column;justify-content: center;">
										<div>
											<div class=""
												style="display: flex;flex-direction: column;justify-content: space-evenly;">
												<div class="main_section_title">Checkout</div>
												<div class="delivery_time_payment_div" style="margin: 0;">
													<div>
														Payment:
													</div>
												</div>
												<!-- Success Messages start -->
												<div id="success-dialog" style="display: none;">
													<div class=""
														style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));"
														open="">
														<div
															style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
															<div style="margin:0 auto;">
																<div class="dialog-title"
																	style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">
																</div>
																<div class="">
																	<ul class="dialog-content"
																		style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
																		{{ message }}
																	</ul>
																</div>
																<div class="dialog-button"><button
																		style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;"
																		type="button"
																		onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';"
																		class="mdl-button confirm">Ok</button></div>
															</div>
														</div>
													</div>
													<div class=""
														style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;"
														open=""></div>
												</div>
												<!-- success Messages End -->

												<!-- Error Messages of same day Delivery -->
												<div id="error-sameday-delivery-dialog" style="display: none;">
													<div class="ankit"
														style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));"
														open="">
														<div
															style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
															<div style="margin:0 auto;">
																<div class="dialog-title"
																	style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">
																	Sorry</div>
																<div class="">
																	<ul class="dialog-content"
																		style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
																		Some Items in your cart are not available for same
																		day Delivery. Please check and clear your cart.
																	</ul>
																</div>
																<div class="dialog-button"><button
																		style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;"
																		type="button"
																		onclick="var obj = document.getElementById('error-sameday-delivery-dialog'); obj.style.display= 'none';"
																		class="mdl-button confirm">Ok</button></div>
															</div>
														</div>
													</div>
													<div class=""
														style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;"
														open=""></div>
												</div>
												<!-- Error Messages End -->

												<!-- Error Messages start -->
												<div id="error-dialog" style="display: none;">
													<div class=""
														style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));"
														open="">
														<div
															style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
															<div style="margin:0 auto;">
																<div class="dialog-title"
																	style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">
																	Warning</div>
																<div class="">
																	<ul class="dialog-content"
																		style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
																		{{ message }}
																	</ul>
																</div>
																<div class="dialog-button"><button
																		style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;"
																		type="button"
																		onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';"
																		class="mdl-button confirm">Ok</button></div>
															</div>
														</div>
													</div>
													<div class=""
														style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;"
														open=""></div>
												</div>
												<!-- Error Messages End -->

												<!--static Error Messages start -->
												<div id="static-error-dialog" style="display: none;">
													<div class=""
														style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));"
														open="">
														<div
															style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
															<div style="margin:0 auto;">
																<div class="dialog-title"
																	style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">
																	Warning</div>
																<div class="">
																	<ul class="dialog-content"
																		style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
																		Please mark the payment method before continuing
																	</ul>
																</div>
																<div class="dialog-button"><button
																		style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;"
																		type="button"
																		onclick="var obj = document.getElementById('static-error-dialog'); obj.style.display = 'none';"
																		class="mdl-button confirm">Ok</button></div>
															</div>
														</div>
													</div>
													<div class=""
														style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;"
														open=""></div>
												</div>
												<!-- Error Messages End -->

												<!--Delivery-not-avilable-dialog Messages start -->
												<div id="Delivery-not-available-dialog" style="display: none;">
													<div class=""
														style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));"
														open="">
														<div
															style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
															<div style="margin:0 auto;">
																<div class="dialog-title"
																	style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">
																	Warning</div>
																<div class="">
																	<ul class="dialog-content"
																		style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
																		Delivery time not available for this date.
																	</ul>
																</div>
																<div class="dialog-button"><button
																		style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;"
																		type="button"
																		onclick="var obj = document.getElementById('Delivery-not-available-dialog'); obj.style.display = 'none';"
																		class="mdl-button confirm">Ok</button></div>
															</div>
														</div>
													</div>
													<div class=""
														style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;"
														open=""></div>
												</div>
												<!-- Error Messages End -->

												<div id="Order_Payment_Type_form"
													style="height: min-content;display: flex;flex-direction: row;justify-content: space-between;"
													name="fileinfo">
													<div
														style="width: 100%;display: grid;grid-template-columns: 0.1fr 1fr 0.1fr 1fr;grid-template-rows: 1fr;grid-gap: 3em;">

														<div class="selection-radio-sec">
															<div class="round" style="">
																<input value="0" type="radio" name="variant"
																	v-on:click="change_payment_type" id="pay_with_radio1">
																<label for="pay_with_radio1"
																	style="border: 1px solid #2F1317; border: 1px solid#2F1317;margin: 0;position: absolute;left: 0%;"></label>
															</div>
															<div class="Order_Payment_type_form_cash_on_delivery">Cash on
																delivery</div>
														</div>
														<div class="selection-radio-sec">
															<div class="round" style="">
																<input value="1" type="radio" name="variant"
																	v-on:click="change_payment_type" id="pay_with_radio2">
																<label for="pay_with_radio2"
																	style="border: 1px solid #2F1317; border: 1px solid#2F1317;margin: 0;position: absolute;left: 0%;"></label>
															</div>
															<div class="Order_Payment_type_form_online_payment">
																<div style="text-align:left;">
																	Online payment
																</div>
																<div class="online_payment_options">
																	<img class="nav_icon_checkout_div nav_icon_checkout_div_mastercard"
																		alt="Credit Card" src="/assets/img/mastercard.png">
																	<div class="payment_method_separator_checkout_div">
																		&nbsp; / &nbsp;</div>
																	<img class="nav_icon_checkout_div "
																		src="/assets/img/visa.svg">
																</div>
															</div>
														</div>
														<div class="selection-radio-sec" id="applepay-device"
															style="display: none;">
															<div class="round" style="">
																<input value="2" type="radio" name="variant"
																	v-on:click="change_payment_type" id="pay_with_radio3">
																<label for="pay_with_radio3"
																	style="border: 1px solid #2F1317; border: 1px solid#2F1317;margin: 0;position: absolute;left: 0%;"></label>
															</div>
															<div class="Order_Payment_type_form_online_payment">
																<div class="Order_Payment_type_form_cash_on_delivery">
																	ApplePay
																</div>
																<div class="online_payment_options">
																	<img class="nav_icon_checkout_div nav_icon_checkout_div_mastercard"
																		alt="Credit Card" src="/assets/img/applepay.png">
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="Order_Delivery_Desired_Time_form" style="width: 100%;" name="fileinfo">

											<div class="delivery_desired_time_first_div">
												<div class="delivery_time_payment_div">
													<div>
														Select date:
													</div>
												</div>
												<div class="frontend-date-validn"
													style="display: flex;flex-direction: row;justify-content: space-around;align-items: stretch;">
													<div v-on:click="change_the_date(day = 'today'); cleartimeslot()"
														class="calendar_date_day" id="date_today">
														<div>Today</div>
														<div style="padding-top: 8px;" id="current_date">
															<!-- {{currentDateTime()}} -->
														</div>
													</div>
													<div v-on:click="change_the_date(day = 'tomorrow'); cleartimeslot()"
														class="calendar_date_day" id="date_tomorrow">
														<div>
															Tomorrow
														</div>
														<div id="tomorrow_date" style="padding-top: 8px;">
															<!-- {{tomorrowDateTime()}} -->
														</div>
													</div>
													<!-- pick a date -->
													<div id="date_pickup" class="select-date-time">
														<datepicker placeholder="Pick Date"
															@closed="change_the_date(day = 'pickday'); cleartimeslot()"
															type="date" :disabled-dates="disabledDates" format="d MMMM"
															v-model="selectDate"></datepicker>
													</div>


												</div>
												<div id="schedule_info"
													class="happybox-info-class extra_happybox-info-class"
													style="margin-bottom: 1em;min-height: 64px;display: none;">
													<div class="center_vertically_and_horizontally wrapped_text">Due to the
														high demand, on Feb 14th we do not accept orders anymore</div>
												</div>
												<div id="" style="display: none;" class="desired_time_form_css">
													<div class="round" style="display:none;">
														<input value="now"
															onClick="change_the_hour(null, '');PAYMENT_SUMMARY.actions.change_desired_delivery_time(this.value)"
															type="radio" name="User_Delivery_Location_Desired_Time"
															id="User_Delivery_Location_Desired_Time_now">
														<label for="User_Delivery_Location_Desired_Time_now"
															style="border: 1px solid #2F1317; border: 1px solid#2F1317;margin: 0;position: absolute;top: 0;left: 0%;"></label>
													</div>
													<div id="desired_delivery_time_now_text"
														class="desired_delivery_time_now_text" style="
																												">
														Now
													</div>
												</div>
												<div id="" style="opacity: 0;">
													<div style="opacity: 0;width: 0;height: 0;">
														<div class="round" style="">
															<input checked value="scheduled" type="radio"
																onClick="schedule_date_hour_has_been_shown=false;PAYMENT_SUMMARY.actions.change_desired_delivery_time(this.value, true);"
																name="User_Delivery_Location_Desired_Time"
																id="User_Delivery_Location_Desired_Time_scheduled">
															<label for="User_Delivery_Location_Desired_Time_scheduled"
																style="border: 1px solid #2F1317; border: 1px solid#2F1317;margin: 0;position: absolute;top: 0;left: 0%;"></label>
														</div>
														<div style="display: none;opacity: 0;width: 0;height: 0;"
															class="schedule_div_checkout_component">Schedule</div>
														<div style="opacity: 0;width: 0;height: 0;">
															<input style="opacity: 0;"
																onChange="PAYMENT_SUMMARY.actions.change_desired_delivery_time_input_perspective()"
																readonly="readonly" id="Order_Delivery_Desired_Time_day"
																required name="Order_Delivery_Desired_Time_day" type="text"
																class="change_desired_delivery_time_input_perspective_class">
														</div>
														<div style="opacity: 0;width: 0;height: 0;">
															<input readonly="readonly" id="Order_Delivery_Desired_Time_hour"
																name="Order_Delivery_Desired_Time_hour" type="text"
																style="text-align: center; display: none;">
														</div>
														<div id='Order_Delivery_Desired_Time_hour_calendar' class="inactive"
															style="z-index: 1000;padding: 5px 0;font-size: 14px;text-align: left;list-style: none;-webkit-background-clip: padding-box;background-clip: padding-box;border-bottom: 7px solid rgba(0,0,0,0.2);border-left: 7px solid transparent;border-right: 7px solid transparent;content: &quot;&quot;;display: inline-block;right: 91px;position: absolute;top: 40px;width: 212px;margin: 0;display: block;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);">
															<div id="schedule_error" class="happybox-error-class"
																style="margin-top: 0em;  min-height: 84px; opacity: 1; display: block;border: none;border-radius: 0;background: white;color: red;border: 1px solid #ddd;">
																<div
																	class="center_vertically_and_horizontally wrapped_text">
																	We do experience overcapacity at speficied time, please
																	choose a later delivery date
																</div>
															</div>
															<table
																style="border-collapse: collapse;border-spacing: 0;border: 1px solid #ddd;width: 100%;height: 100%;background-color: #fff;">
																<tbody
																	style="font-size: 14px;text-align: left;list-style: none;">
																	<tr>
																		<td colspan="3"
																			onclick="PAYMENT_SUMMARY.actions.incrementHourCalendar();"
																			style="min-width: 30px;border: 1px solid #ddd;">
																			<a href="#" data-action="incrementHour"
																				style="border: 1px transparent solid;width: 100%;display: inline-block;margin: 0;padding: 8px 0;outline: 0;color: #333;font-size: 22px;">
																				<img class="fa_arrow_checkout"
																					src="assets/img/fa_icons/fa-arrow-up.svg">
																			</a>
																		</td>
																	</tr>
																	<tr>
																		<td colspan="3"
																			onclick="PAYMENT_SUMMARY.actions.decrementHourCalendar();"
																			style="min-width: 30px;border: 1px solid #ddd;">
																			<a href="#" data-action="decrementHour"
																				style="border: 1px transparent solid;width: 100%;display: inline-block;margin: 0;padding: 8px 0;outline: 0;color: #333;font-size: 22px;">
																				<img class="fa_arrow_checkout"
																					src="assets/img/fa_icons/fa-arrow-down.svg">
																			</a>
																		</td>
																	</tr>
																</tbody>
															</table>
															<button id="setCalendarHour"
																style="width: 100%;height: 58px;background: #fefefe;color: black;font-size: 24px;text-align: center;padding-top: 0px;border: 1px solid #d9d9d9;border-top: 0;">
																Set
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div id="hour_selector" style="display: block;">
											<input type="hidden" name="csrfmiddlewaretoken"
												value="wKO05AnNZy6aR4FKJfxY7HbUkMoycoopGPPhPMytAnV10IbDU1kDtwK6ZWPFlkv4">
											<div class="">
												<div class="delivery_time_payment_div">
													<div>Select time:</div>
												</div>
												<div id="" style="width: 100%;display: flex;flex-direction: column;">
													<div v-for="DeliveryTime in DeliveryTimeData" :key="DeliveryTime.id">
														<span name="11" style='display: none;'
															v-on:click="getDeliveryTime(DeliveryTime.id)" ref="dynamicTime"
															:id="'selected_delivery_time' + DeliveryTime.id"
															class="disabled_hour time_hour_class_span_valentines_day not_valentines_day commonCardClass">
															<div
																style="font-size: 14px;display: flex;flex-direction: row;justify-content: space-between;">
																<input type="hidden" name="selected_delivery_time"
																	:id="'selected_delivery_time_value' + DeliveryTime.id"
																	:value="DeliveryTime.start_time + ' - ' + DeliveryTime.end_time">
																{{ DeliveryTime.start_time }} - {{ DeliveryTime.end_time }}
																<div>( 29 AED )</div>
															</div>
														</span>
													</div>

													<div id="time-not-available"
														style="width:100%;display: flex;flex-direction: column;display:none">
														<div>Delivery time not available for this date.</div>
													</div>

													<div id="select-delivery-date"
														style="width:100%;display: flex;flex-direction: column;display:none">
														<div>Please select Delivery date.</div>
													</div>

													<!-- 60 min express delivery -->
													<div style='display: none !important;' id="offer_time">
														<span name="24" v-on:click="getDeliveryTime('60min',)"
															ref="expressTime" id="selected_delivery_time60min"
															class="time_hour_class_span_valentines_day not_valentines_day commonCardClass">
															<div
																style="font-size: 14px;display: flex;flex-direction: row;justify-content: space-between;">
																<input type="hidden" name="selected_delivery_time"
																	id="selected_delivery_time_value60min"
																	:value="deliveryoffer.name">
																<div style="float:left !important;">{{ deliveryoffer.name }}
																</div>
															</div>
															<div
																style="font-size: 14px;display: flex;text-align: end;flex-direction: row;justify-content: space-between;">
																( {{ deliveryoffer.price }} AED )</div>
														</span>
													</div>

												</div>
											</div>
										</div>
									</div>


									<div class="enter_the_promo_code_div">
										<div id="coupon_code_error" class="happybox-error-class">
											<div class="center_vertically_and_horizontally wrapped_text">
											</div>
										</div>
										<div class="promo_code_section"
											style="height: min-content;width: 100%;margin-top: 0em;margin-bottom: 7px;display: flex;flex-direction: row;justify-content: space-between;display: flex;flex-direction: row;"
											id="">
											<div class="enter_the_promo_code_inner_input">
												<div id="Prom_Code_form" name="fileinfo">
													<input class="enter_the_promo_code" placeholder="Enter the promo code"
														name="code" id="coupon_code" type="text" style="">
												</div>
											</div>
											<div style="height: 100%;padding: 5px;">
												<button type="button" class="enter_the_promo_code_apply_button"
													v-on:click="applyCouponCode">Apply</button>
											</div>
											<div style="display: none !important;height: 100%;padding: 5px;"
												id="remove_promo">
												<button type="button"
													style="font-size: 29px;background: white;border: 1px solid #75cc60;"
													v-on:click="removeCouponCode">&#x2716;</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div style="display: flex;flex-direction: column;justify-content: left;">
						<div style="display: flex;flex-direction: column;justify-content: center;">
							<div style="display: flex;flex-direction: column;justify-content: center;">
								<div id="discount_component"
									style="font-size: 11px;font-family: 'Arial';text-align: center;padding-top: 0em;font-weight: bold !important;padding-right: 23px;color: #664242;display: none;flex-direction: row;justify-content: space-between;font-size: 17px;">
									<div style="color: green;font-weight: bold;">
										Discount:
									</div>
									<div style="font-weight: normal;color: green;font-weight: bold;">
										<div id="voucher_discount_value">
										</div>
									</div>
								</div>
								<div class="checkout_delivery_fee_sub_total_div">
									<div>Sub Total:
									</div>
									<div style="font-weight: normal;" class="grand_total">
										<div class='original_price '>AED {{ basketcartDetails.base_subtotal }}</div>
									</div>
								</div>
								<div class="checkout_delivery_fee_sub_total_div">
									<div>Delivery Fee:
									</div>
									<div style="font-weight: normal;" id="shipping_offer_amount">
										AED {{ basketcartDetails.shipping_amount }}
									</div>
								</div>
								<div class="checkout_delivery_fee_sub_total_div" id="coupon_code_discount"
									style="display:none !important;">
									<div>Coupon Discount:</div>
									<div style="font-weight: normal;">
										- AED {{ coupon_details }}
									</div>
								</div>
								<div class="checkout_delivery_fee_sub_total_div" id="greeting_card_price"
									style="display:none !important;">
									<div>Greeting Card:</div>
									<div style="font-weight: normal;">
										AED 19.00
									</div>
								</div>
								<div class="checkout_delivery_fee_sub_total_div" id="vat_amount">
									<div>VAT(+ 5% ):</div>
									<div style="font-weight: normal;">
										AED {{ basketcartDetails.vat }}
									</div>
								</div>

								<div class="grand_total_div">
									<div>
										Grand Total:
									</div>
									<div style="font-weight: bold;display: flex; flex-direction: row; ">
										<div id="grand_total" class="grand_total">
											<div class='original_price' id="vat-add-total"></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="main_footer_div">
						<button v-on:click="getCheckout" id="checkout_place_order_btn" type="button"
							class="payment_place_order_button">Place Order</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import SpinnerData from './SpinnerData.vue';

export default {
	name: 'CheckoutStep2',
	components: {
		Datepicker,
		SpinnerData,
	},
	data() {
		return {
			CurrentDate: '',
			CurrentMonth: '',
			tomorrowDate: '',
			disabledbtn: false,
			pick_date: '',
			selectDate: '',
			selectTime: '',
			orderpaymentmethod: '',
			basketcartDetails: '',
			basketcartItems: '',
			addPromoCode: '',
			message: '',
			disabledDates: {
				to: new Date(Date.now() - 8640000)
			},
			couponCode: '',
			coupon_details: '',
			deliveryoffer: '',
			DeliveryTimeDetails: '',
			DeliveryTimeData: '',
			loading: false,
			greeting_add: '',

		}
	},
	metaInfo() {
		return {
			titleTemplate: 'Checkout | %s',
			htmlAttrs: {
				lang: 'en-US'
			},
			meta: [
				{ charset: 'utf-8' },
				{ name: 'description', content: 'Chackout' },
				{ name: 'keywords', content: 'Chackout' }
			]
		}
	},
	mounted() {
		window.scrollTo(0, 0);
		document.getElementById('select-delivery-date').style.display = 'block';
		this.getBasketDetails();
		this.getdeliveryoffer();
		this.currentDateTime();
		this.tomorrowDateTime();
		// this.setDeliveryTimeData();
		window.document.addEventListener('message', this.onMessageHandler, false);
	},
	methods: {
		onMessageHandler(e) {
			// console.log("Details status: ", e.detail)
			var onlinepayload = new FormData();
			onlinepayload.set('order_id', this.$session.get("orderid"));
			onlinepayload.set('SessionId', e.detail.sessionId);
			return axios({
				method: 'post',
				url: '/admin/PaymentControllerEmbeded',
				data: onlinepayload,
				config: {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			})
				.then(response => {
					// console.log("response: ", response);
					window.location.href = response.data;
				})
				.catch(error => {
					console.log("err: ", error)
				})
		},

		cleartimeslot() {
			var selected_delivery_time = document.getElementById('selected_delivery_time' + this.timeid);
			this.selectTime = ''
			if (this.timeid == "60min") {
				selected_delivery_time.classList.remove('date__confirmed');
			} else {
				var deliveryData = Object.values(this.DeliveryTimeData);
				for (var i = 0; i < deliveryData.length; i++) {
					if (this.timeid === deliveryData[i].id) {
						selected_delivery_time.classList.remove('date__confirmed');
					}
				}
			}
		},
		getBasketDetails() {
			this.loading = true;
			this.$session.start();
			var customer_id = this.$session.get("LoginID");
			var session_id = this.$cookie.get('device');
			var quote_id = '';
			if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
				quote_id = '';
			}
			else {
				quote_id = this.$cookie.get('QuoteId');
			}
			// if(this.$session.has("QuoteId") === false)
			// {
			// 	quote_id = '';
			// }
			// else
			// {
			// 	quote_id = this.$session.get("QuoteId");
			// }
			axios.post('/webapi/webshop/cart_listing', {
				quote_id: quote_id,
				customer_id: customer_id,
				session_id: session_id,
			})
				.then(response => {
					if (response.data.statusCode === 200 || response.data.statusCode === '200') {
						this.basketcartDetails = response.data.cartData.cartDetails;
						this.basketcartItems = response.data.cartData.cartItems;
						this.greeting_add = response.data.cartData.greeting_details;
						var grand_vat_total = parseFloat(this.basketcartDetails.base_grand_total);
						var date_today = document.getElementById('date_today');
						for (var i = 0; i < this.basketcartItems.length; i++) {
							if (this.basketcartItems[i].one_day_delivery == 0) {
								document.getElementById('date_today').onclick = null;
								document.getElementById('date_today').disabled = true;
								date_today.classList.add('disabled_today');
								date_today.classList.remove('date__confirmed');
								document.getElementById('hour_selector').style.display = 'none';
							} else {
								document.getElementById('hour_selector').style.display = 'block';
							}
						}


						if (this.basketcartDetails.coupon_code) {
							var customer_id = this.$session.get("LoginID");
							var session_id = this.$cookie.get('device');
							var quote_id = '';
							if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
								quote_id = '';
							}
							else {
								quote_id = this.$cookie.get('QuoteId');
							}

							// if(this.$session.has("QuoteId") === false)
							// {
							// 	quote_id = '';
							// }
							// else
							// {
							// 	quote_id = this.$session.get("QuoteId"); 
							// }
							var coupon_type = "0";
							var couponCodeData = this.basketcartDetails.coupon_code;
							axios.post('/webapi/webshop/apply_coupon_code', {
								coupon_code: couponCodeData,
								coupon_type: coupon_type,
								quote_id: quote_id,
								customer_id: customer_id,
								session_id: session_id,
							})
								.then(response => {
									if (response.data.statusCode === 200 || response.data.statusCode === '200') {
										this.message = response.data.message;
										this.coupon_details = response.data.coupon_details;
										document.getElementById('coupon_code').value = this.basketcartDetails.coupon_code;
										document.getElementById('coupon_code_discount').style.display = 'flex';
										document.getElementById('remove_promo').style.display = 'block';
									}
								})
						}

						if (this.greeting_add !== undefined) {
							document.getElementById('greeting_card_price').style.display = 'flex';
						} else {
							document.getElementById('greeting_card_price').style.display = 'none';
						}
						var grandTotalPrice = grand_vat_total.toFixed(2)
						document.getElementById('vat-add-total').innerText = 'AED' + ' ' + grandTotalPrice;
						var uA = navigator.userAgent || navigator.vendor || window.opera;
						var check_ios = /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator|Mac/.test(uA) && !window.MSStream;
						this.loading = false;
						if (check_ios == true) {
							// this.ApplePayButton(grandTotalPrice,'AED');
							document.getElementById("applepay-device").style.display = "block";
						} else {
							// this.ApplePayButton(grandTotalPrice,'AED');
							document.getElementById("applepay-device").style.display = "none";
						}
					} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
						this.loading = false;
					}

				})
		},

		// ApplePayButton(GrandAmt,CountryCode){
		// 	var payload = new FormData();
		// 	payload.set('InvoiceAmount', GrandAmt);
		// 	return axios({
		// 		method: 'post',
		// 		url: '/admin/ApplePayController',
		// 		data: payload,
		// 		config: {
		// 			headers: {
		// 				'Content-Type': 'multipart/form-data'
		// 			}
		// 		}
		// 	}).then(response => {
		// 	// alert(response.data.session_id)



		// 	})
		// },


		applyCouponCode(event) {
			if (event !== undefined)
				event.preventDefault();
			var coupon_code = document.getElementById('coupon_code').value;
			// console.log(coupon_code);
			if (coupon_code.length == 0 || coupon_code == null) {
				this.enableOrDisableErrorMessage('coupon_code_error', "You Don't have any Promo Code.", true);
				return;
			} else {
				document.getElementById('coupon_code_error').style.display = 'none';
			}
			this.$session.start();
			var customer_id = this.$session.get("LoginID");
			var session_id = this.$cookie.get('device');
			var quote_id = '';
			if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
				quote_id = '';
			}
			else {
				quote_id = this.$cookie.get('QuoteId');
			}
			// if(this.$session.has("QuoteId") === false)
			// {
			// 	quote_id = '';
			// }
			// else
			// {
			// 	quote_id = this.$session.get("QuoteId");
			// }
			var coupon_type = "0";
			axios.post('/webapi/webshop/apply_coupon_code', {
				coupon_code: coupon_code,
				coupon_type: coupon_type,
				quote_id: quote_id,
				customer_id: customer_id,
				session_id: session_id,
			})
				.then(response => {
					if (response.data.statusCode === 200 || response.data.statusCode === '200') {
						this.message = response.data.message;
						this.coupon_details = response.data.coupon_details.discount_amount.toFixed(2);
						document.getElementById('success-dialog').style.display = 'block';
						document.getElementById('coupon_code_discount').style.display = 'flex';
						document.getElementById('remove_promo').style.display = 'block';
						// cart change details
						var customer_id = this.$session.get("LoginID");
						var session_id = this.$cookie.get('device');
						var quote_id = '';
						if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
							quote_id = '';
						}
						else {
							quote_id = this.$cookie.get('QuoteId');
						}
						// if(this.$session.has("QuoteId") === false)
						// {
						// 	quote_id = '';
						// }
						// else
						// {
						// 	quote_id = this.$session.get("QuoteId");
						// }
						axios.post('/webapi/webshop/cart_listing', {
							quote_id: quote_id,
							customer_id: customer_id,
							session_id: session_id,
						})
							.then(response => {
								if (response.data.statusCode === 200 || response.data.statusCode === '200') {
									this.basketcartDetails = response.data.cartData.cartDetails;
									var grand_vat_total = parseFloat(this.basketcartDetails.base_grand_total);
									var grandTotalPrice = grand_vat_total.toFixed(2)
									document.getElementById('vat-add-total').innerText = 'AED' + ' ' + grandTotalPrice;
									// console.log("Cart Data",response.data.cartData);
								}
							})
					} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
						this.message = response.data.message;
						document.getElementById('error-dialog').style.display = 'block';
						document.getElementById('coupon_code_discount').style.display = 'none';
						document.getElementById('remove_promo').style.display = 'none';
					}
				})
		},
		removeCouponCode(event) {
			if (event !== undefined)
				event.preventDefault();
			var coupon_code = document.getElementById('coupon_code').value;
			// console.log(coupon_code);
			if (coupon_code.length == 0 || coupon_code == null) {
				this.enableOrDisableErrorMessage('coupon_code_error', "You Don't have any Promo Code.", true);
				return;
			}
			var quote_id = '';
			if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
				quote_id = '';
			}
			else {
				quote_id = this.$cookie.get('QuoteId');
			}
			// if(this.$session.has("QuoteId") === false)
			// {
			// 	quote_id = '';
			// }
			// else
			// {
			// 	quote_id = this.$session.get("QuoteId");
			// }
			var coupon_type = "0";
			axios.post('/webapi/webshop/remove_coupon_code', {
				coupon_code: coupon_code,
				coupon_type: coupon_type,
				quote_id: quote_id,
			})
				.then(response => {
					if (response.data.statusCode === 200 || response.data.statusCode === '200') {
						this.message = response.data.message;
						document.getElementById('success-dialog').style.display = 'block';
						document.getElementById('coupon_code_discount').style.display = 'none';
						document.getElementById('remove_promo').style.display = 'none';

						// cart change details
						var customer_id = this.$session.get("LoginID");
						var session_id = this.$cookie.get('device');
						var quote_id = '';
						if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
							quote_id = '';
						}
						else {
							quote_id = this.$cookie.get('QuoteId');
						}
						// if(this.$session.has("QuoteId") === false)
						// {
						// 	quote_id = '';
						// }
						// else
						// {
						// 	quote_id = this.$session.get("QuoteId");
						// }
						axios.post('/webapi/webshop/cart_listing', {
							quote_id: quote_id,
							customer_id: customer_id,
							session_id: session_id,
						})
							.then(response => {
								if (response.data.statusCode === 200 || response.data.statusCode === '200') {
									this.basketcartDetails = response.data.cartData.cartDetails;
									var grand_vat_total = parseFloat(this.basketcartDetails.base_grand_total);
									var grandTotalPrice = grand_vat_total.toFixed(2)
									document.getElementById('vat-add-total').innerText = 'AED' + ' ' + grandTotalPrice;
									// console.log("Cart Data",response.data.cartData);
								}
							})
					} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
						this.message = response.data.message;
						document.getElementById('error-dialog').style.display = 'block';
					}
				})
		},

		getCheckout(event) {
			// Changing title of the proceed button
			if (event !== undefined)
				event.preventDefault();

			var checkoutPlaceOrderBtn = document.getElementById('checkout_place_order_btn');
			checkoutPlaceOrderBtn.disabled = true;

			var payment_method_marked = false;

			// Validation of the data
			var User_Delivery_Location_Desired_Time_now = document.getElementById('User_Delivery_Location_Desired_Time_now');
			var User_Delivery_Location_Desired_Time_scheduled = document.getElementById('User_Delivery_Location_Desired_Time_scheduled');
			var Order_Delivery_Desired_Time_hour = document.getElementById('Order_Delivery_Desired_Time_hour');
			var timeNotAvailable = document.getElementById('time-not-available');
			const frontdatevaliDiv = document.querySelector(".frontend-date-validn");
			const fronttimevaliDiv = document.querySelector(".frontend-time-validn");
			// next get a refernce to child element p tag
			const para = document.querySelector(".date__confirmed");

			var payment_variants = document.querySelectorAll('input[name="variant"]');
			for (let index = 0; index < payment_variants.length; index++) {
				const element = payment_variants[index];
				if (element.checked == true) {
					payment_method_marked = true;
					break
				}
			}

			if (payment_method_marked == false) {
				document.getElementById('static-error-dialog').style.display = 'block';
				checkoutPlaceOrderBtn.disabled = false;
				// this.disabledbtn = false;
				return;
			}

			// if(greeting_permission_allowed.checked == true && this.greeting_textarea === ''){
			// 	document.getElementById('Greeting-error-dialog').style.display = 'block';
			// checkoutPlaceOrderBtn.disabled = false;
			// 	return;
			// }else{
			// 	this.getGreetingstatus();
			// }

			if (timeNotAvailable.style.display === 'block') {
				checkoutPlaceOrderBtn.disabled = false;
				// this.disabledbtn = false;
				this.message = "Delivery time not available for this date.";
				document.getElementById('error-dialog').style.display = 'block';
				return;
			}



			// check if child present in parent element
			const isChildPresent = frontdatevaliDiv.contains(para);
			if (isChildPresent == false) {
				checkoutPlaceOrderBtn.disabled = false;
				// this.disabledbtn = false;
				this.message = "Please select delivery date and time";
				document.getElementById('error-dialog').style.display = 'block';
				return;
			}

			const timeConfirmSelect = [];
			for (var i = 0; i < this.$refs.dynamicTime.length; i++) {
				for (var j = 0; j < this.$refs.dynamicTime[i].classList.length; j++) {
					if (this.$refs.dynamicTime[i].classList[j] === "date__confirmed") {
						timeConfirmSelect.push(this.$refs.dynamicTime[i].classList[j])
					}
				}
			}

			for (var k = 0; k < this.$refs.expressTime.classList.length; k++) {
				if (this.$refs.expressTime.classList[k] === "date__confirmed") {
					timeConfirmSelect.push(this.$refs.expressTime.classList[k])
				}
			}

			if (timeConfirmSelect.length == 0) {
				checkoutPlaceOrderBtn.disabled = false;
				// this.disabledbtn = false;
				this.message = "Please select delivery date and time";
				document.getElementById('error-dialog').style.display = 'block';
				return;
			}

			var orderTime = this.getDeliveryTime();
			var order_offer_id = null;
			if (orderTime === this.deliveryoffer.name) {

				order_offer_id = this.deliveryoffer.id;

			} else {
				order_offer_id = '';
			}
			var orderDate = this.change_the_date();
			var order_date;
			if (orderDate) {
				var a = new Date(orderDate);
				var date = a.getDate();
				var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				var monthName = months[a.getMonth()];
				order_date = date + ' ' + monthName;
			}
			var orderpaymentmethod = this.change_payment_type();
			var payment_method_name = null;
			var payment_type = 0;
			var currency_code = 'AED';
			if (orderpaymentmethod == 0) {
				payment_method_name = 'COD';
			} else if (orderpaymentmethod == 1) {
				payment_method_name = 'Online Payment';
			} else if (orderpaymentmethod == 2) {
				payment_method_name = 'ApplePay';
			}
			var quot_id = this.$cookie.get('QuoteId');
			var customer_id = this.$session.get('LoginID');
			var address_options = 'old';
			var billing_address_options = 'old';
			var shipping_email_id = this.$session.get('EmailID');
			var greetingContent = this.$session.get('greeting_content');
			var senderName = this.$session.get('sender_name');
			var recipientName = this.$session.get('recipient_name');

			this.onCheckout(payment_method_name);


			axios.post('/webapi/webshop/place_order', {
				payment_method: orderpaymentmethod,
				payment_method_id: orderpaymentmethod,
				payment_method_name: payment_method_name,
				payment_type: payment_type,
				currency_code: currency_code,
				customer_id: customer_id,
				address_options: address_options,
				shipping_email_id: shipping_email_id,
				billing_address_options: billing_address_options,
				orderDate: order_date,
				orderTime: orderTime,
				quote_id: quot_id,
				order_offer_id: order_offer_id,
				greeting_content: greetingContent,
				sender_name: senderName,
				recipient_name: recipientName,

			}).then(response => {
				if (response.data.statusCode === 200 || response.data.statusCode === '200') {
					checkoutPlaceOrderBtn.disabled = true;
					// this.disabledbtn = true;
					this.message = response.data.message;
					document.getElementById('success-dialog').style.display = 'block';
					// document.getElementById('payment_place_order_button').disabled = true;


					var order_id = response.data.order_id;
					var currency_code = 'AED';
					this.$session.set("orderid", order_id);
					var grandvattotal = parseFloat(this.basketcartDetails.base_grand_total);
					var grand_Total_Price = grandvattotal.toFixed(2)
					var payload = new FormData();
					payload.set('order_id', this.$session.get("orderid"));
					payload.set('orderpaymentmethod', orderpaymentmethod);
					payload.set('payment_method_name', payment_method_name);

					if (orderpaymentmethod == 1) {
						var onlinepayload = new FormData();
						onlinepayload.set('order_id', this.$session.get("orderid"));
						onlinepayload.set('orderpaymentmethod', orderpaymentmethod);
						onlinepayload.set('payment_method_name', payment_method_name);
						onlinepayload.set('InvoiceAmount', grand_Total_Price);

						document.getElementById('myFatoorah-payment').style.display = "block";
						var destinationdata = document.getElementById("destination").contentWindow;
						destinationdata.postMessage({ 'order_id': this.$session.get("orderid"), "InvoiceAmount": grand_Total_Price }, "*");

						// return axios({
						// 	method: 'post',
						// 	url: '/admin/OnlinePayment',
						// 	data: payload,
						// 	config: {
						// 		headers: {
						// 			'Content-Type': 'multipart/form-data'
						// 		}
						// 	}
						// }).then(response => {
						// 	const { StatusCode, PaymentLink, message } = response.data;
						// 	if (StatusCode === "200" || StatusCode === 200) {
						// 		this.message = "Redirect ......";
						// 		if (PaymentLink != undefined || PaymentLink != null) {
						// 			window.location.href = PaymentLink;
						// 		}
						// 		window.location.href = PaymentLink;
						// 	} else if (StatusCode === "500" || StatusCode === 500) {
						// 		// console.log('something went wrong.')
						// 	}
						// })
					} else if (orderpaymentmethod == 2) {

						var applepayload = new FormData();
						applepayload.set('order_id', this.$session.get("orderid"));
						applepayload.set('orderpaymentmethod', orderpaymentmethod);
						applepayload.set('payment_method_name', payment_method_name);
						applepayload.set('InvoiceAmount', grand_Total_Price);

						return axios({
							method: 'post',
							url: '/admin/ApplePayController',
							data: applepayload,
							config: {
								headers: {
									'Content-Type': 'multipart/form-data'
								}
							}
						}).then(response => {
							const { StatusCode, PaymentLink, message } = response.data;
							if (StatusCode == 200 || StatusCode == "200") {
								this.message = "Redirect ......";
								if (PaymentLink != undefined || PaymentLink != null) {
									window.location.href = response.data.PaymentLink;
								}
							} else if (StatusCode == 500 || StatusCode == "500") {
								this.message = message;
							}
						})
					} else {

						// destroy the QuoteID.
						this.$cookie.delete('QuoteId');

						axios.post('/webapi/webshop/send_order_confirmation_email', {
							order_id: order_id,
							currency_code: currency_code,
						}).then(response => {

							this.$session.set("orderid", order_id);
							// this.onCheckout();
							this.$router.push({ path: '/success-order/' });

						})
					}


				} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
					checkoutPlaceOrderBtn.disabled = false;
					// this.disabledbtn = false;
					this.message = response.data.message;
					document.getElementById('error-dialog').style.display = 'block';
				} else if (response.data.statusCode === 500 && response.data.message === 'Please select proper shipping address') {
					checkoutPlaceOrderBtn.disabled = false;
					// this.disabledbtn = false;
					this.message = response.data.message;
					document.getElementById('error-dialog').style.display = 'block';
					this.$router.push({ path: '/checkout/step1' });
				}
			})

		},
		onCheckout(payment_method) {
			var dataLayer = window.dataLayer || [];
			dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
			dataLayer.push({
				'event': 'checkout',
				'ecommerce': {
					'currencyCode': 'AED',
					'checkout': {
						'actionField': { 'step': 6, 'option': payment_method }, // step numbers depend on the flow (cart page can be number 1) 
						'products': this.basketcartItems
					}
				},
				// 'eventCallback': function() {}
				// 	document.location = '/checkout/step2';
				// }
			});
		},
		getdeliveryoffer() {
			// console.log("afdgaf");
			axios.get('/webapi/webshop/get_delivery_offer/')
				.then(response => {
					// console.log("delivery offer:",response.data);
					if (response.data.statusCode === 200 || response.data.statusCode === '200') {
						this.deliveryoffer = response.data.Fbcgetdelivery_offer;
						// console.log("delivery offer:",this.deliveryoffer);
						document.getElementById('offer_time').style.display = 'block';

					} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
						document.getElementById('offer_time').style.display = 'none';
						// console.log(response.data);
					}
				})
		},

		currentDateTime() {
			const today = new Date();
			var date = today.getDate();
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var monthName = months[today.getMonth()];
			var dateTime = document.getElementById('current_date').innerHTML = '<span>' + date + '</span>' + ' ' + monthName;
			return dateTime;
		},

		tomorrowDateTime() {
			const today = new Date();
			var nextDay = new Date(today);
			var nextdate = nextDay.setDate(today.getDate() + 1);
			var tomorrowDate = new Date(nextdate);
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var monthName = months[nextDay.getMonth()];
			var date = tomorrowDate.getDate();
			var dateTime = document.getElementById('tomorrow_date').innerHTML = '<span>' + date + '</span>' + ' ' + monthName;
			return dateTime;
		},
		Today_Date_Time() {
			const today = new Date();
			var date = today.getDate();
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var monthName = months[today.getMonth()];
			const todayTime = date + ' ' + monthName + ' ' + today.getUTCFullYear();
			return todayTime;
		},
		Tomorrow_Date_Time() {
			const today = new Date();
			var nextDay = new Date(today);
			var nextdate = nextDay.setDate(today.getDate() + 1);
			var tomorrowDate = new Date(nextdate);
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			var monthName = months[nextDay.getMonth()];
			// var date = tomorrowDate.getDate();
			// document.getElementById('tomorrow_date').innerHTML = '<span>'+date+'</span>'+' '+monthName;
			var tom_datetime = tomorrowDate.getDate() + ' ' + monthName + ' ' + tomorrowDate.getUTCFullYear();
			return tom_datetime;
		},

		change_payment_type() {
			//Changing title of the proceed button
			var radios = document.getElementsByName('variant');
			for (var i = 0, length = radios.length; i < length; i++) {
				if (radios[i].checked) {
					// do whatever you want with the checked radio
					// alert(radios[i].value);
					this.orderpaymentmethod = radios[i].value;
					// only one radio can be logically checked, don't check the rest
					break;
				}
			}

			// console.log(this.orderpaymentmethod);
			var pay_with_radio2 = document.getElementById('pay_with_radio2');
			var payment_place_order_button = document.querySelector('.payment_place_order_button');

			if (pay_with_radio2.checked) {
				payment_place_order_button.innerHTML = 'Proceed to payment';
			} else {
				payment_place_order_button.innerHTML = 'Place order';
			}

			return this.orderpaymentmethod;
		},

		add_greeting_card() {
			var value = document.getElementById('Order_Greeting_Card_inputcheckbox');
			var form_input = document.getElementById('Order_Greeting_Card_form');
			var Greeting_Card = document.getElementById('Greeting_Card');
			var greeting_card_input_div = document.getElementById('greeting_card_input_div');
			// var greeting_card_input_div_text = document.getElementById('greeting_card_input_div_text');
			var div_style_main_greeting_card = document.getElementById('div_style_main_greeting_card');
			if (value.checked) {
				Greeting_Card.removeAttribute('disabled');
				greeting_card_input_div.style.display = 'block';
			} else {
				Greeting_Card.setAttribute('disabled', 'disabled');
				greeting_card_input_div.style.display = 'none';
			}
		},


		change_the_date(day) {

			var hour_selector = document.getElementById('hour_selector');
			var date_today = document.getElementById('date_today');
			var date_tomorrow = document.getElementById('date_tomorrow');
			var date_pickup = document.getElementById('date_pickup');
			document.getElementById('select-delivery-date').style.display = 'none';

			if (day === 'today') {

				for (var i = 0; i < this.basketcartItems.length; i++) {

					if (this.basketcartItems[i].one_day_delivery == 0) {
						document.getElementById('date_today').onclick = null;
						document.getElementById('date_today').disabled = true;
						date_today.classList.add('disabled_today');
						date_today.classList.remove('date__confirmed');
						date_tomorrow.classList.remove('date__confirmed');
						date_pickup.classList.remove('date__confirmed');
						document.getElementById('error-sameday-delivery-dialog').style.display = 'block';
						document.getElementById('hour_selector').style.display = 'none';
					}
					else {
						document.getElementById('hour_selector').style.display = 'block';
						// this.setDeliveryTimeData('today');
						date_today.classList.add('date__confirmed');
						date_tomorrow.classList.remove('date__confirmed');
						date_pickup.classList.remove('date__confirmed');
						this.selectDate = this.Today_Date_Time();
						this.setDeliveryTimeData('today', this.selectDate);
					}
				}
			} else if (day === 'tomorrow') {
				// this.setDeliveryTimeData('tomorrow');
				date_today.classList.remove('date__confirmed');
				date_tomorrow.classList.add('date__confirmed');
				date_pickup.classList.remove('date__confirmed');
				document.getElementById('hour_selector').style.display = 'block';
				this.selectDate = this.Tomorrow_Date_Time();
				this.setDeliveryTimeData('tomorrow', this.selectDate);
			} else if (day === 'pickday') {
				// this.setDeliveryTimeData('pickday');
				date_today.classList.remove('date__confirmed');
				date_tomorrow.classList.remove('date__confirmed');
				date_pickup.classList.add('date__confirmed');
				document.getElementById('hour_selector').style.display = 'block';
				this.$nextTick(() => console.log(this.selectDate));
				var d = new Date(this.selectDate);
				var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
				var monthName = months[d.getMonth()];
				this.selectDate = d.getDate() + ' ' + monthName + ' ' + d.getUTCFullYear();
				this.setDeliveryTimeData('pickday', this.selectDate);
			}

			return this.selectDate;
		},
		setDeliveryTimeData(day = false, deliveyDate) {
			if (deliveyDate) {
				var selectdate = deliveyDate;
				var date = new Date();
				var fulldate = selectdate /*+' '+date.getFullYear()*/;
				var ifdat = new Date(fulldate);
				var ifmonth = '' + (ifdat.getMonth() + 1);
				var ifto_day = '' + ifdat.getDate();
				var ifyear = ifdat.getFullYear();
				if (ifmonth.length < 2) ifmonth = '0' + ifmonth;
				if (ifto_day.length < 2) ifto_day = '0' + ifto_day;
				var ifcorrectformate = [ifyear, ifmonth, ifto_day].join('-');

				axios.get("/webapi/webshop/delivery_timing" + "/" + ifcorrectformate)
					.then(response => {

						if (response.data.statusCode === 200 || response.data.statusCode === '200') {
							var timeslots;
							var iftslotdata = response.data.delivery_timing;
							var selDate = new Date(selectdate);
							if (day != false && day == 'today' || date.getDate() == selDate.getDate() && day == 'pickday') {
								if (iftslotdata.length === 0) {
									document.getElementById('Delivery-not-available-dialog').style.display = 'block';
									document.getElementById('time-not-available').style.display = 'block';
								} else {
									document.getElementById('time-not-available').style.display = 'none';
									timeslots = this.calculateTime(response.data.delivery_timing);
									if (timeslots.length === 0) {
										document.getElementById('time-not-available').style.display = 'block';
									} else {
										document.getElementById('time-not-available').style.display = 'none';
									}
								}
							} else {
								if (iftslotdata.length === 0) {
									document.getElementById('Delivery-not-available-dialog').style.display = 'block';
									document.getElementById('time-not-available').style.display = 'block';
								} else {
									timeslots = response.data.delivery_timing;
									document.getElementById('time-not-available').style.display = 'none';
								}
							}

							this.DeliveryTimeData = timeslots;

							//console.log("delivery_timing: ",this.DeliveryTimeData);
						} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
							console.log("delivery_timing not. ");
						}
					})
			} else {
				var dat = new Date();
				var month = '' + (dat.getMonth() + 1);
				var to_day = '' + dat.getDate();
				var year = dat.getFullYear();
				if (month.length < 2) month = '0' + month;
				if (to_day.length < 2) to_day = '0' + to_day;
				var correctformate = [year, month, to_day].join('-');

				axios.get("/webapi/webshop/delivery_timing" + "/" + correctformate)
					.then(response => {
						if (response.data.statusCode === 200 || response.data.statusCode === '200') {
							var timeslots;
							var tslotdata = response.data.delivery_timing;
							var selDate = new Date(selectdate);
							if (day != false && day == 'today' || dat.getDate() == selDate.getDate() && day == 'pickday') {
								if (tslotdata.length === 0) {
									document.getElementById('Delivery-not-available-dialog').style.display = 'block';
								} else {
									timeslots = this.calculateTime(response.data.delivery_timing);
								}
							} else {
								if (tslotdata.length === 0) {
									document.getElementById('Delivery-not-available-dialog').style.display = 'block';
								} else {
									timeslots = response.data.delivery_timing;
								}
							}
							this.DeliveryTimeData = timeslots;

							//console.log("delivery_timing: ",this.DeliveryTimeData);
						} else if (response.data.statusCode === 500 || response.data.statusCode === '500') {
							console.log("delivery_timing not. ");
						}
					})
			}
		},
		calculateTime(timeslot) {
			var curr_time = new Date().toLocaleString('en-US', { timeZone: 'Asia/Dubai' });
			var dateObj = new Date(curr_time);
			var month = dateObj.getUTCMonth() + 1; //months from 1-12
			var day = dateObj.getUTCDate();
			var year = dateObj.getUTCFullYear();
			var hours = dateObj.getHours();
			var minutes = dateObj.getMinutes();
			var seconds = dateObj.getSeconds();
			var time = dateObj.toLocaleTimeString();
			var newdate = day + "/" + month + "/" + year;
			var newtime = hours + ":" + minutes + ":" + seconds;
			var currentTime = new Date(curr_time);
			currentTime.setHours(hours, minutes, seconds);
			var timeStart = new Date("01/01/2007 " + newtime).getHours();
			var time_slot = new Array();

			var slotdata = Object.values(timeslot);
			for (var i = 0; i < slotdata.length; i++) {
				var StartTime = new Date("01/01/2007 " + slotdata[i].start_time);
				StartTime.setHours(StartTime.getHours() + 1);
				var starthours = StartTime.getHours();
				var startminutes = StartTime.getMinutes();
				var startseconds = StartTime.getSeconds();
				var startingtime = new Date(curr_time);
				startingtime.setHours(starthours, startminutes, startseconds);

				if (startingtime > currentTime) {

					time_slot.push(slotdata[i]);
				}
			}
			return time_slot;
		},
		getDeliveryTime(time_id) {
			this.timeid = time_id;
			var elements = document.getElementsByClassName('commonCardClass');

			for (let element of elements) {
				element.classList.remove('date__confirmed')
			}

			var selected_delivery_time = document.getElementById('selected_delivery_time' + time_id);
			var selected_delivery_time_value = document.getElementById('selected_delivery_time_value' + time_id);
			if (time_id == "60min") {
				selected_delivery_time.classList.add('date__confirmed');
				document.getElementById('shipping_offer_amount').innerHTML = 'AED' + ' ' + this.deliveryoffer.price;
				this.selectTime = selected_delivery_time_value.value;
				// cart listing 
				this.$session.start();
				var customer_id = this.$session.get("LoginID");
				var session_id = this.$cookie.get('device');
				var quote_id = '';
				if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
					quote_id = '';
				}
				else {
					quote_id = this.$cookie.get('QuoteId');
				}

				// if(this.$session.has("QuoteId") === false)
				// {
				// 	quote_id = '';
				// }
				// else
				// {
				// 	quote_id = this.$session.get("QuoteId");
				// }
				axios.post('/webapi/webshop/cart_listing', {
					quote_id: quote_id,
					customer_id: customer_id,
					session_id: session_id,
					shipping_amount: this.deliveryoffer.price,
				})
					.then(response => {

						this.basketcartDetails = response.data.cartData.cartDetails;
						this.basketcartItems = response.data.cartData.cartItems;
						var grand_vat_total = parseFloat(this.basketcartDetails.base_grand_total);
						var date_today = document.getElementById('date_today');
						for (var i = 0; i < this.basketcartItems.length; i++) {
							if (this.basketcartItems[i].one_day_delivery == 0) {
								document.getElementById('date_today').onclick = null;
								document.getElementById('date_today').disabled = true;
								date_today.classList.add('disabled_today');
								date_today.classList.remove('date__confirmed');
							}
						}
						document.getElementById('shipping_offer_amount').innerHTML = 'AED' + ' ' + this.basketcartDetails.shipping_amount;
						this.selectTime = selected_delivery_time_value.value;
						var grandTotalPrice = grand_vat_total.toFixed(2)
						document.getElementById('vat-add-total').innerText = 'AED' + ' ' + grandTotalPrice;

					})

			} else {
				var deliveryData = Object.values(this.DeliveryTimeData);
				for (var i = 0; i < deliveryData.length; i++) {
					if (time_id === deliveryData[i].id) {
						selected_delivery_time.classList.add('date__confirmed');
						document.getElementById('shipping_offer_amount').innerHTML = 'AED' + ' ' + this.basketcartDetails.shipping_amount;
						this.selectTime = selected_delivery_time_value.value;
						// cart listing 
						this.$session.start();
						customer_id = this.$session.get("LoginID");
						session_id = this.$cookie.get('device');
						quote_id = '';
						if (this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined) {
							quote_id = '';
						}
						else {
							quote_id = this.$cookie.get('QuoteId');
						}

						// if(this.$session.has("QuoteId") === false)
						// {
						// 	quote_id = '';
						// }
						// else
						// {
						// 	quote_id = this.$session.get("QuoteId");
						// }
						axios.post('/webapi/webshop/cart_listing', {
							quote_id: quote_id,
							customer_id: customer_id,
							session_id: session_id,
							shipping_amount: 29,
						})
							.then(response => {

								this.basketcartDetails = response.data.cartData.cartDetails;
								this.basketcartItems = response.data.cartData.cartItems;
								var grand_vat_total = parseFloat(this.basketcartDetails.base_grand_total);
								var date_today = document.getElementById('date_today');
								for (var i = 0; i < this.basketcartItems.length; i++) {
									if (this.basketcartItems[i].one_day_delivery == 0) {
										document.getElementById('date_today').onclick = null;
										document.getElementById('date_today').disabled = true;
										date_today.classList.add('disabled_today');
										date_today.classList.remove('date__confirmed');

									}
								}
								document.getElementById('shipping_offer_amount').innerHTML = 'AED' + ' ' + this.basketcartDetails.shipping_amount;
								this.selectTime = selected_delivery_time_value.value;
								var grandTotalPrice = grand_vat_total.toFixed(2)
								document.getElementById('vat-add-total').innerText = 'AED' + ' ' + grandTotalPrice;

							})
					}
				}
			}
			// console.log("this.selectTime",this.selectTime)
			return this.selectTime;
		},

		enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
			var mainElement = document.getElementById(idOfTheMainElement);
			if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
				var mainElement_first_children = mainElement.firstElementChild;
				if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
					mainElement_first_children.innerHTML = messageText;
					if (!disableFlag) {
						mainElement.style.display = "none";
					} else {
						mainElement.style.display = "block";
					}
				}
			}
		},
		checkIfObjectIsNotUndefiendOrNull(obj) {
			if (obj === undefined || obj === null) {
				return true;
			} else {
				return false;
			}
		}

	}
}
</script>
<style scoped>
/* .disabled_today{
    background-color: lightgray !important;
}
.date__confirmed{
	border: 2px solid #75cc60 !important;
}
.container_box {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #2f1317;
	background: #2f1317
}
.enter_the_promo_code_apply_button {
    height: 45px !important;
    color: black;
    background: white;
    font-size: 16px !important;
    font-family: 'Conv_ProximaNova-Regular',sans-serif !important;
    border: 1px solid #75cc60;
}
.container_box input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0
}

.checkmark {
	position: absolute;
	top: 0;
	left: -1px;
	height: 26px;
	width: 26px;
	background-color: white;
	border: 1px solid
}

.container_box:hover input~.checkmark {
	background-color: white;
	border: 1px solid
}

.container_box input:checked~.checkmark {
	background-color: white
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none
}

.container_box input:checked~.checkmark:after {
	display: block
}

.container_box .checkmark:after {
	left: 9px;
	top: 4px;
	width: 5px;
	height: 13px;
	border: solid #66bb6a;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg)
}

.pika-single {
	z-index: 9999;
	display: block;
	position: relative;
	color: #333;
	background: #fff;
	border: 1px solid #ccc;
	border-bottom-color: #bbb;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	top: 50% !important;
	left: 50% !important;
	-ms-transform: translate(-50%, -50%) !important;
	transform: translate(-50%, -50%) !important
}

.pika-single:before,
.pika-single:after {
	content: " ";
	display: table
}

.pika-single:after {
	clear: both
}

.pika-single.is-hidden {
	display: none
}

.pika-single.is-bound {
	position: absolute;
	box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5)
}

.pika-lendar {
	float: left;
	width: 240px;
	margin: 8px
}

.pika-title {
	position: relative;
	text-align: center
}

.pika-label {
	display: inline-block;
	position: relative;
	z-index: 9999;
	overflow: hidden;
	margin: 0;
	padding: 5px 3px;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	background-color: #fff
}

.pika-title select {
	cursor: pointer;
	position: absolute;
	z-index: 9998;
	margin: 0;
	left: 0;
	top: 5px;
	opacity: 0
}

.pika-prev,
.pika-next {
	display: block;
	cursor: pointer;
	position: relative;
	outline: 0;
	border: 0;
	padding: 0;
	width: 20px;
	height: 30px;
	text-indent: 20px;
	white-space: nowrap;
	overflow: hidden;
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 75% 75%;
	opacity: .5
}

.pika-prev:hover,
.pika-next:hover {
	opacity: 1
}

.pika-prev,
.is-rtl .pika-next {
	float: left;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==")
}

.pika-next,
.is-rtl .pika-prev {
	float: right;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=")
}

.pika-prev.is-disabled,
.pika-next.is-disabled {
	cursor: default;
	opacity: .2
}

.pika-select {
	display: inline-block
}

.pika-table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	border: 0
}

.pika-table th,
.pika-table td {
	width: 14.285714285714286%;
	padding: 0
}

.pika-table th {
	color: #999;
	font-size: 12px;
	line-height: 25px;
	font-weight: bold;
	text-align: center
}

.pika-button {
	cursor: pointer;
	display: block;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: 0;
	border: 0;
	margin: 0;
	width: 100%;
	padding: 5px;
	color: #666;
	font-size: 12px;
	line-height: 15px;
	text-align: right;
	background: #f5f5f5
}

.pika-week {
	font-size: 11px;
	color: #999
}

.is-today .pika-button {
	color: #3af;
	font-weight: bold
}

.is-selected .pika-button,
.has-event .pika-button {
	color: #fff;
	font-weight: bold;
	background: #3af;
	box-shadow: inset 0 1px 3px #178fe5;
	border-radius: 3px
}

.has-event .pika-button {
	background: #005da9;
	box-shadow: inset 0 1px 3px #0076c9
}

.is-disabled .pika-button,
.is-inrange .pika-button {
	background: #d5e9f7
}

.is-startrange .pika-button {
	color: #fff;
	background: #6cb31d;
	box-shadow: none;
	border-radius: 3px
}

.is-endrange .pika-button {
	color: #fff;
	background: #3af;
	box-shadow: none;
	border-radius: 3px
}

.is-disabled .pika-button {
	pointer-events: none;
	cursor: default;
	color: #999;
	opacity: .3
}

.is-outside-current-month .pika-button {
	color: #999;
	opacity: .3
}

.is-selection-disabled {
	pointer-events: none;
	cursor: default
}

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
	color: #fff;
	background: #ff8000;
	box-shadow: none;
	border-radius: 3px
}

.pika-table abbr {
	border-bottom: 0;
	cursor: help
}

textarea:disabled {
	background: #7c7c7c !important
}

textarea {
	background: #fff !important
}

.payment_place_order_button {
	width: 100%;
	height: 100%;
	background: #17c942a6;
	color: white;
	font-family: 'proxima-nova', sans-serif !important;
	border: 0
}

@media(max-width:599px) {
	.payment_place_order_button {
		font-size: 5vw
	}
}

@media(min-width:600px) {
	.payment_place_order_button {
		font-size: 3vw
	}
}

@media(min-width:900px) {
	.payment_place_order_button {
		font-size: 2vw
	}
}

@media(min-width:1200px) {
	.payment_place_order_button {
		font-size: 1.5vw
	}
}

@media(min-width:1800px) {
	.payment_place_order_button {
		font-size: 2vw
	}
}

.checkout_payment_component {
	min-height: 0;
	margin: .5em;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	font-size: 15px;
	font-weight: 400;
	height: min-content;
	overflow: visible;
	position: relative;
	background: #fff;
	border-radius: 2px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	width: min-content
}

.payment_place_order_button:disabled{
	opacity: .6;
    pointer-events: none;
}

.checkout_payment_component_class_active {
	border: 2px solid green;
	border-radius: .4em
}

@media(min-width:1200px) {
	.checkout_payment_component_main_div {
		margin: 2em
	}
}

.checkout_payment_component_main_div_first_div {
	width: min-content;
	padding-top: 1em;
	font-size: 1.2em;
	color: #626fb2
}

.checkout_payment_component_main_div_third_div {
	display: flex;
	flex-direction: row;
	justify-content: center
}

.checkout_payment_component_main_div_fourth_div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center
}

.checkout_payment_component_main_div_fifth_div {
	padding-top: 1.5em;
	font-size: 1.1em;
	color: black;
	padding-bottom: 1em
}

.checkout_payment_component_main_div_seventh_div {
	margin-left: 2em;
	margin-right: 2em;
	margin-top: 1em;
	margin-bottom: 1em
}

.checkout_payment_component_main_div_first_first_inner_div {
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background: white;
	box-sizing: border-box;
	-webkit-border-radius: 10px 10px 10px 10px
}

.checkout_payment_component_main_div_first_second_inner_div {
	display: flex;
	flex-direction: column;
	overflow: visible;
	min-height: 0;
	padding: 1em;
	justify-content: center;
	-webkit-border-radius: 10px 10px 10px 10px
}

.checkout_payment_component_main_div_first_third_inner_div {
	font-size: 1.6em;
	color: black;
	word-spacing: .2em;
	line-height: 1.3em;
	text-align: center
}

@media(max-width:599px) {
	.checkout_payment_component {
		width: 100%
	}
}

@media(min-width:600px) {
	.checkout_payment_component {
		width: 100%
	}
}

@media(min-width:900px) {
	.checkout_payment_component {
		width: 432px
	}
}

@media(min-width:1200px) {
	.checkout_payment_component {
		width: 432px
	}
}

@media(min-width:1800px) {
	.checkout_payment_component {
		width: 432px
	}
}

.creditCard-component {
	margin: 0;
	display: none;
	min-height: 0;
	margin: .5em;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	font-size: 15px;
	font-weight: 400;
	overflow: visible;
	background: #fff;
	box-sizing: border-box;
	flex-direction: column;
	border-radius: .6em;
	position: relative;
	width: 100%;
	height: 100%;
	background: black;
	opacity: .4
}

.creditCard-component-first-inner {
	position: relative;
	height: min-content
}

.creditCard-component-second-inner {
	position: relative;
	min-height: 0;
	font-size: 15px;
	font-weight: 400;
	overflow: visible;
	background: transparent;
	box-sizing: border-box;
	flex-direction: column;
	width: 0;
	height: 24em;
	margin-top: 1.5em;
	display: block;
	position: relative;
	height: 54em;
	width: 26em
}

.telr {
	width: 100%;
	height: 100%;
	border: 0
}

.change_desired_delivery_time_input_perspective_class {
	text-align: center
}

@media(max-width:599px) {
	.change_desired_delivery_time_input_perspective_class {
		width: 29vw
	}
}

@media(min-width:600px) {
	.change_desired_delivery_time_input_perspective_class {
		font-size: 21px;
		width: 7vw
	}
}

@media(min-width:900px) {
	.change_desired_delivery_time_input_perspective_class {
		font-size: 21px;
		width: 7vw
	}
}

@media(min-width:1200px) {
	.change_desired_delivery_time_input_perspective_class {
		font-size: 21px;
		width: 7vw
	}
}

@media(min-width:1800px) {
	.change_desired_delivery_time_input_perspective_class {
		font-size: 21px;
		width: 7vw
	}
}

.main_container {
	height: 56em;
	margin: auto
}

@media(max-width:599px) {
	.main_container {
		width: 100% !important
	}
}

@media(min-width:600px) {
	.main_container {
		width: 600px;
		margin-top: 5.5em
	}
}

@media(min-width:900px) {
	.main_container {
		width: 600px;
		margin-top: 5.5em
	}
}

@media(min-width:1200px) {
	.main_container {
		width: 600px;
		margin-top: 5.5em
	}
}

@media(min-width:1800px) {
	.main_container {
		width: 600px;
		margin-top: 5.5em
	}
}

.desired_time_form_css {
	display: grid;
	grid-template-rows: 1fr
}

@media(max-width:599px) {
	.desired_time_form_css {
		margin: 2vw 0 4vw 0;
		grid-template-columns: .5fr 1.1fr 1.5fr .9fr
	}
}

@media(min-width:600px) {
	.desired_time_form_css {
		margin: 0;
		grid-template-columns: .44fr 1.1fr 1.5fr .9fr
	}
}

@media(min-width:900px) {
	.desired_time_form_css {
		margin: 0;
		grid-template-columns: .44fr 1.1fr 1.5fr .9fr
	}
}

@media(min-width:1200px) {
	.desired_time_form_css {
		margin: 0;
		grid-template-columns: .44fr 1.1fr 1.5fr .9fr
	}
}

@media(min-width:1800px) {
	.desired_time_form_css {
		margin: 0;
		grid-template-columns: .44fr 1.1fr 1.5fr .9fr
	}
}

.extra_happybox-info-class {
	min-height: 45px;
	display: block
}

@media(min-width:600px) {
	.extra_happybox-info-class {
		margin-top: 1em !important
	}
}

@media(min-width:900px) {
	.extra_happybox-info-class {
		margin-top: 1em !important
	}
}

@media(min-width:1200px) {
	.extra_happybox-info-class {
		margin-top: 1em !important
	}
}

@media(min-width:1800px) {
	.extra_happybox-info-class {
		margin-top: 1em !important
	}
}

.greeting_card_div_class {
	display: flex;
	flex-direction: column;
	justify-content: left
}

@media(max-width:599px) {
	.greeting_card_div_class {
		margin: 2vw 0 0vw 0
	}
}

@media(min-width:600px) {
	.greeting_card_div_class {
		margin: 0
	}
}

@media(min-width:900px) {
	.greeting_card_div_class {
		margin: 0
	}
}

@media(min-width:1200px) {
	.greeting_card_div_class {
		margin: 0
	}
}

@media(min-width:1800px) {
	.greeting_card_div_class {
		margin: 0
	}
}

.desired_time_input_perspective_css {
	height: min-content;
	display: flex;
	flex-direction: row;
	justify-content: left
}
.calendar_date_day {
	padding: 15px;
	overflow: hidden;
	position: relative;
	border: 0;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2) !important;
	border-radius: .25rem;
	cursor: pointer;
	color: #797979;
	font-size: 14px;
	font-weight: bold;
	border: 1px solid transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	font-size: 14px;
	font-weight: normal;
	width: 100%;
	height: 59px !important;
	margin: 9px;
	margin-top: 0
}

.valentines_day_first {
	margin-bottom: 10px
}

.valentines_day_second {
	margin-bottom: 10px
}

.time_hour_class_span_valentines_day {
	padding: 19px;
	padding-top: 5px;
	padding-bottom: 9px;
	overflow: hidden;
	position: relative;
	border: 0;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2) !important;
	border-radius: .25rem;
	cursor: pointer;
	color: #797979;
	font-size: 14px;
	width: 82%;
	border: 1.5px solid transparent;
	margin-bottom: 30px;
	margin-top: 4px;
	margin-left: 5px;
	margin-right: 7px
}

.time_hour_class_span {
	min-width: 83px;
	background-color: white;
	border-radius: 50%;
	text-justify: auto;
	padding-top: 17px;
	text-align: center;
	border: red;
	border-style: solid;
	margin-right: 14px;
	min-height: 59px
}

.terms_and_conditions_continue {
	text-align: center
}

@media(max-width:599px) {
	.terms_and_conditions_continue {
		font-size: 16px;
		margin-top: 1em
	}
}

@media(min-width:600px) {
	.terms_and_conditions_continue {
		font-size: 19px
	}
}

@media(min-width:900px) {
	.terms_and_conditions_continue {
		font-size: 19px
	}
}

@media(min-width:1200px) {
	.terms_and_conditions_continue {
		font-size: 19px
	}
}

@media(min-width:1800px) {
	.terms_and_conditions_continue {
		font-size: 19px
	}
}

@media(min-width:600px) {
	.enter_the_promo_code {
		font-size: 21px
	}
}

@media(min-width:900px) {
	.enter_the_promo_code {
		font-size: 21px
	}
}

@media(min-width:1200px) {
	.enter_the_promo_code {
		font-size: 21px
	}
}

@media(min-width:1800px) {
	.enter_the_promo_code {
		font-size: 21px
	}
}

@media(min-width:600px) {
	.Order_Delivery_Desired_Time_hour_cover {
		font-size: 21px;
		width: 6vw
	}
}

@media(min-width:900px) {
	.Order_Delivery_Desired_Time_hour_cover {
		font-size: 21px;
		width: 6vw
	}
}

@media(min-width:1200px) {
	.Order_Delivery_Desired_Time_hour_cover {
		font-size: 21px;
		width: 6vw
	}
}

@media(min-width:1800px) {
	.Order_Delivery_Desired_Time_hour_cover {
		font-size: 21px;
		width: 6vw
	}
}

.grand_total_div {
	font-family: 'Arial';
	text-align: center;
	font-weight: bold !important;
	color: #664242;
	display: flex;
	flex-direction: revert;
	justify-content: space-between;
	padding-top: 0
}

@media(max-width:599px) {
	.grand_total_div {
		font-size: 17px
	}
}

@media(min-width:600px) {
	.grand_total_div {
		font-size: 21px
	}
}

@media(min-width:900px) {
	.grand_total_div {
		font-size: 21px
	}
}

@media(min-width:1200px) {
	.grand_total_div {
		font-size: 21px
	}
}

@media(min-width:1800px) {
	.grand_total_div {
		font-size: 21px
	}
}

.checkout_delivery_fee_sub_total_div {
	font-family: 'Arial';
	text-align: center;
	color: #664242;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 0
}

@media(max-width:599px) {
	.checkout_delivery_fee_sub_total_div {
		font-size: 16px
	}
}

@media(min-width:600px) {
	.checkout_delivery_fee_sub_total_div {
		font-size: 21px
	}
}

@media(min-width:900px) {
	.checkout_delivery_fee_sub_total_div {
		font-size: 21px
	}
}

@media(min-width:1200px) {
	.checkout_delivery_fee_sub_total_div {
		font-size: 21px
	}
}

@media(min-width:1800px) {
	.checkout_delivery_fee_sub_total_div {
		font-size: 21px
	}
}

.greeting_card_input_div_text {
	font-family: Arial;
	width: max-content;
	height: max-content
}

@media(max-width:599px) {
	.greeting_card_input_div_text {
		font-size: 17px;
		padding-top: 3vh
	}
}

@media(min-width:600px) {
	.greeting_card_input_div_text {
		font-size: 21px;
		padding-top: 20px
	}
}

@media(min-width:900px) {
	.greeting_card_input_div_text {
		font-size: 21px;
		padding-top: 20px
	}
}

@media(min-width:1200px) {
	.greeting_card_input_div_text {
		font-size: 21px;
		padding-top: 20px
	}
}

@media(min-width:1800px) {
	.greeting_card_input_div_text {
		font-size: 21px;
		padding-top: 20px
	}
}

.online_payment_options {
	display: flex;
	flex-direction: row
}

@media(min-width:600px) {
	.online_payment_options {
		padding-top: 9px
	}
}

@media(min-width:900px) {
	.online_payment_options {
		padding-top: 9px
	}
}

@media(min-width:1200px) {
	.online_payment_options {
		padding-top: 9px
	}
}

@media(min-width:1800px) {
	.online_payment_options {
		padding-top: 9px
	}
}

.desired_delivery_time_now_text {
	margin-left: 0;
	font-family: Arial;
	width: max-content;
	height: max-content
}

@media(max-width:599px) {
	.desired_delivery_time_now_text {
		padding-top: .3em;
		font-size: 17px
	}
}

@media(min-width:600px) {
	.desired_delivery_time_now_text {
		font-size: 21px;
		padding-top: 4px
	}
}

@media(min-width:900px) {
	.desired_delivery_time_now_text {
		font-size: 21px;
		padding-top: 4px
	}
}

@media(min-width:1200px) {
	.desired_delivery_time_now_text {
		font-size: 21px;
		padding-top: 4px
	}
}

@media(min-width:1800px) {
	.desired_delivery_time_now_text {
		font-size: 21px;
		padding-top: 4px
	}
}

.schedule_div_checkout_component {
	font-family: Arial;
	width: max-content;
	height: max-content
}

@media(max-width:599px) {
	.schedule_div_checkout_component {
		font-size: 17px;
		padding-top: .5em
	}
}

@media(min-width:600px) {
	.schedule_div_checkout_component {
		font-size: 21px;
		padding-top: 2px
	}
}

@media(min-width:900px) {
	.schedule_div_checkout_component {
		font-size: 21px;
		padding-top: 2px
	}
}

@media(min-width:1200px) {
	.schedule_div_checkout_component {
		font-size: 21px;
		padding-top: 2px
	}
}

@media(min-width:1800px) {
	.schedule_div_checkout_component {
		font-size: 21px;
		padding-top: 2px
	}
}

@media(max-width:599px) {
	.nav_icon_checkout_div_mastercard {
		width: 17px;
		height: 20px
	}
}

@media(min-width:600px) {
	.nav_icon_checkout_div_mastercard {
		padding-top: 2px
	}
}

@media(min-width:900px) {
	.nav_icon_checkout_div_mastercard {
		padding-top: 2px
	}
}

@media(min-width:1200px) {
	.nav_icon_checkout_div_mastercard {
		padding-top: 2px
	}
}

@media(min-width:1800px) {
	.nav_icon_checkout_div_mastercard {
		padding-top: 2px
	}
}

@media(max-width:599px) {
	.nav_icon_checkout_div {
		width: 17px;
		height: 20px
	}
}

@media(min-width:600px) {
	.nav_icon_checkout_div {
		width: 68px;
		height: min-content
	}
}

@media(min-width:900px) {
	.nav_icon_checkout_div {
		width: 68px;
		height: min-content
	}
}

@media(min-width:1200px) {
	.nav_icon_checkout_div {
		width: 68px;
		height: min-content
	}
}

@media(min-width:1800px) {
	.nav_icon_checkout_div {
		width: 68px;
		height: min-content
	}
}

@media(max-width:599px) {
	.payment_method_separator_checkout_div {
		width: 17px;
		height: 20px
	}
}

@media(min-width:600px) {
	.payment_method_separator_checkout_div {
		padding-top: 1px;
		font-size: 30px
	}
}

@media(min-width:900px) {
	.payment_method_separator_checkout_div {
		padding-top: 1px;
		font-size: 30px
	}
}

@media(min-width:1200px) {
	.payment_method_separator_checkout_div {
		padding-top: 12px;
		font-size: 30px
	}
}

@media(min-width:1800px) {
	.payment_method_separator_checkout_div {
		padding-top: 12px;
		font-size: 30px
	}
}

.fa_arrow_checkout {
	width: 1em
}

.grand_total {
	display: flex;
	flex-direction: row;
	justify-content: right;
	width: 9.45em
}

.User_Delivery_Location_Desired_Time_now {
	opacity: .3
}

.td_delivery_time_not_available {
	background: red
}

@media(max-width:599px) {
	.Order_Payment_type_form_cash_on_delivery {
		font-family: Arial;
		font-size: 17px;
		padding-top: .55em;
		height: max-content;
		width: 4em;
		text-align: center
	}
}

@media(min-width:600px) {
	.Order_Payment_type_form_cash_on_delivery {
		font-family: Arial;
		font-size: 21px;
		padding-top: 1em;
		width: max-content;
		height: max-content
	}
}

@media(min-width:900px) {
	.Order_Payment_type_form_cash_on_delivery {
		font-family: Arial;
		font-size: 21px;
		padding-top: 1em;
		width: max-content;
		height: max-content
	}
}

@media(min-width:1200px) {
	.Order_Payment_type_form_cash_on_delivery {
		font-family: Arial;
		font-size: 21px;
		padding-top: 1em;
		width: max-content;
		height: max-content
	}
}

@media(min-width:1800px) {
	.Order_Payment_type_form_cash_on_delivery {
		font-family: Arial;
		font-size: 21px;
		padding-top: 1em;
		width: max-content;
		height: max-content
	}
}

.Order_Payment_type_form_online_payment {
	font-family: Arial;
	width: 11em;
	height: max-content;
	padding-top: 1em
}

@media(max-width:599px) {
	.Order_Payment_type_form_online_payment {
		font-family: Arial;
		font-size: 17px;
		width: 5em;
		height: max-content;
		padding-top: .3em;
		text-align: center
	}
}

@media(min-width:600px) {
	.Order_Payment_type_form_online_payment {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: 0;
		font-size: 21px
	}
}

@media(min-width:900px) {
	.Order_Payment_type_form_online_payment {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: 0;
		font-size: 21px
	}
}

@media(min-width:1200px) {
	.Order_Payment_type_form_online_payment {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: 0;
		font-size: 21px
	}
}

@media(min-width:1800px) {
	.Order_Payment_type_form_online_payment {
		display: flex;
		flex-direction: row;
		justify-content: center;
		padding-top: 0;
		font-size: 21px
	}
}

input[type='date'],
input[type='time'] {
	-webkit-appearance: none
} */
</style>