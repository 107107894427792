<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->
    <div class="category-listing">
        <Category />
        <div class="section-text main_section_text_category_name">{{ this.$cookie.get('search') }}</div>
        <ol class="breadcrumbs">
            <li>
                <router-link to="/">HappyBox > </router-link> 
            </li>
            <li class="cureent-page">
                <span class="a-list-item a-color-tertiary" >{{ this.$cookie.get('search') }}</span>
            </li> 
        </ol>
        <div class="main-list-of-items">
            <!-- Error Message -->
            <div id="cart-details-error" style="display:none;text-align:center;">
                <h1 style="color:red;font-size: x-large;">{{error}}</h1>
            </div>
            
            <div id="products_main_div" class="main-list-of-items-first-inner" style="justify-content: start;">

                <!-- 1 product -->
                <div v-for="product in ProductList" :key="product.id" class="main-list-of-items-zero-inner">
                    <div class="main-list-of-items---item-first-inner"></div>
                    <div class="main-list-of-items---item-first-inner">
                        <div>
                           <router-link :to="'/product/' + product.url_key"> <img class="external_class_element lazyload highlighted_product_image" 
                            :alt="product.name" :src="'/uploads/products/thumb/' + product.base_image"> </router-link>
                        </div>
                    </div>
                    <div class="main-list-of-items---item-first-inner">
                        <div>
                            <div class="wrapped_text product_name product_product_name">
                              <!--  <img class="external_class_element lazyload highlighted_product_image"> -->
                                 {{product.name}}
                            </div>
                        </div>
                        <div>
                            <div class="original-price">
                                <div class="original-price">
                                    <div class='original_price product_price' v-if="product.product_type === 'simple'">AED {{product.price}}</div>
                                    <div class='original_price product_price' v-else-if="product.product_type === 'configurable'">AED {{product.min_price}} - AED {{product.max_price}}</div>
                                </div>
                                    
                                <div class="original-price">
                                  <!--  <div class='original_price product_price'>AED {{product.min_price}}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import Category from './Category';
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'SearchResult',
    components: { 
        Category,
        SpinnerData,
    },
    data(){
        return{
            ProductList:'',
            searchTerm:'',
            error:'',
            loading: false,
        }
    },
    metaInfo(){
        return {
            titleTemplate:this.searchTerm+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.searchTerm },
                { name: 'keywords', content: this.searchTerm }
            ]
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.loading = true;
        var search_term = this.$route.query.s;
        axios.post('/webapi/webshop/product_listing',{
            search_term: search_term,
        })
        .then(response => {
            if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                this.ProductList = response.data.ProductList;
                this.searchTerm = search_term;
                this.loading = false;
            }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                this.searchTerm = 'Search Products';
                this.error = response.data.message;
                document.getElementById('cart-details-error').style.display = 'block';
                this.loading = false;
            }
        })

    },
    
}
</script>

<style>

</style>