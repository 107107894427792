<template>
<div>
    <header>
    <!-- main header -->
        <div class="navigation_main_bar container mdl-shadow--2dp">
            <div class="navigation_bar">            
                <router-link to="/" style="position: relative;width: 100%;height: 100%;" class="nav nav-brand">
                    <img alt="HB" src="/assets/img/HP_BW_Logo.svg">
                </router-link>


                <div class="top-nav-icon-sec">
                    <ul>
                        <li><router-link to="/basket/"><img alt="Basket" id="basket_nav_icon" style="display: block;" class="nav nav-item" src="/assets/img/fa_icons/fa-shopping-basket.svg"></router-link></li>
                        <li><img alt='Phone' onclick="window.open('tel:+97145800908')" class="nav nav-item" src="/assets/img/fa_icons/fa-phone.svg">
                        <!-- <router-link to="/login"><img alt="Profile" style="color: grey;" class="nav nav-item" src="/assets/img/fa_icons/fa-user.svg"></router-link> --></li>
                        <li> <img alt="Profile" v-on:click="checkUserLoggin" class="nav nav-item" src="/assets/img/fa_icons/fa-user.svg"></li>
                    </ul>
                </div>

                
                <div class="sixty_onehundrtwentmin_class cash-visa-sec">    
                    <div style="display: flex;flex-direction: row;justify-content: left;">
						<div class="cash_div_main">
                            <span class="cash_div_text">Cash</span>
                            <span class="cash-seperator">/</span>
                            <span class="visa-img"><img alt="VISA" class="nav_icon" src="/assets/img/visa.svg"></span>
                        </div> 
                    </div>
              
                </div>

                <div class="head-search-sec">
                    
                    <input type="text" placeholder="Fruits, Cakes, Flowers, Sweets, ..." name="search" v-on:keyup.enter="getSearchData" v-model="search">
                    <button v-on:click="getSearchData" type="submit"><i class="fa fa-search"></i></button>
                
                </div>

             </div>
        </div>

    </header>
</div> 
</template>

<script>
import axios from 'axios'
export default {
    name: 'AppHeader',
    data(){
        return{
            headerdata:'',
            search:'',
        }
    },
    mounted(){
        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    // Does this cookie string begin with the name we want?
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }

        function uuidv4(){
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        let device = getCookie('device');
        if(device == null || device == undefined)
        {
            device = uuidv4()
        }
        document.cookie = 'device=' + device + ";domain=;path=/"

        this.getHeaderinfo();
        this.getBasketDetails();
    },
    methods:{
        checkUserLoggin(){
            this.$session.start();
            var LoginID = this.$session.get('LoginID');
            var PhoneNumber = this.$session.get('PhoneNumber');
            if(LoginID == undefined && PhoneNumber == undefined)
            { 
                this.$router.push({ path: '/login/step2' });
            }else{ 
                this.$router.push({ path: '/my-account' });   
            }
        },
        getHeaderinfo(){
            axios.get('/webapi/webshop/get_webshop_details/')
            .then(response => {
                this.headerdata = response.data.FbcWebShopDetails;
            })
        },
        
        getSearchData(){
            var search = this.search;
            this.$cookie.set('search',search);
            this.$router.push({ path: '/search', query: { s: search  } });
            this.$router.go();
        },
        getBasketDetails(){
            var customer_id = this.$session.get("LoginID");
            var session_id = this.$cookie.get('device');
            var quote_id = '';
            if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
            {
                quote_id = '';
            }
            else
            {
                quote_id = this.$cookie.get('QuoteId');
            }

            // if(this.$session.has("QuoteId") === false)
            // {
            //     quote_id = '';
            // }
            // else
            // {
            //     quote_id = this.$session.get("QuoteId");
            // }
            axios.post('/webapi/webshop/cart_listing',{
                quote_id: quote_id,
                customer_id: customer_id,
                session_id: session_id,
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    localStorage.cartNotEmpty = true;
                }else{
                    localStorage.removeItem('cartNotEmpty');
                }
            })
        },

    }
}
</script>

<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>