<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->

    <div>   
        <!-- main content -->
        <div class="container main_class_misc">
            <div class="row" style="margin: auto;">
                <h3 style="text-align: center;border-bottom: 2px solid #4d4d4d;width: 7em;margin: auto;margin-bottom: 1em;margin-top: 1em;">About Us</h3>
            </div>
            <img alt="ABOUT US" class="see_all_categories_img lazyload" src="assets/img/about_us.png">
            <div class="form_div_box" style="margin: 22vw;text-align: left;margin-top: 2em;margin-bottom: 3em;">
                <p v-html="aboutus.content">{{aboutus.content}}</p>
            </div>
        </div>
    </div>	
</div>
</template>

<script>
import axios from 'axios'
import SpinnerData from './SpinnerData.vue'
export default {
    name: 'AboutUsPage',
    components: { 
       SpinnerData, 
    },
    metaInfo(){
        return {
            titleTemplate:this.metaTitle+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.aboutus.meta_description },
                { name: 'keywords', content: this.aboutus.meta_keywords }
            ]
        }
    },
    data(){
        return{
            aboutus:'',
            metaTitle: '',
            loading: false,
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.getAboutus()
    },
    methods:{
        getAboutus(){
            this.loading = true;
            axios.get("/webapi/webshop/get_cms_page/about-us")
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.aboutus = response.data.cms_page_detail;
                    if(this.aboutus.meta_title){
                        this.metaTitle = this.aboutus.meta_title;
                    }else{
                        this.metaTitle = this.aboutus.title;
                    }
                    this.loading = false;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.loading = false;
                }
                
            })           
        }
    },
}
</script>

<style>

</style>