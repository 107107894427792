<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!-- spinner end -->
    <div class="container main-box-sec order-success">
        <div class="row row-main-div">
            <div class="row_main_div_first_col_md_class">
                <div id="maindivshopproduct_7" class="">

                     <!-- Error Messages start -->
                    <div id="error-dialog" style="display: none;">
                        <div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                            <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                <div style="margin:0 auto;">
                                    <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                        <div class="">
                                            <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                {{message}}
                                            </ul>
                                        </div>
                                    <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" onclick="var obj = document.getElementById('error-dialog'); obj.remove();" class="mdl-button confirm">Ok</button></div>
                                </div>
                            </div>
                        </div>
                        <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                    </div>
                    <!-- Error Messages End -->
                    <!-- Success Messages start -->
                    <div id="success-dialog" style="display: none;">
                        <div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                            <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                <div style="margin:0 auto;">
                                    <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                                        <div class="">
                                            <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                                {{message}}
                                            </ul>
                                        </div>
                                    <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                </div>
                            </div>
                        </div>
                        <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                    </div>
                    <!-- success Messages End -->

                    <div>
                        <div style="display: flex;flex-direction: column;justify-content: center;">
                            <div class="sucess-img" style="">
                                <img style="" src="/assets/img/order_complete_icon.png">
                                <div style="font-size: 19px;color: #2F1317;font-family: 'Arial';text-align: center;padding-top: 2em;display: inline;flex-direction: row;justify-content: left;font-weight: bold !important;padding-bottom: 0em;padding-left: 3em;padding-right: 3em;color: #664242;">
                                    <div class="main_section_title">Thank you for your order, <br>you will receive your delivery at</div>
                                    <div class="order-date-time" id="order_date_time">
                                    </div>
                                </div>
                            </div>
                        <div class="sucess-order-summary">
                            <div class="success-title">
                                Order Summary ( ID: {{orderdata.increment_id}} )
                            </div>
                            <div class="order-product-detail-main">
                                <div v-for="orderitems in orderdata.order_items" :key="orderitems.item_id">
                                    <div class="order-product-detail">
                                        {{orderitems.qty_ordered}} x {{orderitems.product_name}} -
                                    </div>
                                </div>
                                <div class="order-total-div">
                                    <div>
                                        <b>Total:</b>
                                    </div>
                                    <div class="order-final-total">
                                        AED {{orderdata.grand_total}}
                                    </div>
                                </div> 
                            </div>   
                        </div>
                    <div class="order-delivery-address-main">
                        <div class="order-delivery-address">
                            <div class="success-title">
                                Delivery Address
                            </div>
                           <div class="order-content"><b>City:</b> {{addressdetails.city}}</div>
                           <div class="order-content"><b>Area:</b> {{addressdetails.region}}</div>
                            <div class="order-content"><b>Building:</b> {{addressdetails.building}}</div>
                            <div class="order-content"><b>Building Floor:</b> {{addressdetails.building_floor}}</div>
                            <div class="order-content"><b>Apartment Number:</b> {{addressdetails.posession_number}}</div>
                            <div class="order-content"><b>Recipient phone number:</b> (+{{addressdetails.phone_prefix}}) {{addressdetails.mobile_no}}</div>    
                        </div>
                        <div class="order-delivery-address">
                            <div class="success-title">
                                Order By
                            </div>
                           <div class="order-content"><b>Email:</b>  {{orderdata.customer_email}}</div>
                           <div class="order-content"><b>Phone:</b> (+{{orderdata.customer_phone_prefix}}) {{orderdata.customer_phone}}</div>
                        </div>
                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'OrderSuccess',
    components: { 
       SpinnerData, 
    },
    data(){
        return{
            deliverydetails:'',
            addressdetails:'',
            orderdata:'',
            message:'',
            deliveryoffer:'',
            loading: false,
            onlinepaysuccess:'',
        }
    },
    metaInfo(){
        return {
            titleTemplate:'Order successful | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Order successful' },
                { name: 'keywords', content: 'Order successful' }
            ]
        }
    },
    mounted(){	
        window.scrollTo(0,0);
        this.$session.start();
        this.getOrderDetails();
    },
    methods:{
        getOrderDetails(){ 
            this.loading = true;
            var orderid = this.$session.get('orderid');
            axios.get('/webapi/webshop/my_order_detail' + '/' + orderid)
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    
                    // email send for order confirmation of online payment.
                    let emailStatue =JSON.parse(response.data.OrderData.email_sent);
                    var paymentURL = window.location.search;
                    const urlParams = new URLSearchParams(paymentURL);
                    if(urlParams.has('paymentId') == true && emailStatue == 0){
                        this.getsuccessMail();
                        this.getSuccessresponse();
                    }
                    this.deliverydetails = response.data.OrderData.delivery_details;
                    this.addressdetails = response.data.OrderData.shipping_address;
                    this.orderdata = response.data.OrderData;
                    console.log("this.deliverydetails",this.deliverydetails ,"this.addressdetails",this.addressdetails ,"this.orderdata",this.orderdata);
                    axios.get('/webapi/webshop/get_delivery_offer/')
                    .then(response => {
                        this.deliveryoffer = response.data.Fbcgetdelivery_offer;
                        var delivery_date = this.deliverydetails.order_date;
                        if(this.deliverydetails.time_slot === this.deliveryoffer.name){
                            document.getElementById('order_date_time').innerText = delivery_date +', '+ document.getElementById('order_date_time').innerText.replace('between','')+''+this.deliverydetails.time_slot;
                        }else{
                            document.getElementById('order_date_time').innerText = delivery_date +', between '+ this.deliverydetails.time_slot;
                        }
                    })
					
					// destroy the QuoteID.
					// this.$session.delete('QuoteId');
                    this.$cookie.delete('QuoteId');
                    this.loading = false;
                    this.onPurchase();
                }else{
                    this.message = response.data.message;
                    document.getElementById('error-dialog').style.display="block";
                    this.loading = false;
                }
            }) 
        },
        getsuccessMail(){
            var order_id = this.$session.get('orderid');
            var currency_code = 'AED';
            axios.post('/webapi/webshop/send_order_confirmation_email',{
                order_id: order_id,
                currency_code: currency_code,
            }).then(response => {
                    if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.message = response.data.message;
                    console.log("getsuccessMail");
                    
                    document.getElementById('success-dialog').style.display = 'block';
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.message = response.data.message;
                    document.getElementById('error-dialog').style.display = 'block';
                }
            })
        },
        getSuccessresponse(){
            var payload = new FormData();
            payload.set('orderid', this.$session.get("orderid"));
            payload.set('paymentId', this.$route.query.paymentId);
            return axios({
                method: 'post',
                url: 'admin/PaymentController/StoreApiResponse',
                data: payload,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.onlinepaysuccess = response.data;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.onlinepaysuccess = response.data;
                    
                }
            })
        },
        onPurchase(){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                'event': 'purchaseComplete',
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': this.orderdata.order_id, // Transaction ID. Required for purchases and refunds.
                            'affiliation': 'Online Shopping Store',
                            'revenue': this.orderdata.grand_total, // Total transaction value (incl. tax and shipping)
                            'tax': this.orderdata.base_tax_amount,
                            'shipping':this.orderdata.base_shipping_tax_amount,
                            'coupon':this.orderdata.coupon_code
                        },
                        
                        'products': this.orderdata.order_items
                    }
                }

            });
        },
    }
}
</script>

<style scoped>
@media(max-width:599px) {
	.row_main_div_first_col_md_class {
		margin: 1em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:600px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:900px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:1200px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}

@media(min-width:1800px) {
	.row_main_div_first_col_md_class {
		margin: 7em;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
	}
}
</style>