<template>
    <main>
        <!-- main content -->
        <div id="PRODUCT_COMPONENT" class="main_product_component_class">
            
            <!-- spinner -->
            <div v-if="loading">
                <SpinnerData />
            </div>
            <!--  -->

            <div class="container">
                <div class="row row-main-div">
                    <div class="col-md-6 product_main_div_row_section">
                        <div class="product_main_div_cross_icon">
                            <i aria-hidden="true" class="fa fa-times" style="padding-right: 0.5em;padding-top: 0.5em;"></i>
                        </div>
                        <div id="maindivshopproduct_7" class="maindivshopproduct_class">
                            <div class="main_div_shop_inner_class_multiple_images">
                                <div class="main_div_shop_second_inner_class product-image-main">


                                    <div class="mobile_only">
                                        <div style="padding-top:1em;font-size: 22px;color: #2F1317;font-family: 'proxima-nova', sans-serif !important;font-weight: bold !important;text-align: left;"> 
                                             {{products.name}} 
                                        </div>
                                        <ol class="breadcrumbs">
                                            <li><router-link to="/"> HappyBox</router-link></li>
                                            &nbsp;
                                            <li style="font-family: Amazon Ember,Arial,sans-serif;font-size: 12px !important;box-sizing: border-box;list-style: disc;word-wrap: break-word;margin: 0 10px 0 0;margin-right: .4235em;float: left;line-height: 14px;color: #949494;position: relative;top: -1px;word-wrap: break-word;margin-left: 6px;list-style: none;"><span class="a-list-item a-color-tertiary" style="font-size: 12px !important;line-height: 1.5 !important;">></span></li>
                                            &nbsp;
                                            <li><a href="#"> Category</a></li>
                                            &nbsp;
                                            <li style="font-family:Amazon Ember,Arial,sans-serif;font-size: 12px !important;box-sizing: border-box;list-style: disc;word-wrap: break-word;margin: 0 10px 0 0;margin-right: .4235em;float: left;line-height: 14px;color: #949494;position: relative;top: -1px;word-wrap: break-word;margin-left: 6px;list-style: none;"><span class="a-list-item a-color-tertiary" style="font-size: 12px !important;line-height: 1.5 !important;">></span></li>
                                            &nbsp;
                                            <li style="color: black;">{{ products.name }}</li>
                                        </ol>
                                        <hr style="height: 1px;border-bottom: none;border-left: none;border-right: none;margin-bottom: 1.25em;color: white;margin-top: 1.25em;">
                                    </div>
                                    <div class="slideshow-container">
                                        <div class="mySlides fade" style="display: block;">
                                            <!-- spinner -->
                                            <div v-if="loading">
                                                <SpinnerData />
                                            </div>
                                            <!-- end -->
                                            <img :src="'/uploads/products/original/' + products.base_image" id="preview_img" class="product_image" style="height:500px;">
                                        </div>
                                        
                                        <div class="product_miniature_images">
                                            <div id="slide-wrapper">

                                                <img id="slideLeft" class="arrow" v-on:click="leftclick" src="/assets/img/arrow-left.png">

                                                <div id="slider"> 
                                                    <div v-for="gallery in products.mediaGallery" :key="gallery.id" >
                                                        <img :src="'/uploads/products/original/'+ gallery.image" @click="changeIt(gallery.id)" :id="'mini_'+gallery.id" class="thumbnail">
                                                    </div>
                                                </div>

                                                <img id="slideRight" class="arrow" v-on:click="rightclick" src="/assets/img/arrow-right.png">

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="slides_images_plus_minus">
                                        <img onclick="plusSlides(-1)" style="width: 50%;background: transparent;padding-right: 6px;filter: invert(132%);height: 100%;" src="assets/img/fa_icons/fa-arrow-left.svg" class="active">
                                        <img onclick="plusSlides(1)" style="width: 50%;background: transparent;padding-right: 6px;filter: invert(132%);height: 100%;" src="assets/img/fa_icons/fa-arrow-right.svg">
                                    </div> -->
                                </div>
                                <!-- <div style="text-align:center;margin-top: 2em;margin-bottom: 1em; display: none !important;">
                                    <span class="dot active" onclick="currentSlide(0)" style="cursor: pointer;height: 15px;width: 15px;margin: 0 2px;background-color: #bbb;border-radius: 50%;display: inline-block;transition: background-color 0.6s ease;"></span> 
                                    <span class="dot" onclick="currentSlide(1)" style="cursor: pointer;height: 15px;width: 15px;margin: 0 2px;background-color: #bbb;border-radius: 50%;display: inline-block;transition: background-color 0.6s ease;"></span> 
                                    <span class="dot" onclick="currentSlide(2)" style="cursor: pointer;height: 15px;width: 15px;margin: 0 2px;background-color: #bbb;border-radius: 50%;display: inline-block;transition: background-color 0.6s ease;"></span> 
                                    <span class="dot" onclick="currentSlide(3)" style="cursor: pointer;height: 15px;width: 15px;margin: 0 2px;background-color: #bbb;border-radius: 50%;display: inline-block;transition: background-color 0.6s ease;"></span> 
                                </div> -->
                                <div style="display: flex; flex-direction: column;" class="product-details-sec">
                                    <div class="product_description_name_price">
                                        <div class="desktop_only">
                                            <div class="product-name" style="font-size: 42px;color: #2F1317;font-family: 'proxima-nova', sans-serif !important;font-weight: bold !important;text-align: left;"> 
                                                {{products.name}}
                                            </div>
                                                <ol class="breadcrumbs">
                                                <li><router-link to="/"> HappyBox</router-link></li>
                                                &nbsp;
                                                <li style="font-family:Amazon Ember,Arial,sans-serif;font-size: 12px !important;box-sizing: border-box;list-style: disc;word-wrap: break-word;margin: 0 10px 0 0;margin-right: .4235em;float: left;line-height: 14px;color: #949494;position: relative;top: -1px;word-wrap: break-word;margin-left: 6px;list-style: none;"><span class="a-list-item a-color-tertiary" style="font-size: 12px !important;line-height: 1.5 !important;">></span></li>
                                                &nbsp;
                                                <li id="category_name"><a id="category_link"></a></li>
                                                &nbsp;
                                                <li style="font-family: Amazon Ember,Arial,sans-serif;font-size: 12px !important;box-sizing: border-box;list-style: disc;word-wrap: break-word;margin: 0 10px 0 0;margin-right: .4235em;float: left;line-height: 14px;color: #949494;position: relative;top: -1px;word-wrap: break-word;margin-left: 6px;list-style: none;"><span class="a-list-item a-color-tertiary" style="font-size: 12px !important;line-height: 1.5 !important;">></span></li>
                                                &nbsp;
                                                <li style="color: black;">{{products.name}}</li>
                                                </ol>
                                            </div>
                                            <div class="main_div_shop_second_inner_c0ass">
                                                <div class="product_name_price_description">
                                                    <div class="" style="display: flex;flex-direction: row;justify-content: space-evenly;">
                                                        <div style="text-align: left;width: 100%;" >
                                                            <b v-html="products.highlights">>{{products.highlights}}</b>
                                                        </div>
                                                    </div>
                                                    <div class="product_name_price_description_content" style="display: flex;flex-direction: row;justify-content: space-evenly;padding-top: 17px;width: 100%;border: none;">
                                                        <div style="text-align: left;width: 100%;padding-bottom: 0vh;" v-html="products.description">
                                                           {{products.description}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="product_optionsalexandra">
                                                <div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="PRODUCT_OPTIONS">
                                    <!-- Delivery Date Start -->

                                    <!-- Delivery Date End -->

                                    <div class="product_options_main_div" v-for="variants in products.product_variants" :key="variants.variant_id">
                                        <div v-if="variants.variant_code != 'color' && variants.variant_code != 'colors' && variants.variant_code != 'box_color' && variants.variant_code != 'COLOR' && variants.variant_code != 'COLOUR'">
                                            <div class="product_options_main_div_first_inner" style="color: black;">
                                                <label class="">{{variants.variant_name}}</label>
                                            </div>
                                       
                                            <div class="container"  style="width: 100%;display: flex;flex-direction: column;justify-content: left;">
                                                <div v-for="options in variants.variant_options" :key="options.attr_value" >
                                                    <div class="round">
                                                        <input type="radio" :id="options.attr_options_name" :data-name ="variants.variant_code" :class="'cat_class  ' +`${variants.variant_code}`+`_check`" v-on:click="setvariantdetails(),productvariantdata()"  :name="variants.variant_code" :value="options.attr_value+'_'+variants.variant_id">
                                                        <!--<input  type="radio" :id="options.attr_options_name" v-model="varients" class="mdl-radio__button"  :name="variants.variant_code" :value="options.attr_value+'_'+variants.variant_id">-->
                                                        <label type="checkbox" class="mdl-radio mdl-js-radio mdl-js-ripple-effect"  :for="options.attr_options_name"></label>
                                                    </div>
                                                    <span class="option_tag_small_size option_tag_small_size" style="padding-top:
                                                        9px;
                                                        ;margin-top: 0;display: flex;flex-direction: row;min-width: 296px;">
                                                        AED {{options.price}}&emsp;&emsp;{{options.attr_options_name}} 
                                                        <!-- - <div class='original_price_product_option '>AED {{products.webshop_price}}</div> -->
                                                        
                                                    </span>
                                                    <!-- <div class="radio-sec-info" style="padding-left: 3em;padding-top: 0em;font-size: 1em !important;min-height: 0.4em;">
                                                        (23-26 roses)
                                                    </div> -->
    
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <!-- Box Color -->
                                    <div class="product_options_main_div" id="product_color_options_main_div" style="display:none;">
                                        
                                            <div class="product_options_main_div_first_inner" style="color: black;">
                                                <label class="">{{ColorVariantMster.variant_name}}</label>
                                            </div>
                                       
                                            <div class="container"  style="width: 100%;display: flex;flex-direction: column;justify-content: left;">
                                                <div v-for="color in ColorVariantDetails" :key="color.attr_value">
                                                    <div class="round">
                                                        <input type="radio" :id="color.attr_options_name" :data-name ="ColorVariantMster.variant_code" :class="'color_class  ' +`${ColorVariantMster.variant_code}`+`_check`" v-on:click="productvariantdata"  :name="ColorVariantMster.variant_code" :value="color.attr_value+'_'+ColorVariantMster.variant_id">
                                                        <!--<input  type="radio" :id="options.attr_options_name" v-model="varients" class="mdl-radio__button"  :name="variants.variant_code" :value="options.attr_value+'_'+variants.variant_id">-->
                                                        <label type="checkbox" class="mdl-radio mdl-js-radio mdl-js-ripple-effect"  :for="color.attr_options_name"></label>
                                                    </div>
                                                    <span class="option_tag_small_size option_tag_small_size" style="padding-top:
                                                        9px;
                                                        ;margin-top: 0;display: flex;flex-direction: row;min-width: 296px;">
                                                        {{color.attr_options_name}} 
                                                        <!-- - <div class='original_price_product_option '>AED {{products.webshop_price}}</div> -->
                                                        
                                                    </span>
                                                    <!-- <div class="radio-sec-info" style="padding-left: 3em;padding-top: 0em;font-size: 1em !important;min-height: 0.4em;">
                                                        (23-26 roses)
                                                    </div> -->
    
                                                </div>

                                            </div>
                                        
                                    </div>

                                    <div class="total_price_summed" style="padding-top: 4em;">
                                        <div class="total_price_text">Total Price: </div>
                                        <div class="main_price_component" style="text-align: right;max-width: 23em;width: 100vw;display: flex;flex-direction: row;">
                                            <div id="total-price" class='original_price'>AED {{products.webshop_price}}</div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-6 external_increment_decrement_btn_product_description_desktop_view">
                                        <div class="" id="">
                                            <div class="quantity-add-to-cart-sec">
                                                <div id="order_detail_order_detail_quantity223" style="display: block;" class="inc-dec-sec">
                                                    <div style="display: flex;flex-direction: row;justify-content: space-evenly;border: 1px solid #f98c79;/* border-bottom: 0; */height: 3em;font-family: 'proxima-nova', sans-serif !important;height: 44px;">
                                                        <button @click="updateCart('product_quantity','subtract')" style="font-size: 30px;color: #f0a951;width: 100%;text-align: center;background: white;border: none;">
                                                        -
                                                        </button>
                                                        <div style="height: 100%;color: #f0a951;border-right: 1px solid #f98c79;">
                                                        </div>
                                                        <input type="text" value="1" id="product_quantity" style="font-size: 24px;color: #f0a951;width: 100%;text-align: center;background: white;border: none;">
                                                        <!-- <button id="order_detail_product" style="font-size: 19px;color: #f0a951;width: 100%;text-align: center;background: white;border: none;">
                                                        1
                                                        </button> -->

                                                        <div style="height: 100%;color: #f0a951;border-right: 1px solid #f98c79;">
                                                        </div>
                                                        <button @click="updateCart('product_quantity','add')" style="font-size:30px;color: #f0a951;width: 100%;text-align: center;background: white;border: none;">
                                                        +
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="add-cart-btn">
                                                    <div v-on:click="addtocart" style="border: 1px solid #B17830;">
                                                        <button class="continue_purchase_button add_to_cart"  id="button_add_to_the_card223" style="width: 100%;height: 4em;background: rgb(238,153,47);background: linear-gradient(0deg, rgb(238, 153, 47) 11%, rgb(242, 174, 91) 39%, rgb(245, 194, 131) 100%);color: white;font-size: 18px;font-family: 'proxima-nova', sans-serif !important;border: none;">
                                                            Add To Cart
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div class="select-addon-sec">
                            <div class="select-addon-sec-container">
                                <div class="border-title"><h2>Select Your Add-ons</h2></div>
                                    <div class="main-list-of-items">
                                        <div id="products_main_div" class="main-list-of-items-first-inner">
                                            <div class="main-list-of-items-zero-inner" style="border: 2px solid transparent;" id="product_record_id__105">
                                                <div class="main-list-of-items---item-first-inner">
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <img class="external_class_element lazyload highlighted_product_image" alt="image" src="/assets/img/images/622a2c06-cf32-4da9-99ba-da5b0271795e_1_1.jpg">
                                                    </div>
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <div class="wrapped_text product_name product_product_name">
                                                            Happy Birthday Card
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="original_price product_price">AED 169.00</div>
                                                        <div class="add-on-button-sec">
                                                            <button class="add-on-button">Add On</button>
                                                        </div>                              
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            <div class="main-list-of-items-zero-inner" style="border: 2px solid transparent;" id="product_record_id__103">
                                                <div class="main-list-of-items---item-first-inner">
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <img class="external_class_element lazyload highlighted_product_image" alt="image" src="/assets/img/images/496f2c7f-6c99-406d-86b4-3c34d53317bb_1_1.jpg">
                                                    </div>
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <div class="wrapped_text product_name product_product_name">
                                                            Lots of Love
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="original_price product_price">AED 159.00</div>
                                                        <div class="add-on-button-sec">
                                                            <button class="add-on-button">Add On</button> 
                                                        </div>                                 
                                                    </div>
                                                </div>
                                            </div>
                                                
                                            <div class="main-list-of-items-zero-inner" style="border: 2px solid transparent;" id="product_record_id__207">
                                                <div class="main-list-of-items---item-first-inner">
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <img class="external_class_element lazyload highlighted_product_image" alt="image" src="/assets/img/images/1372edbc-9ed8-42ac-b40f-82d5e5e9d680_1_1.jpg">
                                                    </div>
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <div class="wrapped_text product_name product_product_name">
                                                            Happy Birthday Card
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="original_price product_price">AED 229.00</div>
                                                        <div class="add-on-button-sec">
                                                            <button class="add-on-button">Add On</button> 
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="main-list-of-items-zero-inner" style="border: 2px solid transparent;" id="product_record_id__208">
                                                <div class="main-list-of-items---item-first-inner">
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <img class="external_class_element lazyload highlighted_product_image" alt="image" src="/assets/img/images/91cfefb8-1314-4566-a1a7-cba043a133dd_1_1.jpg">
                                                    </div>
                                                </div>
                                                <div class="main-list-of-items---item-first-inner">
                                                    <div>
                                                        <div class="wrapped_text product_name product_product_name">
                                                            Lots of Love
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="original_price product_price">AED 199.00</div>
                                                        <div class="add-on-button-sec">
                                                            <button class="add-on-button">Add On</button> 
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="skip-add-on-sec">
                                        <div class="skip-add-on-sec-container">
                                            <button class="skip-on-button">Skip Add On</button>
                                            <button class="proceed-to-checkout-button">Proceed to checkout</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                        <!-- Error message start -->
                            <!-- Success Messages start -->
                            <div id="success-dialog" style="display: none;" class="dialog-main">
                                <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                    <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                        <div style="margin:0 auto;">
                                            <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                                                <div class="">
                                                    <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
                                                        {{message}}
                                                    </ul>
                                                </div>
                                            <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
                            </div>
                            <!-- success Messages End -->
                            <!-- Error Messages of same day Delivery -->
                            <div id="error-sameday-delivery-dialog" style="display: none;" class="dialog-main">
                                <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                    <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                        <div style="margin:0 auto;">
                                            <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Sorry</div>
                                                <div class="">
                                                    <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                        This Product is not available for same day Delivery.
                                                    </ul>
                                                </div>
                                            <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-sameday-delivery-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                            </div>
                            <!-- Error Messages End -->
                            <!-- Error Messages of same day Delivery -->
                            <div id="error-pickday-sameday-delivery-dialog" style="display: none;" class="dialog-main">
                                <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                    <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                        <div style="margin:0 auto;">
                                            <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Sorry</div>
                                                <div class="">
                                                    <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                        This Product is not available for same day Delivery.
                                                    </ul>
                                                </div>
                                            <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-pickday-sameday-delivery-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                            </div>
                            <!-- Error Messages End -->
                                <!-- Error Messages Box Color -->
                                <div id="error-box-color-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                                    <div class="">
                                                        <div class="dialog-content"  id="error-dialog" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            Box color has not been Chosen.
                                                        </div>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-box-color-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                                </div>
                                <!-- Error Messages End -->
                                <!-- Error Messages Box Color -->
                                <div id="error-product-size-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            Product size has not been Chosen.
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-product-size-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                                </div>
                                <!-- Error Messages End -->
                                <!-- Error Messages Box Color -->
                                <div id="error-product-exist-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Something Wrong Happened!</div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            Error<br>Product dose not exist
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-product-exist-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                                </div>
                                <!-- Error Messages End -->
                                <!-- Error Message out of stock start -->
                                <div id="product-outofstock-dialog" style="display: none;" class="dialog-main">
                                    <div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
                                        <div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
                                            <div style="margin:0 auto;">
                                                <div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
                                                    <div class="">
                                                        <ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
                                                            This Variant is Out Of Stock!<br>Please Choose Other Color.
                                                        </ul>
                                                    </div>
                                                <div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('product-outofstock-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
                                </div>
                                <!-- Error Messages End -->
                        <!-- Error message End -->

                        

                        <!-- Our recommendation start -->

                            <div class="our_recommendations_class_main">
                                <div class="our_recommendations_class">
                                   Our recommendations
                                </div>
                               
                                    <div class="main-list-of-items">
                                        <div id="products_main_div" class="main-list-of-items-first-inner">

                                        <!-- 1 product -->
                                        <div v-for="rproduct in our_recommended" :key="rproduct.id" class="main-list-of-items-zero-inner">
                                            <div class="main-list-of-items---item-first-inner">
                                            </div>
                                            <div class="main-list-of-items---item-first-inner">
                                                <div>
                                                <router-link :to="'/product/' + rproduct.url_key" onclick="window.location.reload();">   <img class="external_class_element lazyload highlighted_product_image" alt="image"  :src="'/uploads/products/thumb/' + rproduct.base_image"></router-link>
                                                </div>
                                            </div>
                                            <div class="main-list-of-items---item-first-inner">
                                                <div>
                                                    <div class="wrapped_text product_name product_product_name">
                                                        {{rproduct.name}}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style="display: flex; flex-direction: column;width: min-content;">
                                                        <div style="display: flex; flex-direction: column;width: min-content;">
                                                            <div class='original_price product_price'>AED {{rproduct.max_price}}</div>
                                                        </div>
                                                            
                                                        <div style="display: flex; flex-direction: column;width: min-content;">
                                                        <!--   <div class='original_price product_price'>AED {{product.min_price}} </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Our recommendation end -->
                            <!-- BASKET_FOOTER start -->
                            <div style="position: fixed; display: none; bottom: 0px; z-index: 1; width: 100%; opacity: 1; background: rgb(112, 206, 52); color: rgb(112, 206, 52);" id="BASKET_FOOTER" class="main_footer_class" onclick="SPINNER.actions.showSpinner();BASKET_FOOTER.actions.startCheckout('BASKET')">
                                <div class="basket_component_class_footer">
                                    <div class="basket_component_class_footer_first_inner">
                                    <div style="word-break: break-all;word-spacing: unset;font-size: 17px;letter-spacing: 2px;margin: 0 0 0px;color: #F0F0F0;text-align: center;background: transparent;width: min-content;height: min-content;width: 2em;height: 2em;position: relative;border-radius: 7px;margin-left: 1em;">        
                                        <div class="basket_footer_products_quantity">
                                        <div style="position: relative;">
                                            <img alt="Basket" class="basket_footer_image" src="/assets/img/fa_icons/fa-shopping-basket.svg">
                                            <div id="BASKET_FOOTERproducts_quantity" class="basket_FOOTER_products_quantity">{{basketcartItems}}</div>
                                        </div>
                                        </div>
                                    </div>
                                    <div id="add_to_the_basket_div" class="add_to_the_basket_div">
                                        <router-link to="/basket/">
                                            <div style="cursor: pointer;color: white;" class="" >
                                            VIEW BASKET
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="BASKET_FOOTERgrand_total">
                                        <div id="BASKET_FOOTERgrand_total">
                                        <div class="original_price ">AED {{basketcartDetails.grand_total}}</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <!-- BASKET_FOOTER End -->



                    </div>
                </div>
            </div>
        </div>
        
    </main>
</template>

<script>
import axios from 'axios'
import SpinnerData from './SpinnerData.vue'
import Vue from 'vue';
export default {
    name: 'ProductPage',
    components: { 
       SpinnerData, 
    },
    metaInfo(){
        return {
            titleTemplate:this.metaTitle+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.products.meta_description },
                { name: 'keywords', content: this.products.meta_keyword }
            ]
        }
    },
    data() {
        return {
            products:'',
            total_price:'',
            slug:'',
            variantdata:'',
            rproducts:'',
            varients: false,
            selectDate:'',
            disabledDates: {
                to: new Date(Date.now() - 8640000)
            },
            message:'',
            basketcartDetails:'',
            basketcartItems:'',
            ColorVariantMster:'',
            ColorVariantDetails:'',
            metaTitle:'',
            our_recommended:'',
            loading: false,
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.loading = true;
        this.productList();
        this.getBestseller();
        this.getBasketDetails();
    },
    computed: {
        isDisabled: function(){
        return !this.varients;
        },

    },
    methods: {
        getPreviousPage() {
            var productid = this.products.id
            axios.post('/webapi/webshop/get_product_category_by_level',{
                product_id:productid,
                cat_level:'',
            })
            .then(response => {
                var category = response.data.CategoryDetails;
                document.getElementById('category_name').innerHTML ='<a href="/category/'+category.slug+'">'+category.cat_name+'</a>';
                
            })
        },
        productList(){
            this.loading = true;
            const product_url_key = this.$route.params.url_key;
            axios.post("/webapi/webshop/product_detail",{
            product_url_key: product_url_key,
            })
            .then(response => { 
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.products = response.data.ProductData;
                    if(this.products.meta_title){
                        
                        this.metaTitle = this.products.meta_title;
                    }else{
                        this.metaTitle = this.products.name;
                        
                    }
                    this.ProductClick(this.products);
                    this.ProductDetailImpressions(this.products);    
                    this.getPreviousPage();
                    this.loading = false;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.loading = false;
                }
                  
            })
        },
        
        ProductClick(productObj){
            // console.log("Product Obj:" ,productObj);
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                'currencyCode': 'AED',
                'click': {
                'products': [{
                    'name': productObj.name,                      // Name or ID is required.
                    'id': productObj.id,
                    'price': productObj.webshop_price,
                    'category': this.category,
                    'variant': productObj.product_variants,
                    
                    }]
                }
                },
                // 'eventCallback': function() {
                // document.location = '/product/' + productObj.url_key
                // }
            });
        },
        ProductDetailImpressions(productObj){
            // console.log("Product Detail Impressions:" ,productObj);
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            'ecommerce': {
                'currencyCode': 'AED',
                'detail': {
                'products': [{
                    'name': productObj.name,         // Name or ID is required.
                    'id': productObj.id,
                    'price': productObj.webshop_price,
                    'category':  this.category,
                    'variant': productObj.product_variants
                }]
                }
            }
            });
        },
        updateCart(incordec,updateType){
            var product_quantity = document.getElementById(incordec);
            var totalPrice = document.getElementById('total-price').innerText.replace('AED', ''); 
           
            if(updateType === 'subtract'){
                if(product_quantity.value <= 1)
               {
                   product_quantity.value = 1;
               }
               else
               {
                   product_quantity.value-- ;
               }
            }else if(updateType === 'add'){
                product_quantity.value++ ;
            }
            var currentTotalPrice = null;
            if(this.variantdata.conf_simple_pro_pice == undefined){
                currentTotalPrice = this.products.webshop_price * product_quantity.value
            }else{
                currentTotalPrice = this.variantdata.conf_simple_pro_pice * product_quantity.value
            }
            document.getElementById('total-price').innerText = 'AED' +' '+ currentTotalPrice;
        },
        productvariantdata(){
            var  selected_values = [];
            var value_key = 0;
            var variantDate = document.getElementsByClassName('cat_class'); 
            for(var i = 0; i < variantDate.length; i++){
                if(variantDate[i].checked){
                    var selected_attr  = variantDate[i].value.split("_")
                    selected_values[value_key]  ={'attr_id':selected_attr[1], 'attr_value':selected_attr[0]};
                    value_key++;
                }
            }

            var  selected_color_values = [];
            var ColorVariantData = document.getElementsByClassName('color_class');
            // console.log(ColorVariantData);
            var colorCount = 0;
            for(var j = 0; j < ColorVariantData.length; j++){
                if(ColorVariantData[j].checked){

                    var selected_color_attr  = ColorVariantData[j].value.split("_")
                    selected_color_values[colorCount]  ={'attr_id':selected_color_attr[1], 'attr_value':selected_color_attr[0]};
                    colorCount++;
                }
            }

            let selectValues = [...selected_values, ...selected_color_values];
            // console.log("selected Varient: ",selectValues);
           // let selectvariantData = JSON.stringify(selectValues);
            axios.post("/webapi/webshop/get_conf_simprod_by_variants",{
                product_id : this.products.id,
                total_variant : selectValues.length,
                selected_variant  : selectValues,
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.variantdata = response.data.ConfigSimpleDetails;
                    var product_quantity = document.getElementById('product_quantity');
                    if(this.variantdata.conf_simple_pro_inventory.qty === 0 && this.variantdata.conf_simple_pro_inventory.status === 'outofstock'){
                        document.getElementById('product-outofstock-dialog').style.display="block";
                        document.getElementById('total-price').innerText = 'AED' +' '+ this.variantdata.conf_simple_pro_pice * product_quantity.value;
                        document.getElementById('button_add_to_the_card223').disabled = true;
                        document.getElementById('button_add_to_the_card223').style.cursor = "not-allowed";
                    }else{
                        document.getElementById('total-price').innerText = 'AED' +' '+ this.variantdata.conf_simple_pro_pice * product_quantity.value;
                        document.getElementById('button_add_to_the_card223').disabled = false;
                        document.getElementById('button_add_to_the_card223').style.cursor = "pointer";
                    }
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.variantdata = response.data.ConfigSimpleDetails;
                    // console.log("get_conf_simprod_by_variants",this.variantdata);
                    this.message = response.data.message;
                    document.getElementById('error-product-exist-dialog').style.display="block";
                    // document.getElementById('button_add_to_the_card223').disabled = true;
                    document.getElementById('button_add_to_the_card223').style.cursor = "not-allowed";
                    
                }
            })
            
        },
        
        getavailableColorVariant(attr_id,attr_value){
            axios.post("/webapi/webshop/get_available_variant",{
                parent_id : this.products.id,
                attr_id : attr_id,
                attr_value  : attr_value,
            })
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                    this.ColorVariantMster = response.data.VariantOptions.variant_master;
                    this.ColorVariantDetails = response.data.VariantOptions.variant_details;

                    document.getElementById('product_color_options_main_div').style.display = 'block';
                    // console.log("Color Respoanse: ",this.ColorVariantMster);
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    console.log(response.data);
                }
            })
        },
        setvariantdetails(){
            var total_variant = Object.keys(this.products.product_variants).length;
            var selected_id = this.products.product_variants[0].selected_id;
           // var variantDate = document.querySelector('input[class="cat_class"]:checked').value;
            var  selected_values = [];
            var value_key = 0;
            var variantDate = document.getElementsByClassName('cat_class');
            // console.log("variantDate", variantDate);
            for(var i = 0; i < variantDate.length; i++){
                if(variantDate[i].checked){
                    // console.log("variantDate[i]",variantDate[i]);
                    var selected_attr  = variantDate[i].value.split("_")
                    
                    selected_values[value_key]  ={'attr_id':selected_attr[1], 'attr_value':selected_attr[0]};
                    value_key++;
                }
            }
            // console.log("selected_values",selected_values)
            this.getavailableColorVariant(selected_values[0].attr_id,selected_values[0].attr_value);

        },
        
        getBasketDetails(){
			var customer_id = this.$session.get("LoginID");
			var session_id = this.$cookie.get('device');
			var quote_id = '';
            
			if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
            {
                quote_id = '';
            }
            else
            {
                quote_id = this.$cookie.get('QuoteId');
            }

			// if(this.$session.has("QuoteId") === false)
            // {
            //     quote_id = '';
            // }
            // else
            // {
            //     quote_id = this.$session.get("QuoteId");
            // }
			axios.post('/webapi/webshop/cart_listing',{
				quote_id: quote_id,
				customer_id: customer_id,
				session_id: session_id,
			})
			.then(response => {
				if(response.data.statusCode === 200 || response.data.statusCode === '200'){
					this.basketcartDetails = response.data.cartData.cartDetails;
					this.basketcartItems= response.data.cartData.cartItems.length;
                    document.getElementById('BASKET_FOOTER').style.display = 'block';
				}else{
                    document.getElementById('BASKET_FOOTER').style.display = 'none';
				}
			})
		},
        
        getBestseller(){
            axios
                .post("/webapi/webshop/product_listing",{
                categoryslug: "bestseller",
                })
                .then(response => {
                this.rproducts = response.data.ProductList;
                this.our_recommended = this.rproducts.slice(0, 4);
                
            })
        },
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        addtocart(){
            const unique = (value, index, self) => {
            return self.indexOf(value) === index
            }
            var variantDate = document.getElementsByClassName('cat_class');
            var ProductVarientCode1 = document.querySelectorAll('[class$="_check"]');
            console.log("ProductVarientCode1" , ProductVarientCode1);
            var check = 1;
            var variant_total = [];
            for(var k = 0; k < ProductVarientCode1.length; k++){
               
                variant_total.push(ProductVarientCode1[k].dataset.name)
            }
            console.log("variant_total" , variant_total);
            var category_Checked = [];
            // var category_Checked_both = []
            var varinat_final_total = variant_total.filter(unique) ;
            var pop_up_str = '' ;
            for(var l = 0; l < varinat_final_total.length; l++){
                
                var ProductVarientCode12 = document.querySelectorAll('input[name='+varinat_final_total[l].toLowerCase()+']:checked');
                if(ProductVarientCode12.length == 0){
                    if(varinat_final_total.length > l+1){
                        // .log(varinat_final_total.length);
                        pop_up_str += this.toTitleCase(varinat_final_total[l].replace("_"," "))+", ";
                    }
                    else if(varinat_final_total.length == l+1){
                        pop_up_str += this.toTitleCase(varinat_final_total[l].replace("_"," "));
                    }
                var product_size = varinat_final_total[l].split("_");
                var ProductSize = product_size.join(' ');
                var SentanceCase = this.toTitleCase(ProductSize);
                    
                    category_Checked.push(this.toTitleCase(varinat_final_total[l].replace("_"," ")));
                 //   category_Checked_both.push(SentanceCase);
                    
                    
                    
                    // category_Checked.push(varinat_final_total[l]+" has not been choosen");
                    //  var selected_attr  = category_Checked[l].value.split("_");
                    // console.log("category_Checked",category_Checked);
               }
               
            }
           
            this.$session.start();
            var product_quantity = document.getElementById("product_quantity").value;
            var product_id = this.products.id;
            var customer_id ='';
            var quote_id ='';

            if(this.$session.has("LoginID") === false)
            {
                customer_id = '';
            }
            else
            {
                customer_id = this.$session.get("LoginID");
            }

			if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
            {
                quote_id = '';
            }
            else
            {
                quote_id = this.$cookie.get('QuoteId');
            }

			// if(this.$session.has("QuoteId") === false)
            // {
            //     quote_id = '';
            // }
            // else
            // {
            //     quote_id = this.$session.get("QuoteId");
            // }
            var session_id = this.$cookie.get('device');
            var quantity = product_quantity;
            this.$session.set("product_quantity",product_quantity);
            this.$session.set("product_id",product_id);
            if(this.products.product_type == "configurable" && category_Checked.length == 0)
            {
                axios.post("/webapi/webshop/add_to_cart",{
                    product_id: product_id,
                    customer_id: customer_id,
                    session_id: session_id,
                    quote_id: quote_id,
                    quantity: quantity,
                    conf_simple_pid: this.variantdata.conf_simple_pro_id
                })
                .then(response => { 
                    if(response.data.statusCode === 200 || response.data.statusCode === '200')
                    {
                        this.message = response.data.message
                        // document.getElementById('success-dialog').style.display="block";
                        document.cookie = 'QuoteId=' + response.data.QuoteId + ";domain=;path=/";
                        // this.$session.set("QuoteId", response.data.QuoteId);
                        this.AddtoShoppingCart(this.products,quantity);
                        this.$router.push({ path: '/basket/' });
                    }
                    else
                    {
                        //document.getElementById('basket_nav_icon').style.display='';
                        //document.getElementById('BASKET_FOOTER').style.display='';
                        alert("Something went Wrong!");
                        
                    }
                })
            }
            else if(category_Checked.length == 0)
            {
                axios.post("/webapi/webshop/add_to_cart",{
                    product_id: product_id,
                    customer_id: customer_id,
                    quote_id: quote_id,
                    session_id: session_id,
                    quantity: quantity,
                })
                .then(response => { 
                    if(response.data.statusCode === 200 || response.data.statusCode === '200')
                    {
                        document.cookie = 'QuoteId=' + response.data.QuoteId + ";domain=;path=/";
                        // this.$session.set("QuoteId", response.data.QuoteId);
                        this.AddtoShoppingCart(this.products,quantity)
                        this.$router.push({ path: '/basket/' });
                    }else{
                        document.getElementById('basket_nav_icon').style.display='';
                        document.getElementById('BASKET_FOOTER').style.display='';
                        alert("Something went Wrong!");
                    }
                })
            }
            else{
                
                document.getElementById('error-box-color-dialog').style.display="block";
                // document.getElementById('error-dialog').innerHTML = pop_up_str+" is not selected";
                document.getElementById('error-dialog').innerHTML = category_Checked+" has not been chosen.";

                    return;
            }
        },
        AddtoShoppingCart(productObj,quantity){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'AED',
                    'add': {                                // 'add' actionFieldObject measures.
                    'products': [{                        //  adding a product to a shopping cart.
                        'name': productObj.name,         // Name or ID is required.
                        'id': productObj.id,
                        'price': productObj.webshop_price,
                        'category':  this.category,
                        'variant': productObj.product_variants,
                        'quantity': quantity
                    }]
                    }
                }
            });
        },
        changeIt(mini_image){
            this.loading = true;
            var minified_image_src = document.getElementById('mini_'+mini_image);
            var preview_img = document.getElementById('preview_img');
            preview_img.src = minified_image_src.src;
            this.loading = false;
        },
        leftclick(){
            document.getElementById('slider').scrollLeft -= 122;
        },
        rightclick(){
            document.getElementById('slider').scrollLeft += 122;
        }
    }
}
</script>

<style scoped>
.thumbnail{
	object-fit: cover;
	max-width: 180px;
	max-height: 140px;
	cursor: pointer;
	opacity: 0.5;
	margin: 5px;
}
.thumbnail:hover{
	opacity:1;
}
#slide-wrapper{
    width: 515px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    display: flex;
}
#slider{
    width: 584px;
    overflow-x: hidden;
    display: flex;
    scroll-behavior: smooth;
}
.arrow{
	width: 30px;
	height: 30px;
	cursor: pointer;
	transition: .3s;
    margin-top: 50px;
}

.add_to_cart:hover{
    cursor: pointer;
}
.disabled_today{
    background-color: lightgray !important;
}
.product-details-sec ol.breadcrumbs li span {
    font-size: 16px !important;
    line-height: 30px !important;
    color: #6f6f6f !important;
}
</style>