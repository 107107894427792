<template>
	<div>
      <!-- spinner -->
      <div v-if="loading">
         <SpinnerData />
      </div>
      <!-- spinner end -->
		<div id="BASKET_COMPONENT" class="main_component_basket">
			<div class="cart-table-main-sec" style="">
				<div class="main_section_title main_section_title_desktop_only desktop_only" style="text-align: center;">
				Shopping Cart
				</div>
				<div class="main_div_3p1jd">
				<div  class="main_div_shop_product_class">
					<div id="maindivshopproduct_7" class="maindivshopproduct_7_class cart-left-sec">
						<div class="shopping_cart_main_div">
							<div class="main_section_title mobile_only" style="text-align: center;">
							Shopping Cart
							</div>
							<div id="cart-details-error" style="display:none;text-align:center;">
							<h1 style="color:red;font-size: x-large;">No Product In your Cart.</h1>
							</div>
							<div v-for="cartItems in basketcartItems" :key="cartItems.item_id" class="order_detais_component" style="display: flex;flex-direction: row;margin-top: 0;padding-bottom: 30px;border-bottom: 1px solid #B4B4B4;padding-top: 30px;align-items: center;">
							<div style="display: flex;flex-direction: column;justify-content: center;" class="cart-img">
								<img class="external_class_element lazyload highlighted_product_image" alt="image" data-src="https://storage.googleapis.com/static.happybox-testing.com/images/images/8eaf55d1-0ff7-4cfe-ac16-24d62cb2309d_1_1_low_quality.jpg" :src="'/uploads/products/original/' + cartItems.base_image">
							</div>
							<div id="order_detail_order_detail_quantity53362" style="width: min-content;" class="cart-product-main-info">
								<div class="basket_order_detail_product_name">
									<div class="truncated_order_detail_product_name"> 
										{{cartItems.product_name}}
									</div>
									<div class="product_options_main_div_first_inner">
										<p>{{cartItems.product_variants}}</p>
									</div>
									<div class="basket_price_div">
										<div>
										Price:
										</div>
										<div class="price_div_apply_discount_component">
										<div :id="'original_price_'+`${cartItems.item_id}`" class='original_price cart-price'>AED {{cartItems.price}}</div>
										</div>
									</div>
								</div>
							</div>
							<div id="order_detail_order_detail_quantity53362" class="cart-product-main-quantity">
								<div class="div_order_detail_quantity_plus_minus">
									<div class="plus_minus_basket_class" :id="'decrementbutton_'+`${cartItems.item_id}`" v-on:click="getDecrementCartItem(`${cartItems.item_id}`,`${cartItems.qty_ordered}`,`${cartItems.quote_id}`,`${cartItems.product_name}`,`${cartItems.price}`,`${cartItems.qty_ordered}`)">
										-
									</div>
									<div style="height: 100%;color: #B17830;border-right: 1px solid #f98c79;"></div>
									<div :id="'quantity_'+`${cartItems.item_id}`" class="order_detail_product_quantity cart-quantity-input">
										{{cartItems.qty_ordered}}
									</div>
									<div style="height: 100%;color: #B4B4B4;border-right: 1px solid #f98c79;"></div>
									<div class="plus_minus_basket_class" :id="'incrementbutton_'+`${cartItems.item_id}`" v-on:click="getIncrementCartItem(`${cartItems.item_id}`,`${cartItems.qty_ordered}`,`${cartItems.quote_id}`)" >
										+
									</div>
								</div>
								<div class="order_detail_item_total_amount">
									<div>
										Total:
									</div>
									<div class="order_detail_item_total_amount_discounted_record">
										<div id="total_price_calculated53362">
										<div :id="'total-price-'+`${cartItems.item_id}`" class='original_price'>AED {{cartItems.total_price}}</div>
										</div>
									</div>
								</div>
								<!-- remove product from cart -->
								<!-- <div > -->
								<button v-on:click="setremoveProduct(`${cartItems.item_id}`,`${cartItems.quote_id}`,`${cartItems.product_name}`,`${cartItems.price}`,`${cartItems.qty_ordered}`)" class="remove_product_from_cart"></button>
								<!-- </div> -->
							</div>
							</div>
							<div class="add-item-sec" style="margin: auto;">
                        <router-link to="/">   
                           <button style="padding: 10px 30px !important;color: black;background-color: transparent;display: inline-block;margin-bottom: 60px;font-size: 19px;font-weight: 400;line-height: 1.42857143;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;user-select: none;background-image: none;border: 1px solid #4cae4c;min-height: 5vh;width: 29%;margin-top: 3em;">
                           Add Items
                           </button>
                        </router-link>
							</div>
						</div>
					</div>
					<div class="main_div_basket_grand_total" id="main_div_grand_total" style="display:none;">
						<div class="div_basket_grand_total">
							<div class="total-add-ons">
							<div class="select-add-ons" id ="select-add-ons">
								<h2>Select Your Add-Ons</h2>
								<div class="add-on-list" id="action-add-on-list" style="display:block;">
									<div class="add-on-des-main">
										<div class="add-on-img"><img alt="" src="/assets/img/card-img.png"></div>
										<div class="add-on-des">
										<h4>Add Your Greeting Card</h4>
										<span class="card-price">AED 19.00</span>
										</div>
									</div>
									<div class="add-on-btn-sec">
										<button class="skip-add-on-button" v-on:click="skip_add_on()">Skip Add On</button>
										<button class="add-on-card-button" id="add_on_card" v-on:click="toogle_add_on()">Yes, Add on card</button>
									</div>
								</div>
							</div>
							<div class="msg-add-ons" id ="msg-add-ons" style="display:none;">
								<h2>Write Your Message</h2>
								<div class="msg-ad-on-form">
									<!--<div id="receiver_name_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div class="input-field">
										<label>Receiver's name <span style="color: red;font-size: 25px !important;">*</span></label>
										<input type="text" id="receiver_name" placeholder="Name">
									</div>-->
									<div id="sender_message_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div class="input-field">
										<label>Enter your message <span style="color: red;font-size: 25px !important;">*</span></label>
										<textarea  id="sender_message" placeholder="Message"></textarea>
									</div>
									<!--<div id="sender_name_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div class="input-field">
										<label>Sender's name <span style="color: red;font-size: 25px !important;">*</span></label>
										<input type="text" id="sender_name" placeholder="Name">
									</div>-->
								</div>
							</div>
							</div>
							<div class="checkout_delivery_fee_sub_total_div subtol_div_design" id="shopping-basket-border">
							<div>Sub Total:
							</div>
							<div style="font-weight: normal;" class="grand_total">
								<div class='original_price ' id="base_sub_total">AED {{basketcartDetails.base_subtotal}}</div>
							</div>
							</div>
							<div class="checkout_delivery_fee_sub_total_div">
							<div>Delivery Fee:
							</div>
							<div style="font-weight: normal;">
								AED {{basketcartDetails.shipping_amount}}
							</div>
							</div>
							<div id="greeting_card_fee_div" class="checkout_delivery_fee_sub_total_div" style="display:none;">
							<div>Greeting Card:
							</div>
							<div style="font-weight: normal;">
								AED 19.00
							</div>
							</div>
                     <div class="checkout_delivery_fee_sub_total_div" >
							<div>VAT(+5%):
							</div>
							<div style="font-weight: normal;" id="vat_amount">
								AED {{basketcartDetails.vat}}
							</div>
							</div>
							<div id="" class="sub_div_basket_grand_total">
							<div class="grand_total_div_text">
								<div>
									Grand Total
								</div>
							</div>
							<div>
								<div class="total_price_calculated">
									<!-- <div id="grand-total-price" class='original_price'>AED {{(parseFloat(basketcartDetails.grand_total) + parseFloat(basketcartDetails.shipping_amount) + parseFloat(basketcartDetails.vat)).toFixed(2)}}</div> -->
									<div id="grand-total-price" class='original_price'></div>
								</div>
							</div>
							</div>
							<div id="basket_proceed_button" class="desktop_only">
							<button id="proceed_to_checkout" v-on:click="setProceedcheckout" style="width: 100%;color: rgb(255, 255, 255);display: inline-block;padding: 6px 12px;margin-bottom: 0px;font-size: 16px;font-weight: bold;line-height: 1.42857;text-align: center;white-space: nowrap;vertical-align: middle;touch-action: manipulation;cursor: pointer;user-select: none;background-image: none;border: 1px solid #75cc60;min-height: 45px;font-family: Conv_ProximaNova-Regular,sans-serif!important;background-color: #75cc60;border-radius: 0;">
							Proceed to checkout
							</button>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
			<!-- Success Messages start -->
			<div id="success-dialog-greeting" style="display: none;" class="dialog-main">
				<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
				<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
					<div style="margin:0 auto;">
						<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
							{{message}}
							</ul>
						</div>
						<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog-greeting'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
					</div>
				</div>
				</div>
				<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
			</div>
			<!-- success Messages End -->
			<!-- dynamic box message-->
			<div id="error-dialog" style="display: none;" class="dialog-main">
				<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="" >
				<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
					<div style="margin:0 auto;">
						<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
							{{message}}
							</ul>
						</div>
						<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
					</div>
				</div>
				</div>
				<div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
			</div>
         <!-- greeting message error start -->
         <div id="greeting-error-dialog" style="display: none;" class="dialog-main">
				<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="" >
				<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
					<div style="margin:0 auto;">
						<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
							Unable to add greeting card message, please check copy and characters added.
							</ul>
						</div>
						<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('greeting-error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
					</div>
				</div>
				</div>
				<div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
			</div>
         <!-- greeting message error end -->
			<!-- dynamic box message-->
			<div id="error-dialog-dynamic" style="display: none;" class="dialog-main">
				<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
				<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
					<div style="margin:0 auto;">
						<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
							Please Login or Register First.
							</ul>
						</div>
						<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog-dynamic'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
					</div>
				</div>
				</div>
				<div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
			</div>
			<!-- static box message-->
			<div id="error-no-product-greeting-card-dialog" style="display: none;" class="dialog-main">
				<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
				<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
					<div style="margin:0 auto;">
						<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
						<div class="">
							<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
							No Product In your Cart.  
							</ul>
						</div>
						<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-no-product-greeting-card-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
					</div>
				</div>
				</div>
				<div class="" style="width: 100vw;z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: 100vh;" open=""></div>
			</div>
			<div class="our_recommendations_class_main">
				<div class="our_recommendations_class"> 
				Products that you may like
				</div>
				<div class="main-list-of-items">
					<div id="products_main_div" class="main-list-of-items-first-inner">
						<div v-for="product in products" :key="product.id" class="main-list-of-items-zero-inner">
							<div class="main-list-of-items---item-first-inner">
							</div>
							<div class="main-list-of-items---item-first-inner">
								<div>
									<router-link :to="'/product/' + product.url_key">   
										<img class="external_class_element lazyload highlighted_product_image" :alt="product.name"  :src="'/uploads/products/thumb/' + product.base_image">
									</router-link>
								</div>
							</div>
							<div class="main-list-of-items---item-first-inner">
								<div>
									<div class="wrapped_text product_name product_product_name">
										{{product.name}}
									</div>
								</div>
								<div>
									<div style="display: flex; flex-direction: column;width: min-content;">
										<div style="display: flex; flex-direction: column;width: min-content;">
											<div class='original_price product_price' v-if="product.product_type === 'simple'">AED {{product.price}}</div>
											<div class='original_price product_price' v-else-if="product.product_type === 'configurable'">AED {{product.min_price}} - AED {{product.max_price}}</div>
										</div>
											
										<div style="display: flex; flex-direction: column;width: min-content;">
										<!--   <div class='original_price product_price'>AED {{product.min_price}}</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios';
   import SpinnerData from './SpinnerData.vue';
	export default {
		name: 'ShoppingBasket',
      components: { 
        SpinnerData
      },
      metaInfo(){
        return {
            titleTemplate:'Shopping Cart | %s',
            htmlAttrs: {
                lang: 'en-US'                  
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Shopping Cart' },
                { name: 'keywords', content: 'Shopping Cart' }
            ]
        }
      },
		data(){
			return{
				basketcartDetails:'',
				basketcartItems:'',
				noData:'',
				Product_varients:'',
				varientName:'',
				message:'',
				VAT:'',
				greeting_add:'',
				products:'',
            loading: false,
			}
		},
		mounted(){
         window.scrollTo(0, 0);
			this.$session.start();
			this.getBasketDetails();
			this.getBestseller();
		},
		methods:{
			toogle_add_on(){
				var x = document.getElementById("msg-add-ons");
				const greeting_add_on = 19.00;
				if (x.style.display === "none") {
					var customer_id = this.$session.get("LoginID");
					var session_id = this.$cookie.get('device');
					var quote_id = '';
               
               if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
               {
                  quote_id = '';
               }
               else
               {
                  quote_id = this.$cookie.get('QuoteId');
               }
					// if(this.$session.has("QuoteId") === false)
					// {
					// 	quote_id = '';
					// }
					// else
					// {
					// 	quote_id = this.$session.get("QuoteId");
					// }
					axios.post('/webapi/webshop/cart_listing',{
						quote_id: quote_id,
						customer_id: customer_id,
						session_id: session_id,
					})
					.then(response => {
						if(response.data.statusCode === 200 || response.data.statusCode === '200'){
							x.style.display = "block";
							if(document.getElementById("msg-add-ons").style.display === 'block'){
								document.getElementById('greeting_card_fee_div').style.display = 'flex';
								var grand_total_price = parseFloat(document.getElementById('grand-total-price').innerText.replace('AED', ''));
								var base_subtotal = parseFloat(document.getElementById('base_sub_total').innerText.replace('AED', '')).toFixed(2);
								var grand_total_greeting = grand_total_price + greeting_add_on;
								document.getElementById('grand-total-price').innerText = 'AED' +' '+ grand_total_greeting;
                        document.getElementById("action-add-on-list").style.display = "none";
							}
						}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
							document.getElementById('error-no-product-greeting-card-dialog').style.display='block';
						}
					})
				}
			},
			skip_add_on(){
				var shopping_basket_border = document.getElementById('shopping-basket-border');
				shopping_basket_border.classList.remove('subtol_div_design');
				var y = document.getElementById("select-add-ons");
				const greeting_add_on = 19.00;
				y.style.display = "none";
				var x = document.getElementById("msg-add-ons");
				if (x.style.display === "block") {
					var quote_id = '';
					if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
               {
                  quote_id = '';
               }
               else
               {
                  quote_id = this.$cookie.get('QuoteId');
               }
					// if(this.$session.has("QuoteId") === false)
					// {
					// 	quote_id = '';
					// }
					// else
					// {
					// 	quote_id = this.$session.get("QuoteId");
					// }
					axios.post('/webapi/webshop/remove_greeting',{
						quote_id:quote_id,
					}).then(response =>{
						if(response.data.statusCode === 200 || response.data.statusCode === '200'){
							document.getElementById('greeting_card_fee_div').style.display = 'none';
							var grand_total_price = parseFloat(document.getElementById('grand-total-price').innerText.replace('AED', ''));
							var base_subtotal = parseFloat(document.getElementById('base_sub_total').innerText.replace('AED', '')).toFixed(2);
							var grand_total_greeting = (parseFloat(grand_total_price) - parseFloat(greeting_add_on)).toFixed(2);
							document.getElementById('grand-total-price').innerText = 'AED' +' '+ grand_total_greeting;
						}
						
					})
				x.style.display = "none";
				}
			},
			getBasketDetails(){
				this.loading = true;
				var customer_id = this.$session.get("LoginID");
				var session_id = this.$cookie.get('device');
				var quote_id = '';
				if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
            {
               quote_id = '';
            }
            else
            {
               quote_id = this.$cookie.get('QuoteId');
            }
            // if(this.$session.has("QuoteId") === false)
            // {
            // 	quote_id = '';
            // }
            // else
            // {
            // 	quote_id = this.$session.get("QuoteId");
            // }
				axios.post('/webapi/webshop/cart_listing',{
					quote_id: quote_id,
					customer_id: customer_id,
					session_id: session_id,
				})
				.then(response => {
					if(response.data.statusCode === 200 || response.data.statusCode === '200'){
						this.basketcartDetails = response.data.cartData.cartDetails;
						this.basketcartItems= response.data.cartData.cartItems;
						this.greeting_add = response.data.cartData.greeting_details;
                  localStorage.cartNotEmpty = true;
						document.getElementById('main_div_grand_total').style.display = 'block';
						document.getElementById('grand-total-price').innerText = 'AED'+' '+(parseFloat(this.basketcartDetails.grand_total) + parseFloat(this.basketcartDetails.shipping_amount) + parseFloat(this.basketcartDetails.vat)).toFixed(2);
						if(this.greeting_add !== undefined){
                     this.toogle_add_on();
                     document.getElementById('sender_message').value = this.$session.get('greeting_content');
						}else{
                      document.getElementById('greeting_card_fee_div').style.display = 'none';
                  }
                  this.loading = false;
                  // console.log("Price NaN");
					}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
						this.message = response.data.message;
						document.getElementById('grand-total-price').innerText = 'AED';
						document.getElementById('cart-details-error').style.display='block';
						document.getElementById('proceed_to_checkout').disabled = true;
                  this.loading = false;
                  // console.log("Price NaN");
					}
				})
			},
			getBestseller(){
				axios
					.post("/webapi/webshop/product_listing",{
					categoryslug: "bestseller",
					})
					.then(response => {
					this.products = response.data.ProductList.slice(0, 4);
					// console.log("best seller:",this.products);
				})
			},
			setProceedcheckout(){
				var greeting_content = document.getElementById('sender_message');
				var quote_id = '';
				if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
            {
               quote_id = '';
            }
            else
            {
               quote_id = this.$cookie.get('QuoteId');
            }
            // if(this.$session.has("QuoteId") === false)
            // {
            // 	quote_id = '';
            // }
            // else
            // {
            // 	quote_id = this.$session.get("QuoteId");
            // }
				if(this.$session.has("LoginID") === false){
               if(document.getElementById('msg-add-ons').style.display === 'block'){
						if (greeting_content.value.length == 0 || greeting_content.value == null) {
							this.enableOrDisableErrorMessage('sender_message_error', 'Please enter all mandatory Fields.', true);
							return;
						}
						this.$session.set('greeting_content',greeting_content.value);
						axios.post('/webapi/webshop/add_greeting',{
							greeting_content: greeting_content.value,
							quote_id: quote_id
						}).then(response => {
							if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                        // this.message = response.data.message
								// document.getElementById('success-dialog').style.display ='block';
                        this.$router.push({ path: '/login/step2' });
							}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
								document.getElementById('greeting-error-dialog').style.display ='block';
							}
						})
					}else{
                  this.$router.push({ path: '/login/step2' });
               } 
				}else{
	
					if(document.getElementById('msg-add-ons').style.display === 'block'){
						if (greeting_content.value.length == 0 || greeting_content.value == null) {
							this.enableOrDisableErrorMessage('sender_message_error', 'Please enter all mandatory Fields.', true);
							return;
						}
						this.$session.set('greeting_content',greeting_content.value);
						axios.post('/webapi/webshop/add_greeting',{
							greeting_content: greeting_content.value,
							quote_id: quote_id
						}).then(response => {
							// console.log("add_greeting API :",response.data);
							if(response.data.statusCode === 200 || response.data.statusCode === '200'){
								// this.message = response.data.message
								// document.getElementById('success-dialog').style.display ='block';
                        this.$router.push({ path: '/checkout/step1' });
							}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
								// this.message = response.data.message
								document.getElementById('greeting-error-dialog').style.display ='block';
							}
						})
					}else{
                  this.$router.push({ path: '/checkout/step1' });
               }
				}
			},
			setremoveProduct(item_id, quote_id,product_name,product_price,product_quantity){
				var itemid = item_id;
				var quoteid = quote_id;
            var name = product_name;
            var price = product_price;
            var quantity = product_quantity;
            this.RemovefromCart(itemid,name,price,quantity);
				axios.post('/webapi/webshop/remove_cart_item',{
					item_id: itemid,
					quote_id: quote_id
				}).then(response => {
					if(response.data.statusCode === 200 || response.data.statusCode === '200'){
						this.message = response.data.message
						document.getElementById('error-dialog').style.display ='block';
						location.reload();
					}else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
						this.message = response.data.message
						document.getElementById('error-dialog').style.display ='block';
					}
				})
			},
			getDecrementCartItem(item_id, qty_ordered, quote_id,product_name,product_price,product_quantity){
				var session_id = this.$cookie.get('device');
				var quoteid = quote_id;
				var cart_item_id = item_id;
            var name = product_name;
            var price = product_price;
            var quantity = product_quantity;
				var qty = document.getElementById('quantity_'+cart_item_id).innerHTML-1;
				
				document.getElementById('decrementbutton_'+cart_item_id).style.pointerEvents = 'none';
				if(qty >= 1)
				{
               this.RemovefromCart(cart_item_id,name,price,quantity);
					axios.post('/webapi/webshop/update_cart_item',{
						session_id: session_id,
						quote_id: quoteid,
						cart_item_id: cart_item_id,
						qty: qty,
					}).then(response => {
						if(response.data.statusCode === 200 || response.data.statusCode === '200'){
							document.getElementById('decrementbutton_'+cart_item_id).style.pointerEvents = 'auto';
							document.getElementById('quantity_'+cart_item_id).innerHTML= qty;
							this.getupdateCartTotal(item_id);
						}
						
					})
				}else{
               this.RemovefromCart(cart_item_id,name,price,quantity);
					axios.post('/webapi/webshop/remove_cart_item',{
					item_id: cart_item_id,
					quote_id: quoteid
					}).then(response => {
						if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                     
							this.message = response.data.message
							document.getElementById('error-dialog').style.display ='block';
							location.reload();
						}
					})
				}
			},
			getIncrementCartItem(item_id, qty_ordered, quote_id){
				var session_id = this.$cookie.get('device');
				var quoteid = quote_id;
				var cart_item_id = item_id;
				var qty = parseInt(document.getElementById('quantity_'+cart_item_id).innerHTML)+1;
				//console.log(session_id , quoteid, cart_item_id, qty);
				document.getElementById('incrementbutton_'+cart_item_id).style.pointerEvents = 'none';
				axios.post('/webapi/webshop/update_cart_item',{
					session_id: session_id,
					quote_id: quote_id,
					cart_item_id: cart_item_id,
					qty: qty,
				}).then(response => {
					if(response.data.statusCode === 200 || response.data.statusCode === '200'){
						document.getElementById('incrementbutton_'+cart_item_id).style.pointerEvents = 'auto';
						document.getElementById('quantity_'+cart_item_id).innerHTML= qty;
						this.getupdateCartTotal(item_id,'add');
					}
				})
			},
			getupdateCartTotal(item_id,operator =false){
				this.VAT = 0.05; 
				const Delivery_Charge = 29.00;
				const Greeting_Add_on = 19.00;
				var base_subtotal = parseFloat(document.getElementById('base_sub_total').innerText.replace('AED', '')).toFixed(2);
				var tax  = base_subtotal  *  this.VAT ;
				var total_base_amount = base_subtotal + tax;
				var original_price = parseFloat(document.getElementById('original_price_'+item_id).innerText.replace('AED', ''));
				var quantity = parseInt(document.getElementById('quantity_'+item_id).innerHTML);
				var total_price = parseFloat(document.getElementById('total-price-'+item_id).innerText.replace('AED', ''));
				var grand_total_price = parseFloat(document.getElementById('grand-total-price').innerText.replace('AED', ''));
				var new_cart_price = (original_price*quantity);
				var round_new_cart_price = new_cart_price.toFixed(2);
				var grand_total = 0;
				if(operator == 'add'){
					grand_total = parseFloat(base_subtotal) + parseFloat(original_price);
				}
				else{
					grand_total = base_subtotal - original_price ;
				}
				// console.log(grand_total);
				var round_grand_total = grand_total.toFixed(2);
				var vat_amount = (parseFloat(round_grand_total) * parseFloat(this.VAT)).toFixed(2);
				var great_grand_total = 0;
				if(document.getElementById("msg-add-ons").style.display === 'block'){
					great_grand_total = (parseFloat(round_grand_total) + parseFloat(Delivery_Charge) + parseFloat(vat_amount) + parseFloat(Greeting_Add_on)).toFixed(2);
				}else{
					great_grand_total = (parseFloat(round_grand_total) + parseFloat(Delivery_Charge) + parseFloat(vat_amount)).toFixed(2);
				}
				document.getElementById('total-price-'+item_id).innerText = 'AED' +' '+ round_new_cart_price;
				document.getElementById('grand-total-price').innerText = 'AED' +' '+ great_grand_total;
				document.getElementById('base_sub_total').innerText = 'AED' +' '+ round_grand_total;
				document.getElementById('vat_amount').innerText = 'AED' +' '+ vat_amount;
			},
         RemovefromCart(id,name,price,quantity){
            var dataLayer = window.dataLayer || [];
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            'event': 'removeFromCart',
            'ecommerce': {
               'currencyCode': 'AED',
               'remove': {                               // 'remove' actionFieldObject measures.
                  'products': [{                          //  removing a product to a shopping cart.
                     'name': name,
                     'id': id,
                     'price': price,
                     'quantity': quantity
                  }]
               }
            }
            });
         },
			enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
				var mainElement = document.getElementById(idOfTheMainElement);
				if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
					var mainElement_first_children = mainElement.firstElementChild;
					if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
						mainElement_first_children.innerHTML = messageText;
						if (!disableFlag) {
							mainElement.style.display = "none";
						} else {
							mainElement.style.display = "block";
						}
					}
				}
			},
			checkIfObjectIsNotUndefiendOrNull(obj) {
				if (obj === undefined || obj === null) {
					return true;
				} else {
					return false;
				}
			}
			
		}
	}
</script>
<style scoped>
   .subtol_div_design{
   margin-top: 25px;
   border-top: 2px solid darkgray;
   padding-top: 21px;
   }
   .order_detail_item_total_amount_discounted_record {
   padding-left: .3em
   }
   .order_detail_item_total_amount {
   font-family: Arial;
   display: flex;
   flex-direction: row;
   justify-content: center
   }
   @media(max-width:599px) {
   .order_detail_item_total_amount {
   font-size: 14px;
   padding-top: .5em
   }
   }
   @media(min-width:600px) {
   .order_detail_item_total_amount {
   font-size: 18px
   }
   }
   @media(min-width:900px) {
   .order_detail_item_total_amount {
   font-size: 18px
   }
   }
   @media(min-width:1200px) {
   .order_detail_item_total_amount {
   font-size: 18px
   }
   }
   @media(min-width:1800px) {
   .order_detail_item_total_amount {
   font-size: 18px
   }
   }
   .order_detail_product_quantity {
   color: #2f1317
   }
   @media(max-width:599px) {
   .order_detail_product_quantity {
   font-size: 13px;
   padding-top: .8em;
   width: 3em;
   height: 2em
   }
   }
   @media(min-width:600px) {
   .order_detail_product_quantity {
   font-size: 17px;
   padding-top: 20px;
   width: 4em;
   height: 3em
   }
   }
   @media(min-width:900px) {
   .order_detail_product_quantity {
   font-size: 17px;
   padding-top: 20px;
   width: 4em;
   height: 3em
   }
   }
   @media(min-width:1200px) {
   .order_detail_product_quantity {
   font-size: 17px;
   padding-top: 20px;
   width: 4em;
   height: 3em
   }
   }
   @media(min-width:1800px) {
   .order_detail_product_quantity {
   font-size: 17px;
   padding-top: 20px;
   width: 4em;
   height: 3em
   }
   }
   .div_order_detail_quantity_plus_minus {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   border: 1px solid #b4b4b4;
   font-family: 'proxima-nova', sans-serif !important;
   border-radius: .5em
   }
   @media(max-width:599px) {
   .div_order_detail_quantity_plus_minus {
   height: 42px
   }
   }
   @media(min-width:600px) {
   .div_order_detail_quantity_plus_minus {
   height: 56px
   }
   }
   @media(min-width:900px) {
   .div_order_detail_quantity_plus_minus {
   height: 56px
   }
   }
   @media(min-width:1200px) {
   .div_order_detail_quantity_plus_minus {
   height: 56px
   }
   }
   @media(min-width:1800px) {
   .div_order_detail_quantity_plus_minus {
   height: 56px
   }
   }
   .plus_minus_basket_class {
   color: #b4b4b4
   }
   @media(max-width:599px) {
   .plus_minus_basket_class {
   padding-top: .5em;
   font-size: 18px;
   width: 2em
   }
   }
   @media(min-width:600px) {
   .plus_minus_basket_class {
   padding-top: 14px;
   font-size: 29px;
   width: 63px
   }
   }
   @media(min-width:900px) {
   .plus_minus_basket_class {
   padding-top: 14px;
   font-size: 29px;
   width: 63px
   }
   }
   @media(min-width:1200px) {
   .plus_minus_basket_class {
   padding-top: 14px;
   font-size: 29px;
   width: 63px
   }
   }
   @media(min-width:1800px) {
   .plus_minus_basket_class {
   padding-top: 14px;
   font-size: 29px;
   width: 63px
   }
   }
   .basket_order_detail_product_name {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   border-bottom: 0;
   font-family: 'proxima-nova', sans-serif !important;
   height: min-content;
   margin-left: 1em;
   width: 9em;
   text-align: left
   }
   @media(min-width:600px) {
   .basket_order_detail_product_name {
   font-size: 18px
   }
   }
   @media(min-width:900px) {
   .basket_order_detail_product_name {
   font-size: 18px
   }
   }
   @media(min-width:1200px) {
   .basket_order_detail_product_name {
   font-size: 18px
   }
   }
   @media(min-width:1800px) {
   .basket_order_detail_product_name {
   font-size: 18px
   }
   }
   @media(min-width:600px) {
   .product_product_name {
   font-size: 22px !important
   }
   }
   @media(min-width:900px) {
   .product_product_name {
   font-size: 22px !important
   }
   }
   @media(min-width:1200px) {
   .product_product_name {
   font-size: 22px !important
   }
   }
   @media(min-width:1800px) {
   .product_product_name {
   font-size: 22px !important
   }
   }
   .basket_price_div {
   font-family: Arial;
   padding-top: .5em;
   display: flex;
   flex-direction: row;
   justify-content: left
   }
   @media(max-width:599px) {
   .basket_price_div {
   font-size: 14px
   }
   }
   .price_div_apply_discount_component {
   font-family: Arial;
   padding-left: .3em
   }
   @media(max-width:599px) {
   .price_div_apply_discount_component {
   font-size: 14px
   }
   }
   .truncated_order_detail_product_name {
   color: #2f1317;
   font-family: 'proxima-nova', sans-serif !important;
   font-weight: bold !important;
   text-align: left;
   white-space: initial;
   text-overflow: ellipsis;
   overflow: hidden;
   display: inherit
   }
   @media(max-width:599px) {
   .truncated_order_detail_product_name {
   font-size: 14px
   }
   }
   @media(max-width:599px) {
   .main_div_3p1jd {
   width: 100%
   }
   }
   @media(min-width:600px) {
   .main_div_3p1jd {
   width: 100%;
   min-height: 40vh;
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:900px) {
   .main_div_3p1jd {
   width: 100%;
   min-height: 40vh;
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:1200px) {
   .main_div_3p1jd {
   width: 100%;
   min-height: 40vh;
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:1800px) {
   .main_div_3p1jd {
   width: 100%;
   min-height: 40vh;
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:600px) {
   .maindivshopproduct_7_class {
   width: 60vw
   }
   }
   @media(min-width:900px) {
   .maindivshopproduct_7_class {
   width: 100vw
   }
   }
   @media(min-width:1200px) {
   .maindivshopproduct_7_class {
   width: 70vw !important
   }
   }
   @media(min-width:1800px) {
   .maindivshopproduct_7_class {
   width: 100vw
   }
   }
   @media(min-width:600px) {
   .main_section_title_desktop_only {
   text-align: center;
   margin-top: 3em;
   margin-bottom: 2em;
   font-size: 35px !important
   }
   }
   @media(min-width:900px) {
   .main_section_title_desktop_only {
   text-align: center;
   margin-top: 3em;
   margin-bottom: 2em;
   font-size: 35px !important
   }
   }
   @media(min-width:1200px) {
   .main_section_title_desktop_only {
   text-align: center;
   margin-top: 3em;
   margin-bottom: 2em;
   font-size: 35px !important
   }
   }
   @media(min-width:1800px) {
   .main_section_title_desktop_only {
   text-align: center;
   margin-top: 3em;
   margin-bottom: 2em;
   font-size: 35px !important
   }
   }
   .grand_total_div_text {
   display: flex;
   flex-direction: row;
   justify-content: left;
   width: 16em;
   color: #2f1317
   }
   @media(min-width:600px) {
   .grand_total_div_text {
   width: min-content;
   min-width: 199px
   }
   }
   @media(min-width:900px) {
   .grand_total_div_text {
   width: min-content;
   min-width: 199px
   }
   }
   @media(min-width:1200px) {
   .grand_total_div_text {
   width: min-content;
   min-width: 199px
   }
   }
   @media(min-width:1800px) {
   .grand_total_div_text {
   width: min-content;
   min-width: 199px
   }
   }
   .sub_div_basket_grand_total {
   height: 3em;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 0;
   font-family: Arial;
   padding-left: .3em;
   font-family: 'proxima-nova', sans-serif !important
   }
   @media(max-width:599px) {
   .sub_div_basket_grand_total {
   font-size: 14px;
   height: 1em;
   padding-top: 1.2em;
   margin-left: 1.5em;
   margin-right: 1.5em
   }
   }
   @media(min-width:600px) {
   .sub_div_basket_grand_total {
   font-size: 28px;
   padding-top: 0
   }
   }
   @media(min-width:900px) {
   .sub_div_basket_grand_total {
   font-size: 28px;
   padding-top: 0
   }
   }
   @media(min-width:1200px) {
   .sub_div_basket_grand_total {
   font-size: 28px;
   padding-top: 0
   }
   }
   @media(min-width:1800px) {
   .sub_div_basket_grand_total {
   font-size: 28px;
   padding-top: 0
   }
   }
   @media(min-width:600px) {
   .main_div_shop_product_class {
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:900px) {
   .main_div_shop_product_class {
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:1200px) {
   .main_div_shop_product_class {
   display: flex;
   flex-direction: row
   }
   }
   @media(min-width:1800px) {
   .main_div_shop_product_class {
   display: flex;
   flex-direction: row
   }
   }
   @media(max-width:599px) {
   .shopping_cart_main_div {
   display: flex;
   flex-direction: column
   }
   }
   @media(min-width:600px) {
   .shopping_cart_main_div {
   width: 83%;
   margin: auto
   }
   }
   @media(min-width:900px) {
   .shopping_cart_main_div {
   width: 83%;
   margin: auto
   }
   }
   @media(min-width:1200px) {
   .shopping_cart_main_div {
   width: 83%;
   margin: auto
   }
   }
   @media(min-width:1800px) {
   .shopping_cart_main_div {
   width: 83%;
   margin: auto
   }
   }
   @media(min-width:600px) {
   .main_div_basket_grand_total {
   max-height: 300px;
   width: 30vw;
   border: 1px solid #b4b4b4
   }
   }
   @media(min-width:900px) {
   .main_div_basket_grand_total {
   max-height: 300px;
   width: 30vw;
   border: 1px solid #b4b4b4
   }
   }
   @media(min-width:1200px) {
   .main_div_basket_grand_total {
   max-height: 300px;
   width: 25vw;
   border: 1px solid #b4b4b4
   }
   }
   @media(min-width:1800px) {
   .main_div_basket_grand_total {
   max-height: 300px;
   width: 30vw;
   border: 1px solid #b4b4b4
   }
   }
   @media(max-width:599px) {
   .div_basket_grand_total {
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-bottom: 0
   }
   }
   @media(min-width:600px) {
   .div_basket_grand_total {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin-bottom: 0;
   height: 100%;
   margin: 1em
   }
   }
   @media(min-width:900px) {
   .div_basket_grand_total {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin-bottom: 0;
   height: 100%;
   margin: 1em
   }
   }
   @media(min-width:1200px) {
   .div_basket_grand_total {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin-bottom: 0;
   height: 100%;
   margin: 1em
   }
   }
   @media(min-width:1800px) {
   .div_basket_grand_total {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin-bottom: 0;
   height: 100%;
   margin: 1em
   }
   }
   @media(max-width:599px) {
   .external_footer_bar_first_div {
   height: 3em;
   padding-top: 1em
   }
   }
   @media(min-width:600px) {
   .external_footer_bar_first_div {
   width: 30vw
   }
   }
   @media(min-width:900px) {
   .external_footer_bar_first_div {
   width: 30vw
   }
   }
   @media(min-width:1200px) {
   .external_footer_bar_first_div {
   width: 30vw
   }
   }
   @media(min-width:1800px) {
   .external_footer_bar_first_div {
   width: 30vw
   }
   }
   .basket_promoted_product {
   width: 100%;
   height: 100%;
   object-fit: fill;
   margin-top: 0;
   top: 0;
   max-width: 23em;
   border: 2px solid #b17830
   }
   .main_basket_div {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(100, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
   margin-bottom: .5em;
   margin: auto
   }
   @media(max-width:599px) {
   .main_basket_div {
   width: 100vw;
   margin-top: 1em
   }
   }
   @media(min-width:600px) {
   .main_basket_div {
   width: 80vw;
   margin-top: 1em
   }
   }
   @media(min-width:900px) {
   .main_basket_div {
   width: 80vw;
   margin-top: 1em
   }
   }
   @media(min-width:1200px) {
   .main_basket_div {
   width: 80vw;
   margin-top: 1em
   }
   }
   @media(min-width:1800px) {
   .main_basket_div {
   width: 80vw;
   margin-top: 1em
   }
   }
   .main_basket_div_first_inner {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   margin: auto
   }
   @media(max-width:599px) {
   .main_basket_div_first_inner {
   width: 96%
   }
   }
   @media(min-width:600px) {
   .main_basket_div_first_inner {
   width: 96%
   }
   }
   @media(min-width:900px) {
   .main_basket_div_first_inner {
   width: 96%
   }
   }
   @media(min-width:1200px) {
   .main_basket_div_first_inner {
   width: 96%
   }
   }
   @media(min-width:1800px) {
   .main_basket_div_first_inner {
   width: 96%
   }
   }
   @media(max-width:599px) {
   .highlighted_product_image {
   width: 5em;
   height: 4em
   }
   }
   @media(min-width:600px) {
   .highlighted_product_image {
   width: 10em;
   height: 9em
   }
   }
   @media(min-width:900px) {
   .highlighted_product_image {
   width: 10em;
   height: 9em
   }
   }
   @media(min-width:1200px) {
   .highlighted_product_image {
   width: 10em;
   height: 9em
   }
   }
   @media(min-width:1800px) {
   .highlighted_product_image {
   width: 10em;
   height: 9em
   }
   }
   .shop_basket_main_div {
   overflow: visible;
   z-index: 2;
   width: 433px;
   display: grid;
   grid-template-rows: max-content;
   grid-gap: 5em;
   grid-template-columns: repeat(auto-fit, minmax(20px, 44em));
   justify-content: center;
   margin: auto;
   width: 97vw;
   margin-bottom: 2em
   }
   .shop_basket_main_div_dk1831202 {
   margin-top: 2em;
   min-width: 8.4em
   }
   @media(max-width:599px) {
   .shop_basket_main_div_dk1831202 {
   margin: 0 auto 0 auto;
   width: 11em
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div_dk1831202 {
   margin: 0 auto 0 auto;
   width: 11em
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div_dk1831202 {
   margin: auto;
   width: 11em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div_dk1831202 {
   margin: auto;
   width: 11em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div_dk1831202 {
   margin: auto;
   width: 11em
   }
   }
   @media(max-width:599px) {
   .shop_basket_main_div_prices_div_first_div,
   .shop_basket_main_div_prices_div_second_div {
   margin: auto
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div_prices_div_first_div,
   .shop_basket_main_div_prices_div_second_div {
   margin: auto
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div_prices_div_first_div,
   .shop_basket_main_div_prices_div_second_div {
   margin: auto 1em auto 1em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div_prices_div_first_div,
   .shop_basket_main_div_prices_div_second_div {
   margin: auto 1em auto 1em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div_prices_div_first_div,
   .shop_basket_main_div_prices_div_second_div {
   margin: auto 1em auto 1em
   }
   }
   @media(max-width:599px) {
   .shop_basket_main_div_3918723 {
   margin: .6em;
   text-align: center;
   width: 10em
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div_3918723 {
   margin: .6em;
   text-align: center;
   width: 15em
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div_3918723 {
   margin: auto;
   text-align: center;
   width: 15em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div_3918723 {
   margin: auto;
   text-align: center;
   width: 15em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div_3918723 {
   margin: auto;
   text-align: center;
   width: 15em
   }
   }
   @media(max-width:599px) {
   .shop_basket_main_div-button-customize {
   display: block;
   margin: 1em auto 1em auto
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div-button-customize {
   display: block;
   margin: 1em auto 1em auto
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div-button-customize {
   margin: auto;
   display: block;
   margin-bottom: 1em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div-button-customize {
   margin: auto;
   display: block;
   margin-bottom: 1em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div-button-customize {
   margin: auto;
   display: block;
   margin-bottom: 1em
   }
   }
   @media(max-width:599px) {
   .shop_basket_main_div_prices_div {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 11em
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div_prices_div {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 11em
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div_prices_div {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 9em;
   margin: auto
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div_prices_div {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 9em;
   margin: auto
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div_prices_div {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 9em;
   margin: auto
   }
   }
   @media(max-width:599px) {
   .shop_basket_main_div_dk18238 {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: auto;
   width: 11em
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div_dk18238 {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   margin: auto;
   width: 11em
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div_dk18238 {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 36em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div_dk18238 {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 36em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div_dk18238 {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   width: 36em
   }
   }
   .shop_basket_main_div-first-inner {
   display: flex;
   flex-direction: column;
   justify-content: center
   }
   .shop_basket_main_div-second-inner {
   border: 1px solid #cdcdcd;
   height: min-content
   }
   .shop_basket_main_div-third-inner {
   font-size: .7em;
   line-height: 1em;
   margin: auto;
   text-align: center;
   width: 100%;
   min-height: 3em;
   max-height: 5em;
   background: transparent;
   color: white;
   -moz-border-radius: 10px 10px 0 0;
   -webkit-border-radius: 10px 10px 0 0;
   border: 0 solid #827d7d
   }
   .shop_basket_main_div-fourth-inner {
   font-size: 1.5em;
   line-height: 1em;
   margin: auto;
   text-align: center;
   width: 100%;
   min-height: 3em;
   max-height: 5em;
   background: transparent;
   color: black;
   -moz-border-radius: 10px 10px 0 0;
   -webkit-border-radius: 10px 10px 0 0;
   border: 0 solid #827d7d;
   padding-top: 1em
   }
   .shop_basket_main_div-fifth-inner {
   display: flex;
   flex-direction: column;
   justify-content: center
   }
   .shop_basket_main_div-sixth-inner {
   display: flex;
   flex-direction: column;
   justify-content: center
   }
   .shop_basket_main_div-seventh-inner {
   margin: auto
   }
   .shop_basket_main_div-seventh-inner img {
   width: 12em;
   height: 12em
   }
   .shop_basket_main_div-first-zero-inner {
   max-height: 14em;
   min-height: 0;
   margin: 28px .5em;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly
   }
   @media(max-width:599px) {
   .shop_basket_main_div-first-zero-inner {
   margin: .6em;
   text-align: center;
   width: 10em
   }
   }
   @media(min-width:600px) {
   .shop_basket_main_div-first-zero-inner {
   margin: .6em;
   text-align: center;
   width: 10em
   }
   }
   @media(min-width:900px) {
   .shop_basket_main_div-first-zero-inner {
   margin: auto;
   text-align: center;
   width: 13em
   }
   }
   @media(min-width:1200px) {
   .shop_basket_main_div-first-zero-inner {
   margin: auto;
   text-align: center;
   width: 13em
   }
   }
   @media(min-width:1800px) {
   .shop_basket_main_div-first-zero-inner {
   margin: auto;
   text-align: center;
   width: 13em
   }
   }
   .shop_basket_main_div-first-first-inner {
   border: 1px solid #dbdbdb;
   border-radius: 1em;
   width: 3em;
   height: 3em;
   position: relative;
   background: red
   }
   .shop_basket_main_div-first-first-inner i {
   font-size: 2em;
   width: 100%;
   height: 100%;
   margin: auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   top: 17%;
   position: absolute;
   color: white
   }
   .shop_basket_main_div-first-second-inner {
   font-size: 1.4em;
   margin: auto 0 auto;
   padding-left: .5em;
   padding-right: .5em
   }
   .shop_basket_main_div-first-third-inner {
   border: 1px solid #dbdbdb;
   border-radius: 1em;
   width: 3em;
   height: 3em;
   position: relative;
   background: green;
   color: white
   }
   .shop_basket_main_div-first-fourth-inner {
   font-size: 1.3em;
   margin: auto;
   text-align: center;
   width: 100%;
   min-height: 2em;
   padding-top: 1em;
   border-radius: 0 0 10px 10px;
   -moz-border-radius: 0 0 10px 10px;
   -webkit-border-radius: 0 0 10px 10px;
   background: transparent;
   color: black;
   line-height: 1em;
   width: 100%
   }
   .shop_basket_main_div-first-fourth-inner div {
   display: flex;
   flex-direction: row;
   justify-content: space-evenly
   }
   .container {
   width: 100%;
   max-width: 680px;
   text-align: center;
   margin: 0 auto
   }
   .container h1 {
   font-size: 42px;
   font-weight: 300;
   color: #0f3c4b;
   margin-bottom: 40px
   }
   .container h1 a:hover,
   .container h1 a:focus {
   color: #39bfd3
   }
   .container nav {
   margin-bottom: 40px
   }
   .container nav a {
   border-bottom: 2px solid #c8dadf;
   display: inline-block;
   padding: 4px 8px;
   margin: 0 5px
   }
   .container nav a.is-selected {
   font-weight: 700;
   color: #39bfd3;
   border-bottom-color: currentColor
   }
   .container nav a:not(.is-selected):hover,
   .container nav a:not(.is-selected):focus {
   border-bottom-color: #0f3c4b
   }
   .container footer {
   color: #92b0b3;
   margin-top: 40px
   }
   .container footer p+p {
   margin-top: 1em
   }
   .container footer a:hover,
   .container footer a:focus {
   color: #39bfd3
   }
   .box {
   font-size: 1.25rem;
   position: relative;
   padding: 100px 118px;
   min-width: 17em
   }
   .box.has-advanced-upload {
   outline: 2px dashed #92b0b3;
   outline-offset: -10px;
   -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
   transition: outline-offset .15s ease-in-out, background-color .15s linear;
   width: 100%;
   justify-content: center;
   display: flex
   }
   .croppie_object_instance {
   width: 426px;
   height: 426px;
   margin-bottom: 10vh
   }
   .box.is-dragover {
   outline-offset: -20px;
   outline-color: #c8dadf;
   background-color: #fff
   }
   .box__dragndrop,
   .box__icon {
   display: none
   }
   .box.has-advanced-upload .box__dragndrop {
   display: inline
   }
   .box.has-advanced-upload .box__icon {
   width: 100%;
   height: 80px;
   fill: #92b0b3;
   display: block;
   margin-bottom: 40px
   }
   .box__input_extra {
   display: flex !important;
   flex-direction: column !important;
   width: 73em !important;
   justify-content: space-between !important;
   position: fixed !important;
   left: 17em !important;
   z-index: 10000002 !important;
   top: 7em !important;
   border: 2em solid white !important;
   background: #b3b3b3 !important;
   box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2) !important;
   width: 28em !important;
   overflow-y: auto;
   max-height: 84vh;
   background: white !important
   }
   .box__input {
   display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: space-between
   }
   .box.is-uploading .box__input,
   .box.is-success .box__input,
   .box.is-error .box__input {
   visibility: hidden
   }
   .box__uploading,
   .box__success,
   .box__error {
   display: none
   }
   .box.is-uploading .box__uploading,
   .box.is-success .box__success,
   .box.is-error .box__error {
   display: flex;
   justify-content: center;
   position: absolute;
   top: 50%;
   right: 0;
   left: 0;
   -webkit-transform: translateY(-50%);
   transform: translateY(-50%)
   }
   .box__uploading {
   font-style: italic
   }
   .box__success {
   -webkit-animation: appear-from-inside .25s ease-in-out;
   animation: appear-from-inside .25s ease-in-out
   }
   @-webkit-keyframes appear-from-inside {
   from {
   -webkit-transform: translateY(-50%) scale(0)
   }
   75% {
   -webkit-transform: translateY(-50%) scale(1.1)
   }
   to {
   -webkit-transform: translateY(-50%) scale(1)
   }
   }
   @keyframes appear-from-inside {
   from {
   transform: translateY(-50%) scale(0)
   }
   75% {
   transform: translateY(-50%) scale(1.1)
   }
   to {
   transform: translateY(-50%) scale(1)
   }
   }
   .box__restart {
   font-weight: 700
   }
   .box__restart:focus,
   .box__restart:hover {
   color: #39bfd3
   }
   .js .box__file {
   width: .1px;
   height: .1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1
   }
   .js .box__file+label {
   max-width: 80%;
   text-overflow: ellipsis;
   white-space: nowrap;
   cursor: pointer;
   display: inline-block;
   overflow: hidden
   }
   .js .box__file+label:hover strong,
   .box__file:focus+label strong,
   .box__file.has-focus+label strong {
   color: #39bfd3
   }
   .js .box__file:focus+label,
   .js .box__file.has-focus+label {
   outline: 1px dotted #000;
   outline: -webkit-focus-ring-color auto 5px
   }
   .no-js .box__file+label {
   display: none
   }
   .no-js .box__button {
   display: block
   }
   .box__button {
   font-weight: 700;
   color: #e5edf1;
   background-color: #39bfd3;
   display: none;
   padding: 8px 16px;
   margin: 40px auto 0
   }
   .box__button:hover,
   .box__button:focus {
   background-color: #0f3c4b
   }
   .main-div-class-upload {
   flex-direction: column;
   display: flex;
   justify-content: center
   }
   .sidebar-information-taxonomy {
   writing-mode: horizontal-tb;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(118px, 1fr));
   grid-auto-rows: max-content;
   grid-gap: 10px
   }
   .sidebar-information-taxonomy>div {
   font-size: 1.5em;
   word-wrap: break-word;
   word-spacing: normal;
   word-break: break-all;
   text-align: center;
   margin: auto;
   width: 100%;
   height: 100%;
   position: relative
   }
   .sidebar-information-taxonomy>div>div {
   margin: 0
   }
   .external_footer_bar {
   opacity: 1;
   display: block !important;
   position: -webkit-sticky;
   bottom: 0;
   background-color: white;
   z-index: 1
   }
   @media(max-width:599px) {
   .external_footer_bar {
   width: 97vw;
   position: relative;
   margin-left: 10px;
   margin-right: 10px
   }
   }
   @media(min-width:600px) {
   .external_footer_bar {
   width: 100vw;
   display: flex !important;
   flex-direction: row
   }
   }
   @media(min-width:900px) {
   .external_footer_bar {
   width: 100vw;
   display: flex !important;
   flex-direction: row
   }
   }
   @media(min-width:1200px) {
   .external_footer_bar {
   width: 100vw;
   display: flex !important;
   flex-direction: row
   }
   }
   @media(min-width:1800px) {
   .external_footer_bar {
   width: 100vw;
   display: flex !important;
   flex-direction: row
   }
   }
   @media(max-width:599px) {
   .external_footer_bar_first_div {
   flex-direction: row;
   display: flex !important;
   justify-content: space-between;
   height: 3em;
   padding-top: 1em;
   margin-right: 10px !important
   }
   }
   @media(min-width:600px) {
   .external_footer_bar_first_div {
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   height: 5em
   }
   }
   @media(min-width:900px) {
   .external_footer_bar_first_div {
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   height: 5em
   }
   }
   @media(min-width:1200px) {
   .external_footer_bar_first_div {
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   height: 5em
   }
   }
   @media(min-width:1800px) {
   .external_footer_bar_first_div {
   flex-direction: row;
   display: flex;
   justify-content: space-between;
   height: 5em
   }
   }
   @media(max-width:599px) {
   .main_component_basket {
   width: 100%;
   padding: 0
   }
   }
   @media(min-width:600px) {
   .main_component_basket {
   margin-top: 4.5em
   }
   }
   @media(min-width:900px) {
   .main_component_basket {
   margin-top: 4.5em
   }
   }
   @media(min-width:1200px) {
   .main_component_basket {
   margin-top: 4.5em
   }
   }
   @media(min-width:1800px) {
   .main_component_basket {
   margin-top: 4.5em
   }
   }
</style>