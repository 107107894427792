import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '../components/HomePage.vue'
import AboutUsPage from '../components/AboutUsPage.vue'
import ContactPage from '../components/ContactPage.vue'
import PrivacyPolicyPage from '../components/PrivacyPolicyPage.vue'
import TermsConditionsPage from '../components/TermsConditionsPage.vue'
import ProductPage from '../components/ProductPage.vue'
import RegistrationLogin from '../components/RegistrationLogin.vue'
import ProductList from '../components/ProductList.vue'
import RegistrationStepSecond from '../components/RegistrationStepSecond.vue'
import CheckoutStep2 from '../components/CheckoutStep2.vue'
import OrderSuccess from '../components/OrderSuccess.vue'
import OrderFailure from '../components/OrderFailure.vue'
import ShoppingBasket from '../components/ShoppingBasket.vue'
import MyAccount from '../components/MyAccount.vue'
import ResetPassword from '../components/ResetPassword.vue'
import RegistrationStepthird from '../components/RegistrationStepthird.vue'
import OrderDetails from '../components/OrderDetails.vue'
import SearchResult from '../components/SearchResult.vue'
import VerifyNumber from '../components/VerifyNumber.vue'
import SetNewPassword from '../components/SetNewPassword.vue'
import NotFound from '../components/NotFound.vue'


// import TestCheckoutStep2 from '../components/TestCheckoutStep2.vue'

Vue.use(Router)

const router = new Router({
    routes: [{
        path: '/',
        component: HomePage,
        name: 'homepage'
    },
    {
        path: '/about-us/',
        component: AboutUsPage,
        name: 'about'
    },

    {
        path: '/contact',
        component: ContactPage,
        name: 'contact'
    },
    {
        path: '/policy',
        component: PrivacyPolicyPage,
        name: 'policy'
    },
    {
        path: '/terms',
        component: TermsConditionsPage,
        name: 'terms'
    },
    {
        path: '/product/:url_key',
        component: ProductPage,
        name: 'product'
    },
    {
        path: '/category/:slug',
        component: ProductList,
        props: true,
        meta: {
            breadcrumb: ':slug',
        },
    },
    // {
    //     path: '/login',
    //     component: RegistrationLogin,
    //     name: 'login'
    // },
    {
        path: '/login/step2',
        component: RegistrationStepSecond,
        name: 'loginstep2'
    },
    // {
    //     path: '/login/verify-contact',
    //     component: VerifyNumber,
    //     name: 'verify'
    // },
    {
        path: '/checkout/step1',
        component: RegistrationStepthird,
        name: 'map',
        beforeEnter: (to, from, next) => {
            let conditions = window.localStorage;
            if (conditions.cartNotEmpty && conditions.UEmailID) {
                next();
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID) {
                next("/basket/");
            } else if (conditions.cartNotEmpty && conditions.UEmailID == undefined) {
                next("/login/step2");
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID == undefined) {
                next("/");
            }
        }
    },
    {
        path: '/checkout/step2',
        component: CheckoutStep2,
        name: 'checkoutstep2',
        beforeEnter: (to, from, next) => {
            let conditions = window.localStorage;
            if (conditions.cartNotEmpty && conditions.UEmailID) {
                next();
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID) {
                next("/basket/");
            } else if (conditions.cartNotEmpty && conditions.UEmailID == undefined) {
                next("/login/step2");
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID == undefined) {
                next("/");
            }
        }
    },


    // {
    //     path: '/checkout/test/step2',
    //     component: TestCheckoutStep2,
    //     name: 'login'
    // },


    {
        path: '/success-order/',
        component: OrderSuccess,
        name: 'success-order',
        beforeEnter: (to, from, next) => {
            let conditions = window.localStorage;
            if (conditions.cartNotEmpty && conditions.UEmailID) {
                next();
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID) {
                next("/basket/");
            } else if (conditions.cartNotEmpty && conditions.UEmailID == undefined) {
                next("/login/step2");
            } else if (conditions.cartNotEmpty == undefined && conditions.UEmailID == undefined) {
                next("/");
            }
        }
    },
    {
        path: '/payment-failure/',
        component: OrderFailure,
        name: 'payment-failure'
    },
    {
        path: '/basket/',
        component: ShoppingBasket,
        name: 'basket'
    },
    {
        path: '/my-account',
        component: MyAccount,
        name: 'my-account'
    },
    // {
    //     path: '/user/password/reset',
    //     component: ResetPassword,
    //     name: 'reset'
    // },
    {
        path: '/customer/reset-password/:token',
        component: SetNewPassword,
        name: 'passwordreset'
    },
    {
        path: '/user/order-details/:order_id',
        component: OrderDetails,
        name: 'order-details',
        props: true,
        meta: {
            breadcrumb: ':order_id',
        },
    },
    {
        path: '/search',
        component: SearchResult,
        name: 'search'
    },
    {
        path: '*',
        component: NotFound,
        name: 'NotFound'
    },

    ],
    mode: 'history'
})

export default router