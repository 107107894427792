<template>
  <div>

    <!-- best sellersection -->    
    <div class="heading-sec">
        <div class="section-text">
            Best Seller
        </div>
    </div>
        <div class="main-list-of-items">
            <div id="products_main_div" class="main-list-of-items-first-inner">

            <!-- 1 product -->
            <div v-for="product in products" :key="product.id" class="main-list-of-items-zero-inner">
                <div class="main-list-of-items---item-first-inner">
                </div>
                <div class="main-list-of-items---item-first-inner">
                    <div>
                       <router-link :to="'/product/' + product.url_key"><img class="external_class_element lazyload highlighted_product_image" alt="image"  :src="'/uploads/products/thumb/' + product.base_image"></router-link>
                    </div>
                </div>
                <div class="main-list-of-items---item-first-inner">
                    <div>
                        <div class="wrapped_text product_name product_product_name">
                            <router-link style="color: black;" :to="'/product/' + product.url_key">{{product.name}}</router-link>
                        </div>
                    </div>
                    <div>
                        <div style="display: flex; flex-direction: column;width: min-content;">
                            <div style="display: flex; flex-direction: column;width: min-content;">
                                <div class='original_price product_price' v-if="product.product_type === 'simple'">AED {{product.price}}</div>
                                <div class='original_price product_price' v-else-if="product.product_type === 'configurable'">AED {{product.min_price}} - AED {{product.max_price}}</div>
                            </div>
                                
                            <div style="display: flex; flex-direction: column;width: min-content;">
                             <!--   <div class='original_price product_price'>AED {{product.min_price}}</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            

        </div>
    </div>

    <!-- 4 pruducts and about-us decrption section -->
    <div class="category_main_div">
        <div class="category_main_div-container">
            <div class="category_main_div-inner">
                <div v-for="block in categoryblocks" :key="block.id" class="category-main-inner">
                    <router-link :to="block.link_button_to">
                        <div class="see_all_categories_div_img">
                            <img :alt="block.heading" class="see_all_categories_img lazyload" :src="'/uploads/banner/1920x1080/' + block.banner_image " >
                                <div class="category-main-description">
                                <div style="text-transform: uppercase;" class="see_all_categories_div_category_name">
                                    {{block.heading}}
                                </div>
                                <div class="see_all_categories_description">
                                    {{block.button_text}}
                                </div>
                                <div class="see_all_categories_div_button">
                                    <button class="see_all_categories_button">
                                        <router-link :to="block.link_button_to">
                                            See all {{block.heading}}
                                        </router-link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>

    <!-- about us section -->
    <!-- About us section end -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Homebestsellers',
    data(){
        return{
            products:'',
            categoryblocks:'',
        }
    },
    mounted() {
        this.getBestseller();
        this.getCategoryBlocks();
            
    },
    methods: {
        getBestseller(){
            axios
                .post("/webapi/webshop/product_listing",{
                categoryslug: "bestseller",
                })
                .then(response => {
                this.products = response.data.ProductList.slice(0, 8);
            })
        },
        getCategoryBlocks(){
            axios
                .post("/webapi/webshop/get_banners",{
                    banner_type: "categoryblock",
                }) 
                .then(response => {
                    this.categoryblocks = response.data.ShopBannerDetails;
                })
        }
    }
}
</script>

<style>

</style>