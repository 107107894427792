<template>
    <div>
        <!-- spinner -->
        <div v-if="loading">
            <SpinnerData />
        </div>
        <!--  -->
        <!-- main content -->
        <div class="container main_class_misc content-sec-info">
            <div class="row" style="margin: auto;">
                <h3 style="text-align: center;border-bottom: 2px solid #4d4d4d;width: 7em;margin: auto;margin-bottom: 1em;margin-top: 1em;" class="content-title">Contact</h3>
            </div>
            <div class="form_div_box" style="text-align: center;margin: auto;display: block;">
                <p>
                    Address: {{contactUs.site_address}}<br>
                    Hours: Open {{contactUs.site_start_time}} - Closes {{contactUs.site_close_time}}<br>
                    Phone: <a class="detail" target="_blank" :href="'tel:'+contactUs.site_contact_no">{{contactUs.site_contact_no}}</a><br>
                    Email: <a class="detail" target="_blank" :href="'mailto:'+contactUs.site_contact_email">{{contactUs.site_contact_email}}</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'ContactPage',
    components: { 
       SpinnerData, 
    },
    metaInfo(){
        return {
            titleTemplate:'Contact | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Contact Us' },
                { name: 'keywords', content: 'Contact Us' }
            ]
        }
    },
    data(){
        return{
            contactUs:'',
            loading: false,
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.getHeaderinfo();
    },
    methods:{
        getHeaderinfo(){
            this.loading = true;
            axios.get('/webapi/webshop/get_webshop_details/')
            .then(response => {
            if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                this.contactUs = response.data.FbcWebShopDetails;
                this.loading = false;
            }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                this.loading = false;
            }
            
            })
        },
    }
}
</script>

<style>

</style>