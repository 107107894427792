<template>
<div>
    <!-- spinner -->
    <div v-if="loading">
        <SpinnerData />
    </div>
    <!--  -->
    <!-- main content -->
    <div class="container main_class_misc content-sec-info">
        <div class="row" style="margin: auto;">
            <h3 style="text-align: center;border-bottom: 2px solid #4d4d4d;width: 7em;margin: auto;margin-bottom: 1em;margin-top: 1em;" class="content-title">{{ privacypolicy.title }}</h3>
        </div>
        <div class="form_div_box" style="margin: 2em;text-align: left;">
            <p v-html="privacypolicy.content">{{ privacypolicy.content }}</p>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import SpinnerData from './SpinnerData.vue';
export default {
    name: 'PrivacyPolicyPage', 
    components: { 
       SpinnerData, 
    },
    metaInfo(){
        return {
            titleTemplate:this.metaTitle+' | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: this.privacypolicy.meta_description },
                { name: 'keywords', content: this.privacypolicy.meta_keywords }
            ]
        }
    },
    data(){
        return{
            privacypolicy:'',
            metaTitle:'',
            loading: false,
            // content:'' 
        
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.getPrivacyPolicy()
    },
    methods:{
        getPrivacyPolicy(){
            this.loading = true;
            axios
            .get("/webapi/webshop/get_cms_page/policy")
            .then(response => {
                if(response.data.statusCode === 200 || response.data.statusCode === '200'){
                this.privacypolicy = response.data.cms_page_detail;
                if(this.privacypolicy.meta_title){
                    this.metaTitle = this.privacypolicy.meta_title;
                    
                }else{
                    this.metaTitle = this.privacypolicy.title;
                    
                }
                // console.log("Privacy Policy", this.privacypolicy);
                this.loading = false;
                }else if(response.data.statusCode === 500 || response.data.statusCode === '500'){
                    this.loading = false;
                }
            })    
                   
        }
    },
    
}
</script>

<style>

</style>