<template>
<div class="container main_class_misc main-box-sec location-section">
		<div class="" style="display: flex;flex-direction: row;justify-content: space-evenly;">
            <div class="main_section_title">Please enter your delivery location</div>


			<!-- scrolltoview Scroll first half page -->
				<div id="first_half_scroll"></div>
			<!-- end -->


			<!-- Success Messages start -->
			<div id="success-dialog" style="display: none;">
				<div class="" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
					<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
						<div style="margin:0 auto;">
							<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
								<div class="">
									<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
										{{message}}
									</ul>
								</div>
							<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
						</div>
					</div>
				</div>
				<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
			</div>
			<!-- success Messages End -->


        </div>
		<!--Delivery form Data -->
			<div class="container delivery_location_extended_customer_information_form main-box-sec checkout-steps">
				<div class="row row-main-div">
					<div id="delivery_location_extended_customer_information_form" style="margin: 2em;">
						<div id="maindivshopproduct_7" class="">
						<div>
							<div style="display: flex;flex-direction: column;justify-content: center;">
								<div style="">
									<div class="" style="display: flex;flex-direction: row;justify-content: space-evenly;">
									<!-- <div class="main_section_title" id="delivery_location_extended_checkout_div">Please enter your delivery 
										location
									</div> -->
									</div>
									<form id="delivery_location_extended_form" enctype="multipart/form-data" method="post" name="fileinfo">
									
									<input type="hidden" name="csrfmiddlewaretoken" value="MqJFCLxzv17KQzitXPpX3L54ZAAEzSRBZ0EmEkrODqLqQeOC4dTaQSSGiKSAO6kS">
									<div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label has-placeholder is-upgraded" style="width: 100%;" data-upgraded=",MaterialTextfield">
										<div style="text-align:left;">Deliver To:</div>
									</div>

									<!-- <div class="loc-search-main"><input type="text" id="autocomplete" /></div>
									<div><input type="hidden" id="latitude_db" name="latitude_db" value=""/></div>
									<div><input type="hidden" id="longitude_db" name="longitude_db" value=""/></div>
									<div id="googleMap" style="display:none;width:700px;height:400px;"></div> -->

									<div id="User_Delivery_Location_Full_Name_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Delivery_Location_Full_Name_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<input class="mdl-textfield__input" focused="" @keyup="disableform" style="" type="text" id="User_Delivery_Location_Full_Name" placeholder="Name" autofocus="" name="User_Delivery_Location_Full_Name" required>
									</div>
									
									<div style="display:none;">
										<div id="User_Delivery_Location_City_error" class="happybox-error-class">
											<div class="center_vertically_and_horizontally wrapped_text">
											</div>
										</div>
										<div id="User_Delivery_Location_City_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
											<input class="mdl-textfield__input" focused="" style="" type="text" id="User_Delivery_Location_City" placeholder="Country" value="United Arab Emirates" readonly autofocus="" name="User_Delivery_Location_City">
										</div>
									</div>


									<!-- scrolltoview  Scroll secand half page-->
										<div id="secand_half_scroll"></div>
									<!-- end -->
									
									<!-- Select Emirates-->
									<div id="User_Delivery_Location_Emirates_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>

									<div id="User_Delivery_Location_Emirates_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<select class="select-option-field" id="User_Delivery_Location_Emirates" @change="disableform" name="User_Delivery_Location_Emirates">
											<option value="Emirates" selected>Select Emirates</option>
											<option value="Dubai">Dubai</option>
											<option value="Sharjah">Sharjah</option>
										</select>
									</div>
									<!--End-->

									<div id="User_Delivery_Location_Region_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Delivery_Location_Region_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<input class="mdl-textfield__input" focused="" style="" type="text" @keyup="disableform" id="User_Delivery_Location_Region" placeholder="Area" autofocus="" name="User_Delivery_Location_Region" required>
									</div>

									<div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%;height: 7vh;padding-top: 0vh;" class="location-type">
										<div id="location_type_Apartment" v-on:click="changeLocationTypeTo('Apartment');" class="delivery_location_type apartment_div_type delivery_location_type_confirmed"> Apartment</div>
										<div id="location_type_House" v-on:click="changeLocationTypeTo('House');" class="delivery_location_type house_div_type"> House </div>
										<div id="location_type_Office" v-on:click="changeLocationTypeTo('Office');" class="delivery_location_type office_div_type"> Office </div>
										<input style="display: none;" name="User_Delivery_Location_Building_Type" id="User_Delivery_Location_Building_Type" type="text" value="0">
									</div>
									<div id="User_Delivery_Location_Building_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Delivery_Location_Building_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;padding-top: 2em;">
										<input class="mdl-textfield__input" focused="" @keyup="disableform" style="" type="text" id="User_Delivery_Location_Building" placeholder="Building" autofocus="" name="User_Delivery_Location_Building" required>
									</div>
									<div id="User_Delivery_Location_Building_Floor_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Delivery_Location_Building_Floor_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<input  class="mdl-textfield__input" focused="" @keyup="disableform" style="" type="text" id="User_Delivery_Location_Building_Floor" placeholder="Floor" autofocus="" name="User_Delivery_Location_Building_Floor" required>
									</div>
									<div id="User_Delivery_Location_Posession_Number_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Delivery_Location_Posession_Number_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<input value="" class="mdl-textfield__input" focused="" @keyup="disableform" style="" type="text" id="User_Delivery_Location_Posession_Number" placeholder="Apartment Number" autofocus="" name="User_Delivery_Location_Posession_Number" required>
									</div>
									<div id="User_Email_Address_error" class="happybox-error-class">
										<div class="center_vertically_and_horizontally wrapped_text">
										</div>
									</div>
									<div id="User_Email_Address_div" class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label" style="width: 100%;">
										<input type="email" name="User_Email_Address" placeholder="Email Address" @keyup="disableform" class="mdl-textfield__input"  id="User_Email_Address"  required>
									</div>
									<div>
										<div class="regular_text" style="margin-top: 20px;text-align:left;">
											Recipient phone number (recommended)
										</div>
										<div id="User_Delivery_Location_Phone_Number_error" class="happybox-error-class">
											<div class="center_vertically_and_horizontally wrapped_text">
											</div>
										</div>

										<div>
											<vue-phone-number-input id="phonenumberdata"  v-model="country_code_details" :required="true" default-country-code="AE" @update="updatedPhoneNumber"/> 			
										</div>
									</div>
									</form>
								</div>
								<div  style="width: 100%;margin-bottom: 0em;margin-top: 1em;">
									<button v-on:click="confirm_delivery_location_form()" id="delivery_location_form" style="width: 100%;height: 4em;background: rgb(238,153,47);background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;" class="disable-btn">Confirm</button>
								</div>
							</div>
							<div>
							</div>
						</div>
						</div>
					</div>

					<!-- Success Messages start -->
					<div id="success-dialog" style="display: none;" class="dialog-main">
						<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
							<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
								<div style="margin:0 auto;">
									<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;"></div>
										<div class="">
											<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0px;font-size: 24px;line-height: 30px;color: rgb(47 228 25);margin-bottom: 40px;font-weight: bold;">
												{{message}}
											</ul>
										</div>
									<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('success-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
								</div>
							</div>
						</div>
						<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
					</div>
					<!-- success Messages End -->

					<!-- Error Messages start -->
					<div id="error-dialog" style="display: none;" class="dialog-main">
						<div class="dialog-main-box" style="width: 100%;z-index: 100149;right: 0;padding: 40px 20px;background: #fff;color: #000;display: block;margin: 0;position: fixed;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);max-width: 38em;height: 40vh;max-width:600px;filter: drop-shadow(0px 3px 3.5px rgba(0,0,0,0.16));" open="">
							<div style="width: 100%;z-index: 100149;right: 0;background: #fff;color: #000;display: block; margin: 0;height: 100%;display: flex;align-items:center;">
								<div style="margin:0 auto;">
									<div class="dialog-title" style="font-size: 32px;line-height: 40px;color: #000000;font-weight: bold;text-align: center;">Warning</div>
										<div class="">
											<ul class="dialog-content" style="list-style-type: none;text-align: center;padding: 0;font-size:24px;line-height:30px;color:#e42919;margin-bottom:40px;">
												{{message}}
											</ul>
										</div>
									<div class="dialog-button"><button style="width: 100%;height: 5.2em;background: #17c942a6;color: white;font-size: 16px;font-family: 'proxima-nova', sans-serif !important;border: none;height: 45px;max-width: 280px;border-radius: 0;font-weight: 600;margin: 0 auto;display: block;" type="button" onclick="var obj = document.getElementById('error-dialog'); obj.style.display= 'none';" class="mdl-button confirm">Ok</button></div>
								</div>
							</div>
						</div>
						<div class="" style="z-index: 100148;position: fixed;left: 0;right: 0;margin: auto;padding: 1em;background: grey;opacity: 0.5;display: block;top: 0;height: -webkit-fill-available;" open=""></div>
					</div>
					<!-- Error Messages End -->
				</div>
			</div>
		<!-- Delivery form Data End  -->
		
		
</div>
</template>
<script>
import axios from 'axios'
export default {
    name:'RegistrationStepthird',
    data() {
        return {
            center: { lat: 25.2096274, lng: 55.2728081 },
			selected_area:'',
        }
    },
}
</script> 
<script>
import axios from 'axios'
export default {
    name: 'RegistrationStepthird',
    data() {
        return {
            products:'',
            slug:'',
			message:'',
			selected_area:'',
			country_code_details:'',
            ContactnumberDetails:'',
			Area_data:'',
        }
	},
	metaInfo(){
        return {
            titleTemplate:'Delivery Location | %s',
            htmlAttrs: {
                lang: 'en-US'
            },
            meta: [
                { charset: 'utf-8' },
                { name: 'description', content: 'Delivery Location Details' },
                { name: 'keywords', content: 'Delivery Location Details' }
            ]
        }
    },
	mounted(){
		this.getdeliverydetail();
		window.scrollTo(0,0);
		// this.myMap();
		this.getBasketDetails();
    },
	methods:{
		getdeliverydetail(){
			document.getElementById('User_Delivery_Location_Full_Name').value = localStorage.getItem("location_name");
			document.getElementById('User_Delivery_Location_Emirates').value = localStorage.getItem("location_Emirates");
			document.getElementById('User_Delivery_Location_Region').value = localStorage.getItem("location_region");
			if(localStorage.getItem("building_type") == 0){
				document.getElementById('User_Delivery_Location_Building').value = localStorage.getItem("User_Delivery_Location_Building");
				document.getElementById('User_Delivery_Location_Building_Floor').value = localStorage.getItem("User_Delivery_Location_Building_Floor");
				document.getElementById('User_Delivery_Location_Posession_Number').value = localStorage.getItem("User_Delivery_Location_Posession_Number");
			}
			document.getElementById('User_Email_Address').value = localStorage.getItem("User_Email_Address");
			// this.ContactnumberDetails.nationalNumber = localStorage.getItem("phone_number");
		},
		disableform(){
			var location_type_Apartment = document.getElementById('location_type_Apartment');
			var location_name = document.getElementById('User_Delivery_Location_Full_Name');
			var location_region = document.getElementById('User_Delivery_Location_Region');
			var User_Delivery_Location_Emirates = document.getElementById('User_Delivery_Location_Emirates');
			var building_type = document.getElementById('User_Delivery_Location_Building_Type');
			
			var User_Delivery_Location_Building = document.getElementById('User_Delivery_Location_Building');
			var User_Delivery_Location_Building_Floor = document.getElementById('User_Delivery_Location_Building_Floor');
			var User_Delivery_Location_Posession_Number = document.getElementById('User_Delivery_Location_Posession_Number');
			var User_Delivery_Location_Phone_Number = document.getElementById('User_Delivery_Location_Phone_Number');
			var phonenumberdata = document.getElementById('phonenumberdata');

			var delivery_location_form = document.getElementById('delivery_location_form');
			
			//alert("Data "+User_Delivery_Location_Emirates.value);
			if(User_Delivery_Location_Emirates.value == 'Emirates'){
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');
				
				return;
			}

			if (location_name.value.length == 0 || location_name.value == null) {
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');
				
				return;
			}

			if(building_type.value==0){
				if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
					delivery_location_form.classList.add('disable-btn');
					delivery_location_form.classList.remove('green-btn');

					return;
				} 
				
				if (User_Delivery_Location_Building_Floor.value.length == 0 || User_Delivery_Location_Building_Floor.value == null) {
					delivery_location_form.classList.add('disable-btn');
					delivery_location_form.classList.remove('green-btn');

					return;
				}
				
				if (User_Delivery_Location_Posession_Number.value.length == 0 || User_Delivery_Location_Posession_Number.value == null) {
					delivery_location_form.classList.add('disable-btn');
					delivery_location_form.classList.remove('green-btn');

					return;
				}
			}else if(building_type.value==1){
				if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
					delivery_location_form.classList.add('disable-btn');
					delivery_location_form.classList.remove('green-btn');

					return;
				}
				
			}else if(building_type.value==2){
				if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
					delivery_location_form.classList.add('disable-btn');
					delivery_location_form.classList.remove('green-btn');

					return;
				} 
				
			}

			if(User_Email_Address.value.length == 0 || User_Email_Address.value == null){
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');
				
				return;
			}

			if(this.country_code_details.length == 0){
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');

				return;
			}else if(this.ContactnumberDetails.isValid == false){
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');

				return;
			}

			if (location_region.value.length == 0 || location_region.value == null) {
				delivery_location_form.classList.add('disable-btn');
				delivery_location_form.classList.remove('green-btn');

				return;
			}

			delivery_location_form.classList.remove('disable-btn');
			delivery_location_form.classList.add('green-btn');
			
				
		},
		myMap(){
			var self = this;
			var myCenter = new google.maps.LatLng(25.0580262,54.5186056);
			var mapProp= {center:new google.maps.LatLng(25.2096274,55.2728081),zoom: 14,mapTypeControl: false,streetViewControl: false,fullscreenControl: false,mapTypeId: 'roadmap' };
			var map = new google.maps.Map(document.getElementById("googleMap"),mapProp);
			var marker = new google.maps.Marker({position: myCenter,draggable: false});
			marker.setMap(map);
			if (marker && marker.setMap) {
					marker.setMap(null);
				
				}
			var dubaiBounds ={north: 25.3585607,east: 55.5650393,south: 24.7921359,west: 54.8904543};
			var options ={bounds: dubaiBounds,types: ['establishment'],componentRestrictions: {country: 'AE'},strictBounds: false};
			var places = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),options);
			places.bindTo('bounds', map);
			var grand_total = parseInt('0');
			var rowsDubaiSharjah = [
					{lat: 24.91582779388786, lng: 54.90008328123156},
					{lat: 24.859768664476167, lng: 55.04290554685656},
					{lat: 24.685095790079767, lng: 55.14383009502728},
					{lat: 24.685095790079767, lng: 55.25643995830853},
					{lat: 24.60770849038519, lng: 55.31137159893353},
					{lat: 24.63018071059415, lng: 55.34982374737103},
					{lat: 24.642663531777334, lng: 55.51736525127728},
					{lat: 24.71503928018872, lng: 55.64096144268353},
					{lat: 24.75245851579326, lng: 55.72061232158978},
					{lat: 24.79734672894527, lng: 55.72335890362103},
					{lat: 24.971757406831774, lng: 55.66293409893353},
					{lat: 25.21303239130096, lng: 55.65194777080853},
					{lat: 25.16566809542357, lng: 55.85574607683816},
					{lat: 25.227799300697534, lng: 55.92166404558816},
					{lat: 25.324660605595735, lng: 55.86673240496316},
					{lat: 25.365196420705438, lng: 55.56571334202454},
					{lat: 25.356509809792847, lng: 55.50048201878235},
					{lat: 25.398385931012292, lng: 55.42323439915344},
					{lat: 25.357750792426813, lng: 55.29242842991516},
					{lat: 25.33261841188908, lng: 55.293115075422975},
					{lat: 25.315239886824934, lng: 55.312341149641725},
					{lat: 25.30127319348001, lng: 55.31302779514954},
					{lat: 25.315239886824934, lng: 55.28281539280579},
					{lat: 25.29941084607094, lng: 55.28453200657532},
					{lat: 25.280785798466248, lng: 55.2944883664386},
					{lat: 25.26960939707079, lng: 55.27457564671204},
					{lat: 25.281096239365556, lng: 55.25569289524719},
					{lat: 25.218371060042543, lng: 55.226167138411256},
					{lat: 25.219302858961356, lng: 55.23063033421204},
					{lat: 25.213401345280378, lng: 55.235436852766725},
					{lat: 25.20470385605767, lng: 55.228913720442506},
					{lat: 25.198180331304144, lng: 55.22513717014954},
					{lat: 25.192899126707367, lng: 55.22685378391907},
					{lat: 25.13945288888535, lng: 55.17810195286438},
					{lat: 25.117383951959912, lng: 55.155785973860475},
					{lat: 25.140074491336744, lng: 55.153039391829225},
					{lat: 25.146601125993712, lng: 55.13621657688782},
					{lat: 25.132925872088173, lng: 55.11218398411438},
					{lat: 25.108990493135657, lng: 55.102570947005006},
					{lat: 25.092201846861123, lng: 55.117333825422975},
					{lat: 25.0934455293115, lng: 55.1242002805011},
					{lat: 25.078520505674575, lng: 55.11046737034485},
					{lat: 25.05177528817257, lng: 55.09330123264954},
					{lat: 25.042755235890517, lng: 55.07991164524719},
					{lat: 25.051464262934196, lng: 55.05450576145813},
					{lat: 25.0492870641697, lng: 55.022920068098756},
					{lat: 25.050531182484182, lng: 54.99957412083313},
					{lat: 25.030623774636346, lng: 54.953694889093185},
					{lat: 24.988620994670956, lng: 54.946485111261154},
					{lat: 24.966836122554795, lng: 54.94854504778459},
					{lat: 24.94784901723094, lng: 54.93309552385881},
					{lat: 24.96278993582316, lng: 54.905286380792404},
					{lat: 24.951273137875997, lng: 54.7919385149553}
				];
				// Dubai + Sharja
				const polygonDubaiSharjah = new google.maps.Polygon({
				paths: rowsDubaiSharjah,
				strokeColor: "#FF0000",
				strokeOpacity:  0.00001,
				visible: false,
				strokeWeight: 0,
				clickable: false,
				editable: true,
				fillColor: '0.0',
				fillOpacity: 0.0,
				zIndex: 0,
			});
			var rowsAbuDhabi = [
					{lat: 24.45202020959115, lng: 54.31918394949025},
					{lat: 24.416151386839015, lng: 54.32709279222149},
					{lat: 24.400206840095667, lng: 54.368978168198055},
					{lat: 24.367998971260306, lng: 54.44244923753399},
					{lat: 24.35736544641711, lng: 54.45343556565899},
					{lat: 24.300831185297287, lng: 54.43820347877886},
					{lat: 24.258895200845465, lng: 54.376405383075735},
					{lat: 24.222758649919832, lng: 54.40430607747953},
					{lat: 24.127542035178397, lng: 54.63913884115141},
					{lat: 23.99336689111283, lng: 55.08820500326078},
					{lat: 23.944427087873073, lng: 55.48096623372953},
					{lat: 23.988348279589587, lng: 55.57846989583891},
					{lat: 24.031000239126012, lng: 55.63889470052641},
					{lat: 24.06862256892751, lng: 55.74326481771391},
					{lat: 24.05608301737513, lng: 55.77622380208891},
					{lat: 24.03350873737874, lng: 55.80780949544828},
					{lat: 24.02598309571489, lng: 55.82428898763578},
					{lat: 24.074891885100232, lng: 56.00007023763578},
					{lat: 24.213991669637647, lng: 55.94239201497953},
					{lat: 24.2027189513715, lng: 55.84626164388578},
					{lat: 24.220253859982524, lng: 55.74601139974516},
					{lat: 24.25782053466581, lng: 55.73914494466703},
					{lat: 24.291621054959275, lng: 55.78034367513578},
					{lat: 24.334171873287918, lng: 55.82566227865141},
					{lat: 24.395469976305055, lng: 55.82978215169828},
					{lat: 24.50173287595955, lng: 55.76661076497953},
					{lat: 24.589176125236754, lng: 55.75974430990141},
					{lat: 24.644109227857186, lng: 55.78995671224516},
					{lat: 24.67031878779882, lng: 55.82978215169828},
					{lat: 24.77385502704562, lng: 55.82978215169828},
					{lat: 24.800037277811604, lng: 55.81055607747953},
					{lat: 24.949543978436985, lng: 55.81467595052641},
					{lat: 24.974444159130822, lng: 55.85312809896391},
					{lat: 25.010540469288088, lng: 55.96161808919828},
					{lat: 25.090163744490095, lng: 55.97809758138578},
					{lat: 25.24801275278069, lng: 56.22391667318266},
					{lat: 25.55070615586001, lng: 55.65125431966703},
					{lat: 25.589108644410604, lng: 55.53589787435453},
					{lat: 25.499896864713165, lng: 55.47821965169828},
					{lat: 25.39821381290352, lng: 55.42054142904203},
					{lat: 25.367195813564642, lng: 55.47547306966703},
					{lat: 25.348581190915226, lng: 55.68833317708891},
					{lat: 25.21322896422393, lng: 55.65400090169828},
					{lat: 24.994360675642877, lng: 55.66636052083891},
					{lat: 24.786572043084778, lng: 55.73502507162016},
					{lat: 24.73669092059993, lng: 55.72815861654203},
					{lat: 24.673063464730095, lng: 55.58396305990141},
					{lat: 24.629378768650632, lng: 55.52765812826078},
					{lat: 24.631875448682482, lng: 55.36835637044828},
					{lat: 24.59442001271252, lng: 55.28595890951078},
					{lat: 24.67680715584012, lng: 55.23377385091703},
					{lat: 24.684294200890907, lng: 55.11017765951078},
					{lat: 24.838925647493994, lng: 55.03876652669828},
					{lat: 24.928623168688684, lng: 54.84375920247953},
					{lat: 24.90122250437722, lng: 54.57184758138578},
					{lat: 24.714237883656285, lng: 54.32465519857328}
				];
				// Abu Dhabi
					const polygonAbuDhabi = new google.maps.Polygon({
					paths: rowsAbuDhabi,
					strokeColor: "#FF0000",
					strokeOpacity:  0.00001,
					visible: false,
					strokeWeight: 0,
					clickable: false,
					editable: true,
					fillColor: '0.0',
					fillOpacity: 0.0,
					zIndex: 0,
				});
			var rowsSharjahOnly = [
				{lat: 25.16566809542357, lng: 55.85574607683816},
				{lat: 25.227799300697534, lng: 55.92166404558816},
				{lat: 25.324660605595735, lng: 55.86673240496316},
				{lat: 25.397320, lng: 55.422718},
				{lat: 25.357750792426813, lng: 55.29242842991516}
			];
				// Sharjah only
				const polygonSharjah = new google.maps.Polygon({
					paths: rowsSharjahOnly,
					strokeColor: "#FF0000",
					strokeOpacity:  0.00001,
					visible: false,
					strokeWeight: 0,
					clickable: false,
					editable: true,
					fillColor: '0.0',
					fillOpacity: 0.0,
					zIndex: 0,
				});

			// load and Enter place
			google.maps.event.addDomListener(window, 'load', function () {
			//var dubaiBounds ={north: 25.3585607,east: 55.5650393,south: 24.7921359,west: 54.8904543};
			//var options ={bounds: dubaiBounds,types: ['establishment'],componentRestrictions: {country: 'AE'},strictBounds: true};
			//var places = new google.maps.places.Autocomplete(document.getElementById('autocomplete'),options);
			//places.bindTo('bounds', map);
			google.maps.event.addListener(places, 'place_changed', function () {
			var place = places.getPlace();
			var address = place.formatted_address;
			var latitude = place.geometry.location.lat();
			var longitude = place.geometry.location.lng();
			marker.setPosition(place.geometry.location);
			marker.setVisible(true);
			var db_address= address;
			var db_latt = latitude;
			var db_lngg = longitude;

			if(db_address.includes('- Dubai - United Arab Emirates')){
				this.Area_data = db_address.replace(' - Dubai - United Arab Emirates','');
			}else if(db_address.includes('Dubai - United Arab Emirates')){
				this.Area_data = db_address.replace('Dubai - United Arab Emirates','');
			}else if(db_address.includes('- United Arab Emirates')){
				this.Area_data = db_address.replace('- United Arab Emirates','');
			}else if(db_address.includes('United Arab Emirates')){
				this.Area_data = db_address.replace('- United Arab Emirates','');
			}else{
				this.Area_data = db_address;
			}
			
			document.getElementById("User_Delivery_Location_Region").value = this.Area_data;
			document.getElementById('latitude_db').value= db_latt;
			document.getElementById('longitude_db').value= db_lngg;
			var mesg = "Address: " + address;
			mesg += "\nLatitude: " + latitude;
			mesg += "\nLongitude: " + longitude;
			map.setCenter(place.geometry.location);
			console.log(mesg);});});
			// document.getElementById('confirm_delivery_location_form_btn').removeAttribute("disabled");
		},

		confirmtheLocation(){
			this.$session.start();
			var latitude = document.getElementById('latitude_db').value;
			var longitude = document.getElementById('longitude_db').value;
			var customer_id = this.$session.get('LoginID');
			//console.log( latitude , longitude , customer_id);

			axios.post('/webapi/webshop/register_location',{
				lat : latitude,
				longi : longitude,
				customer_id : customer_id
			}).then(response => {
				if(response.data.statusCode === 200 || response.data.statusCode === '200'){
					this.message = response.data.message;
					document.getElementById('success-dialog').style.display = 'block';
					this.$router.push({ path: '/checkout/step1' });
				}else{
					console.log('Something Went Wrong!',response.data);
				}
			})
		},
		getBasketDetails(){
			this.$session.start();
			var customer_id = this.$session.get("LoginID");
			var session_id = this.$cookie.get('device');
			var quote_id = '';

			if(this.$cookie.get('QuoteId') == null || this.$cookie.get('QuoteId') == undefined)
			{
				quote_id = '';
			}
			else
			{
				quote_id = this.$cookie.get('QuoteId');
			}

			// if(this.$session.has("QuoteId") === false)
            // {
            //     quote_id = '';
            // }
            // else
            // {
            //     quote_id = this.$session.get("QuoteId");
            // }
			axios.post('/webapi/webshop/cart_listing',{
				quote_id: quote_id,
				customer_id: customer_id,
				session_id: session_id,
			})
			.then(response => {
				this.basketcartDetails = response.data.cartData.cartDetails;
				this.basketcartItems = response.data.cartData.cartItems;
				// console.log("base_grand_total",this.basketcartDetails.base_grand_total,"basket Details",this.basketcartDetails,"Cart Items",this.basketcartItems);
			})
		},
		
		changeLocationTypeTo(location_type, event='') {
            //    alert("IN"+location_type);
                var self = this.DELIVERY_ADDRESS_EXTENDED;

                var location_type_Apartment = document.getElementById('location_type_Apartment');
                var location_type_House = document.getElementById('location_type_House');
                var location_type_Office = document.getElementById('location_type_Office');

                if(location_type == 'Apartment') {
                    location_type_Apartment.classList.add('delivery_location_type_confirmed');
                    location_type_House.classList.remove('delivery_location_type_confirmed');
                    location_type_Office.classList.remove('delivery_location_type_confirmed');
                }else if(location_type == 'House') { 
                    location_type_Apartment.classList.remove('delivery_location_type_confirmed');
                    location_type_House.classList.add('delivery_location_type_confirmed');
                    location_type_Office.classList.remove('delivery_location_type_confirmed');
                }else if(location_type == 'Office') {
                    location_type_Apartment.classList.remove('delivery_location_type_confirmed');
                    location_type_House.classList.remove('delivery_location_type_confirmed');
                    location_type_Office.classList.add('delivery_location_type_confirmed');
                }

                //   event.target.classList.add('delivery_location_type_confirmed');

                var User_Delivery_Location_Building = document.getElementById('User_Delivery_Location_Building');
                var User_Delivery_Location_Building_Floor = document.getElementById('User_Delivery_Location_Building_Floor');
                var User_Delivery_Location_Posession_Number = document.getElementById('User_Delivery_Location_Posession_Number');
                var User_Delivery_Location_Building_Type = document.getElementById('User_Delivery_Location_Building_Type');
                User_Delivery_Location_Building_error.style.display = 'none';
                User_Delivery_Location_Building_Floor_error.style.display = 'none';
                User_Delivery_Location_Posession_Number_error.style.display = 'none';

                if(location_type == 'Apartment') {
					
                
                    User_Delivery_Location_Building_Floor.style.display='block';
                    User_Delivery_Location_Posession_Number.style.display='block';
                    User_Delivery_Location_Building_Floor.parentElement.style.display='block';
                    User_Delivery_Location_Posession_Number.parentElement.style.display='block';

                    User_Delivery_Location_Building.placeholder = 'Building';
                    User_Delivery_Location_Posession_Number.placeholder = 'Apartment Number';
                    User_Delivery_Location_Building_Type.value = "0"




                } else if(location_type == 'House') { 
					
                    User_Delivery_Location_Building_Floor.style.display = 'none';
                    User_Delivery_Location_Building_Floor.parentElement.style.display = 'none';
                    User_Delivery_Location_Posession_Number.style.display = 'none';
                    User_Delivery_Location_Posession_Number.parentElement.style.display = 'none';


                    User_Delivery_Location_Building.placeholder = 'House';
                    User_Delivery_Location_Building_Type.value = "1"
                } else if(location_type == 'Office') {
					
                    User_Delivery_Location_Building_Floor='none';
                    // User_Delivery_Location_Building_Floor.parentElement.style.display='none';
                    User_Delivery_Location_Posession_Number.style.display='none';
                    // User_Delivery_Location_Posession_Number.parentElement.style.display='none';


                    User_Delivery_Location_Building.placeholder = 'Office Building';
                    User_Delivery_Location_Posession_Number.placeholder = 'Office';
                    User_Delivery_Location_Building_Type.value = "2"
                }
                
            },
            confirm_delivery_location_form(sender) {
                var location_type_Apartment = document.getElementById('location_type_Apartment');
                var location_name = document.getElementById('User_Delivery_Location_Full_Name');
                var location_region = document.getElementById('User_Delivery_Location_Region');
				var location_Emirates = document.getElementById('User_Delivery_Location_Emirates');
                var building_type = document.getElementById('User_Delivery_Location_Building_Type');
              
                var User_Delivery_Location_Building = document.getElementById('User_Delivery_Location_Building');
                var User_Delivery_Location_Building_Floor = document.getElementById('User_Delivery_Location_Building_Floor');
                var User_Delivery_Location_Posession_Number = document.getElementById('User_Delivery_Location_Posession_Number');
				var User_Email_Address = document.getElementById("User_Email_Address");
                var User_Delivery_Location_Phone_Number = document.getElementById('User_Delivery_Location_Phone_Number');
                var phonenumberdata = document.getElementById('phonenumberdata');

                if (location_name.value.length == 0 || location_name.value == null) {
					document.getElementById('User_Delivery_Location_Full_Name_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Delivery_Location_Full_Name_error', 'User Delivery Location Name Cannot be Empty', true);

					const el = document.getElementById('first_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

                    return;
                }else{
					document.getElementById('User_Delivery_Location_Full_Name_error').style.display = 'none';
				}

				
				if(location_Emirates.value == "Emirates"){
					document.getElementById('User_Delivery_Location_Emirates_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Delivery_Location_Emirates_error', 'Please Select the User Delivery Location Emirates', true);

					const el = document.getElementById('first_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

                    return;
				}else{
					document.getElementById('User_Delivery_Location_Emirates_error').style.display = 'none';
				}

                if (location_region.value.length == 0 || location_region.value == null) {
					document.getElementById('User_Delivery_Location_Region_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Delivery_Location_Region_error', 'User Delivery Location Region Cannot be Empty', true);

					const el = document.getElementById('first_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

                    return;
                }else{   
					document.getElementById('User_Delivery_Location_Region_error').style.display = 'none';
				}
                
				if(building_type.value==0){
                    if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
						document.getElementById('User_Delivery_Location_Building_error').style.display = 'block';
						this.enableOrDisableErrorMessage('User_Delivery_Location_Building_error', 'User Delivery Location Building Cannot be Empty', true);
						
						const el = document.getElementById('secand_half_scroll');
						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}

						return;
					}else{
						document.getElementById('User_Delivery_Location_Building_error').style.display = 'none';
					} 
					
					if (User_Delivery_Location_Building_Floor.value.length == 0 || User_Delivery_Location_Building_Floor.value == null) {
						document.getElementById('User_Delivery_Location_Building_Floor_error').style.display = 'block';
						this.enableOrDisableErrorMessage('User_Delivery_Location_Building_Floor_error', 'User Delivery Location Building Floor Cannot be Empty', true);
						
						const el = document.getElementById('secand_half_scroll');
						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}

						return;
					}else{
						
						document.getElementById('User_Delivery_Location_Building_Floor_error').style.display = 'none';
					}
					
					if (User_Delivery_Location_Posession_Number.value.length == 0 || User_Delivery_Location_Posession_Number.value == null) {
						document.getElementById('User_Delivery_Location_Posession_Number_error').style.display = 'block';
						this.enableOrDisableErrorMessage('User_Delivery_Location_Posession_Number_error', 'User Delivery Location Posession Number Cannot be Empty', true);
						
						const el = document.getElementById('secand_half_scroll');
						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}

						return;
					}else{
                        
						document.getElementById('User_Delivery_Location_Posession_Number_error').style.display = 'none';
					}
				}else if(building_type.value==1){
					if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
						this.enableOrDisableErrorMessage('User_Delivery_Location_Building_error', 'User Delivery Location Building Cannot be Empty', true);
						
						const el = document.getElementById('secand_half_scroll');
						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}

						return;
					}else{
                          
						document.getElementById('User_Delivery_Location_Building_error').style.display = 'none';
					} 
					
				}else if(building_type.value==2){
					if (User_Delivery_Location_Building.value.length == 0 || User_Delivery_Location_Building.value == null) {
						this.enableOrDisableErrorMessage('User_Delivery_Location_Building_error', 'User Delivery Location Building Cannot be Empty', true);
						
						const el = document.getElementById('secand_half_scroll');
						if (el) {
							el.scrollIntoView({behavior: 'smooth'});
						}

						return;
					}else{
						document.getElementById('User_Delivery_Location_Building_error').style.display = 'none';
					} 
					
				}

				if (User_Email_Address.value.length == 0 || User_Email_Address.value == null) {
					document.getElementById('User_Email_Address_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Email_Address_error', 'User Email Address Cannot be Empty', true);

					const el = document.getElementById('secand_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

                    return;
                }else{
					document.getElementById('User_Email_Address_error').style.display = 'none';
				}

				if(this.country_code_details.length == 0){
					document.getElementById('User_Delivery_Location_Phone_Number_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Delivery_Location_Phone_Number_error', 'User Delivery Location Phone Number Cannot be Empty', true);
                    
					const el = document.getElementById('secand_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

					return;
                }else if(this.ContactnumberDetails.isValid == false){
					document.getElementById('User_Delivery_Location_Phone_Number_error').style.display = 'block';
                    this.enableOrDisableErrorMessage('User_Delivery_Location_Phone_Number_error', 'Please Enter valid User Delivery Location Phone Number', true);
                    
					const el = document.getElementById('secand_half_scroll');
					if (el) {
						el.scrollIntoView({behavior: 'smooth'});
					}

					return;
                }else{
                    document.getElementById('User_Delivery_Location_Phone_Number_error').style.display = 'none';
				} 
					

                var form_input = document.getElementById('delivery_location_extended_form');   		
                var payload = new FormData(form_input);
                

                // Concatenating phone extension with the number
                payload.set(
                    'User_Delivery_Location_Phone_Number',
                    this.ContactnumberDetails.nationalNumber
                )

                payload.set(
                    'User_Delivery_Location_Building_Type',
                    User_Delivery_Location_Building_Type.value
                )
                
                payload.set(
                    'User_Delivery_Location_Phone_Number_Prefix',
                    this.ContactnumberDetails.countryCallingCode
                )

                // Setting up user data
                payload.set(
                    'phone_number',
                    this.ContactnumberDetails.nationalNumber
                )

                payload.set(
                    'phone_number_prefix',
                    this.ContactnumberDetails.countryCallingCode
                )
                this.$session.start();
                var Custregister_ext_no = this.$session.get('ext_no');
				var Custregister_phone_number = this.$session.get('PhoneNumber');
				
				// EmailID saved in session from delivery form location
				this.$session.set('EmailID',User_Email_Address.value);

                payload.set('Custregister_ext_no',Custregister_ext_no);
				payload.set('Custregister_phone_number',Custregister_phone_number);

                localStorage.setItem("location_name",location_name.value);
				localStorage.setItem("location_region",location_region.value);
				localStorage.setItem("location_Emirates",location_Emirates.value);
				localStorage.setItem("building_type",building_type.value);
				localStorage.setItem("User_Delivery_Location_Building",User_Delivery_Location_Building.value);
				localStorage.setItem("User_Delivery_Location_Building_Floor",User_Delivery_Location_Building_Floor.value);
				localStorage.setItem("User_Delivery_Location_Posession_Number",User_Delivery_Location_Posession_Number.value);
				// localStorage.setItem("User_Delivery_Location_Building",User_Delivery_Location_Building.value);
				// localStorage.setItem("User_Delivery_Location_Building1",User_Delivery_Location_Building.value);
				// localStorage.setItem("User_Delivery_Location_Building2",User_Delivery_Location_Building.value);
				localStorage.setItem("User_Email_Address",User_Email_Address.value);
				
                return axios({
                    method: 'post',
                    url: '/webapi/webshop/register_address',
                    data: payload,
                    config: {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                })
                .then(response => {
                    
                    // return response.json();
                if (response.data.statusCode === 200 || response.data.statusCode === "200") {
                    this.message = response.data.message;
                    // document.getElementById('success-dialog').style.display="block";
                    this.$router.push({ path: '/checkout/step2' });
                } else if (response.data.statusCode === 500) {
                    this.message = response.data.message;
                    document.getElementById('error-dialog').style.display="block";
                    this.enableOrDisableErrorMessage( 'Something went wrong!',response.data.message, true);
                } 
                })
                
            },
            updatedPhoneNumber(data){
				
                this.ContactnumberDetails = data;
				this.disableform();
				// console.log("Contact Details: ",this.ContactnumberDetails);
                // console.log("contact Details: ",this.ContactnumberDetails,"countryCallingCode :",this.ContactnumberDetails.countryCallingCode,"nationalNumber :",this.ContactnumberDetails.nationalNumber);
                
            },

            enableOrDisableErrorMessage(idOfTheMainElement, messageText, disableFlag) {
                var mainElement = document.getElementById(idOfTheMainElement);
                if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement)) {
                    var mainElement_first_children = mainElement.firstElementChild;
                    if (!this.checkIfObjectIsNotUndefiendOrNull(mainElement_first_children)) {
                        mainElement_first_children.innerHTML = messageText;
                        if (!disableFlag) {
                            mainElement.style.display = "none";
                        } else {
                            mainElement.style.display = "block";
                        }
                    }
                }
            },
            checkIfObjectIsNotUndefiendOrNull(obj) {
                if (obj === undefined || obj === null) {
                    return true;
                } else {
                    return false;
                }
            }
	}
}
</script>
<style scoped>
.disable-btn{
	width: 280px !important;
    height: 45px !important;
    background-color: #e3e4e3 !important;
    background-image: none !important;
    font-size: 16px !important;
    line-height: 50px !important;
    color: #ffffff;
    font-weight: bold;
    border-radius: 0 !important;
    box-shadow: none !important;
    line-height: 43px !important;
    text-transform: capitalize !important;
    text-shadow: none !important;
    margin-top: 30px !important;
	cursor: not-allowed;
}
</style>